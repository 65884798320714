<h2 mat-dialog-title>Customize Verification Report</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Select Customer</mat-label>
      <mat-select formControlName="customerId" placeholder="Select Customer">
        <mat-option *ngFor="let customer of optionSelect.customers" [value]="customer.value">
          {{ customer.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Select Location</mat-label>
      <mat-select formControlName="locationId" placeholder="Select Location">
        <mat-option *ngFor="let location of optionSelect.locations" [value]="location.value">
          {{ location.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
        <input matStartDate formControlName="beginDate" placeholder="Start date">
        <input matEndDate formControlName="endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onClose()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onApply()">Apply</button>
</mat-dialog-actions>
