import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService, ModalService } from 'app/services';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { states } from 'assets/states';
import { prettyPrintPhone } from 'assets/formatting';

@Component({
  selector: 'app-location-notifications-modal',
  templateUrl: './location-notifications-modal.component.html',
  styleUrls: ['./location-notifications-modal.component.scss'],
})
export class LocationNotificationsModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  location: any;
  locationForm: FormGroup;
  states = states;
  private _unsubscribeAll: Subject<any>;
  notiValidity = {
    phone: <any>true,
    email: <any>true,
    valid: <any>true,
    reassess: <any>false,
  };
  users: any = [];
  activeRow: boolean;
  previousData: any;
  dataSource: any;
  notificationPoints: any = [];
  smsNotificationPoints: any = [];
  emailNotificationPoints: any = [];
  smsSelect;
  emailSelect;
  removable = true;
  smsSelectVal;
  emailSelectVal;
  smsOptions: any = [];
  emailOptions: any = [];

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<LocationNotificationsModalComponent>,
    private http: HttpService,
    private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this.smsSelect = new FormControl();
    this.emailSelect = new FormControl();
    this._fuseTranslationLoaderService.loadTranslations(english);
    this.location = this.data.location;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.showDialog();
    this.http.getBusinessUsers().subscribe(res => {
      res.forEach(user => {
        user.fullName = `${user.first_name} ${user.last_name}`,
        user.smsLabel = `${user.fullName}, ${prettyPrintPhone(user.phone)}`;
        user.emailLabel = `${user.fullName}, ${user.email}`;
      });
      this.users = res.sort((a, b) => 
        a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : 
        a.first_name.toLowerCase() < b.first_name.toLowerCase() ? -1 : 
        0
      );
      this.getSmsPoints();
      this.getEmailPoints();
    })

  }

  notiPointSort(a, b){
    return a.description?.toLowerCase() > b.description?.toLowerCase() ? 1 : 
    a.description?.toLowerCase() < b.description?.toLowerCase() ? -1 : 
    0
  }

  getSmsInfo(){
    let validatePhone = val => {
      let valid = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(val);
      return valid;
    };
    this.modalService.showInputModal(
      'Add recipient',
      null,
      true,
      [
        {label: 'Phone number', type: 'tel', name: 'phone', validator: validatePhone},
        {label: 'Description', type: 'text', name: 'description', placeholder: 'Mike Jones'}
      ]
    )      
    .afterClosed()
    .subscribe(res => {
      if(res?.phone && validatePhone(res.phone)){
        res.phone = res.phone.replace(/[^0-9]/g, '');
        return this.addNotificationPoint(res);
      }
    })
  }

  getEmailInfo(){
    let validateEmail = val => {
      let valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val);
      return valid;
    };
    this.modalService.showInputModal(
      'Add recipient',
      null,
      true,
      [
        {label: 'Email', type: 'text', name: 'email', validator: validateEmail},
        {label: 'Description', type: 'text', name: 'description', placeholder: 'Mike Jones'}
      ]
    )      
    .afterClosed()
    .subscribe(res => {
      if(res?.email && validateEmail(res.email)){
        return this.addNotificationPoint(res);
      }
    })
  }

  getSmsPoints(){
    this.http.getBusinessNotificationPoints({where: {bus_sub_acc_loc_id: this.location.id, text_alerts: true}})
      .subscribe(res => {
        res.forEach(el => el.label = ((el.description ? `${el.description}, ` : '') + prettyPrintPhone(el.phone)));
        this.smsNotificationPoints = res.sort(this.notiPointSort);
        this.smsOptions = this.users.filter(j => !this.smsNotificationPoints.find(k => j.phone == k.phone));
      });
  }
  
  getEmailPoints(){
    this.http.getBusinessNotificationPoints({where: {bus_sub_acc_loc_id: this.location.id, email_alerts: true}})
      .subscribe(res => {
        res.forEach(el => el.label = ((el.description ? `${el.description}, ` : '') + el.email));
        this.emailNotificationPoints = res.sort(this.notiPointSort);
        this.emailOptions = this.users.filter(j => !this.emailNotificationPoints.find(k => j.email == k.email));
      });
  }

  addNotificationPoint(props){
    let notificationPoint = {
      bus_sub_acc_loc_id: this.location.id,
      assigned_units: true, 
      unassigned_units: true,
      email_alerts: !!props.email,
      text_alerts: !!props.phone,
      ...props
    };
    this.http.createNotificationPoint(notificationPoint).subscribe(() => {
      this.smsSelectVal = undefined;
      this[`get${props.email ? 'Email' : 'Sms'}Points`]();
    })
  }

  removeNotificationPoint(el): void {
    this.http.deleteNotificationPoint(el.id).subscribe(() => {
      this[`get${el.email ? 'Email' : 'Sms'}Points`]();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  
}
