import { MatDialogRef } from '@angular/material/dialog';

export abstract class BaseModal {
  constructor(protected dialogRef: MatDialogRef<any>, protected data: any) {}

  //abstract onResize?(event: Event): void;

  showDialog(): void {
    this.dialogRef.addPanelClass('hide-dialog');

    this.dialogRef.afterOpened().subscribe(() => {
      // TODO: Smooth out transition
      this.dialogRef.addPanelClass('show-dialog');
      this.dialogRef.removePanelClass('hide-dialog');

      window.dispatchEvent(new Event('resize'));
    });
  }
}
