<div>
  <form [formGroup]="changePasswordForm" fxLayout="column">
    <mat-form-field appearance="standard">
      <mat-label>Current password</mat-label>
      <input matInput formControlName="currentPassword" type="password"/>
      <mat-icon matSuffix class="secondary-text" (click)="toggleShow('currentPassword')">remove_red_eye</mat-icon>
      <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('required')">
        Required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>New password</mat-label>
      <input matInput formControlName="newPassword" type="password" minlength="6" [errorStateMatcher]="matcher"/>
      <mat-icon matSuffix class="secondary-text" (click)="toggleShow('newPassword')">remove_red_eye</mat-icon>
      <mat-error *ngIf="changePasswordForm.hasError('sameAsCurrent')" >
        Current password cannot be used as new password
      </mat-error>
      <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
        Invalid password
      </mat-error>
      <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('minlength')">
        Password must be at least 6 characters long
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Confirm new password</mat-label>
      <input matInput formControlName="confirmNewPassword" type="password" [errorStateMatcher]="matcher"/>
      <mat-icon matSuffix class="secondary-text" (click)="toggleShow('confirmNewPassword')">remove_red_eye</mat-icon>
      <mat-error *ngIf="changePasswordForm.hasError('noMatch')" >
        Passwords don't match
      </mat-error>
    </mat-form-field>
  </form>
  <div fxLayout="row" fxLayoutGap="8px" class="btn-row">
    <button [disabled]="changePasswordForm.status == 'INVALID'" (click)="changePassword()" mat-button color="accent">
      Confirm
    </button>
  </div>
</div>
