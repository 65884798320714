import { Component, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { HttpService } from 'app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { prettyPrintDate } from 'assets/formatting';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-deployment-history-modal',
  templateUrl: './deployment-history-modal.component.html',
  styleUrls: ['./deployment-history-modal.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '250px'})),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeploymentHistoryModalComponent extends BaseModal implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('perfectScrollBar') perfectScroll: PerfectScrollbarComponent;

  dataSource: any;
  displayedColumns: string[] = ['pretty_deploy_time', 'deploy_name', 'result_type', 'expand'];
  expandedElement: any;

  private _unsubscribeAll: Subject<any>;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<DeploymentHistoryModalComponent>,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.showDialog();
    this.fetchRows();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.perfectScroll.directiveRef.update()
      this.perfectScroll.directiveRef.config.suppressScrollY = false;
    }, 300)
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchRows(): void {
    let subscription = this.http.getBusinessUsers().subscribe((users) => {
      subscription.unsubscribe();
      this.data.device.segPestTrapData.forEach(el => {
        for(let j in users)
          if(users[j].id == el.user_id){
            el.user_name = `${users[j].first_name} ${users[j].last_name}`;
            break;
          }
        el.user_name || (el.user_name = "N/A");
        el.result_type || (el.result_type = "N/A");
        el.deploy_name || (el.deploy_name = "N/A");
        el.deploy_notes || (el.deploy_notes = "N/A");
        el.pickup_notes || (el.pickup_notes = "N/A");
        el.pretty_deploy_time = el.deploy_time ? prettyPrintDate(el.deploy_time, {time: true}) : "N/A";
        el.pretty_pickup_time = el.pickup_time ? prettyPrintDate(el.pickup_time, {time: true}) : "N/A";
      });
      this.dataSource = new MatTableDataSource(this.data.device.segPestTrapData);
      this.dataSource.sort = this.sort;
    });
  }
}
