import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { catchError, map, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { prettyPrintDate } from 'assets/formatting';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-devices-dashboard',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesDashboardComponent implements OnDestroy {
  loading = true;;
  businessName: string;
  showMap: boolean;

  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  displayedColumns: string[] = ['devicename', 'devicedesc', 'app_tc', 'gps_label', 'lastEventDate', 'lastEventBody'];
  queryInput = new FormControl('');
  technicianFilter = new FormControl(0);
  queeblo002: boolean = true;
  technicians: any[] = [];
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private modalService: ModalService,
    private authService: AuthService,

  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.authService.currentBusiness
    .pipe(
      takeUntil(this._unsubscribeAll),
      skipWhile((bus) => bus == null),
    )
    .subscribe((business) => {
      this.businessName = business.name;
    });
    this.fetchRows();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchRows(reload?: any): void {
    if(reload === undefined)
      this.loading = true;
    const $devices = this.httpService.getBusinessDevices();
    const $hubsensors = this.httpService.getBusinessHubSensors();

    forkJoin([$devices, $hubsensors])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((data) => ([...data[0], ...data[1]].filter(el => !!el.app_tc))),
        map((devices) =>
          _.each(devices, (device) => {

            if(device.sensr_webapp_lat){
              device.webapp_lat = device.sensr_webapp_lat;
              device.webapp_lon = device.sensr_webapp_long;
            }

            device.gps = !device.webapp_lat || !device.webapp_lon ? //|| device.segPestTrapData[0].pickup_time || !device.segPestTrapData[0]?.placement_lat_long?.includes(', ') ?
              null : {lat: parseFloat(device.webapp_lat), lng: parseFloat(device.webapp_lon)}; //((a) => ({lat: parseFloat(a[0]), lng: parseFloat(a[1])}))(device.segPestTrapData[0].placement_lat_long.split(', '));

            device.gps_label = device.gps != null ? `${device.gps.lat.toFixed(5)}, ${device.gps.lng.toFixed(5)}` : 'No GPS data available';
            
            device.user_full_name = device.user ? `${device.user.first_name} ${device.user.last_name}` : '(Not assigned)';


            if(!this.technicians.includes(device.user_full_name))
              this.technicians.push(device.user_full_name);
      
            if(device.sensorHistories)
              device.deviceEvents = device.sensorHistories;

            if(device.deviceEvents){
              device.lastEvent = device.deviceEvents[0];
              device.lastEventBody = device.lastEvent.body;
              device.lastEventDate = prettyPrintDate(new Date(device.lastEvent.created), {time: true});
            }
          }),
        ),
        map((devices) =>
          (this.technicians.sort((a, b) => a == '(Not assigned)' ? 1 : a > b ? 1 : a < b ? -1 : 0),
          devices.filter(a => !!a.app_tc).sort((a, b) => {
            return a.app_tc > b.app_tc ? -1 : a.app_tc < b.app_tc ? 1 : 0;
          }))
        ),
        catchError((err) => (this.loading = false, this.modalService.errorDialog(err)))
      )
      .subscribe((res: any) => {  
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
          typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
        this.dataSource.filterPredicate = (el, val) => {
            if(!val.technician || val.technician == 0 ||  
              val.technician == el.user_full_name
            ){
              if(val.query){
                for(let i of ['devicename', 'devicedesc', 'app_tc', 'gps_label', 'lastEventDate', 'lastEventBody'])
                  if(el[i] && `${el[i]}`.toLowerCase().indexOf(val.query) >= 0)
                    return true;
              } else 
                return true;
            }
            return false;
        };
        this.queryInput.valueChanges.subscribe(() => this.dataSource.filter = {query: this.queryInput.value, technician: this.technicianFilter.value});
        this.technicianFilter.valueChanges.subscribe(() => this.dataSource.filter = {query: this.queryInput.value, technician: this.technicianFilter.value})
        this.loading = false;
      });
  }

  popoverOpened(): void {
    this.showMap = false;
    setTimeout(() => (this.showMap = true), 0);
  }
}
