<div>
  <h2 mat-dialog-title>Missed heartbeats</h2>
  <mat-dialog-content style="height: 30vh">
    <perfect-scrollbar [config]="{suppressScrollX : true, suppressScrollY : true}" #perfectScrollBar>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
        <ng-container matColumnDef="devicename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Device name </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.devicename }} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="serial">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.serial }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="lastConnection">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Last connection </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.lastConnection }} </mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClick(row.serial)" class="clickable"></mat-row>
      </mat-table>
    </perfect-scrollbar>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
