import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BusinessAccountLocation } from 'app/services';
import { Subject } from 'rxjs';
import { emailValidator, zipValidator } from 'validators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { states } from 'assets/states';
@Component({
  selector: 'app-edit-customer-location-modal',
  templateUrl: './edit-customer-location-modal.component.html',
  styleUrls: ['./edit-customer-location-modal.component.scss'],
})
export class EditCustomerLocationModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  location: any;
  locationForm: FormGroup;
  states = states;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<EditCustomerLocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);

    this._fuseTranslationLoaderService.loadTranslations(english);

    this.location = this.data.location;

    this.locationForm = new FormGroup({
      name: new FormControl(this.location.name, [Validators.required]),
      extLocCustId: new FormControl(this.location.ext_loc_cust_id),
      address1: new FormControl(this.location.address_1, [Validators.required]),
      address2: new FormControl(this.location.address_2),
      city: new FormControl(this.location.city, [Validators.required]),
      state: new FormControl(states.find(el => this.location.state.toLowerCase() == el.long.toLowerCase() || this.location.state.toLowerCase() == el.short.toLowerCase())?.short, [Validators.required]),
      zip: new FormControl(this.location.zip, [Validators.required, zipValidator()]),
      contactFirstName: new FormControl(this.location.contact_first_name, [
        Validators.required,
      ]),
      contactLastName: new FormControl(this.location.contact_last_name, [
        Validators.required,
      ]),
      contactEmail: new FormControl(this.location.contact_email, [
        emailValidator(),
      ]),
      contactPhone: new FormControl(this.location.contact_phone),
    });

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  
  sendCustomer(): void {
    const newLocation: BusinessAccountLocation = {
      id: this.location.id,
      name: this.locationForm.get('name').value,
      ext_loc_cust_id: this.locationForm.get('extLocCustId').value,
      address_1: this.locationForm.get('address1').value,
      address_2: this.locationForm.get('address2').value,
      city: this.locationForm.get('city').value,
      state: this.locationForm.get('state').value,
      zip: this.locationForm.get('zip').value,
      contact_first_name: this.locationForm.get('contactFirstName').value,
      contact_last_name: this.locationForm.get('contactLastName').value,
      contact_email: this.locationForm.get('contactEmail').value,
      contact_phone: this.locationForm
        .get('contactPhone')
        .value.replace(/-/g, ''),
    };

    this.dialogRef.close(newLocation);
  }
}
