import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '.';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { prettyPrintDate } from 'assets/formatting';
import * as _ from 'lodash';

export interface BusinessAccount {
  id?: string;
  name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  segment_id: number;
  business_id?: string;
  ext_cust_id?: string;
}

export interface BusinessAccountLocation {
  id?: string;
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_phone: string;
  contact_email: string;
  bus_sub_id?: string;
  ext_loc_cust_id?: string;
}

export interface PickupSensor {
  rowId: number;
  deviceId: number;
  resultType: string;
  pickupTime: string;
  notes: string;
}

const env = environment;

const props = {
  keys: [
    'name', 'notes', 'serial', 'type', 'events', 'reedStatus', 'disarmTime', 'lastActive', 'power', 'lastConnection', 'heartbeat'
  ],
  device: {
    name: 'devicename',
    notes: 'device_notes',
    serial: 'device_serial',
    type: 'unit_type',
    events: 'deviceEvents',
    reedStatus: 'reed_status',
    disarmTime: 'disarmtime',
    lastActive: 'lastActive',
    power: 'isDevicePowerOn',
    lastConnection: 'lastConnection',
    heartbeat: 'unit_autocheckin',
    endpoint: 'devices',
  },
  sensor: {
    name: 'devicename',
    notes: 'sensr_device_notes',
    serial: 'sensor_serial',
    type: 'sensr_type',
    events: 'sensorHistories',
    reedStatus: 'sensr_reed_status',
    disarmTime: 'sensr_disarmtime',
    lastActive: 'sensr_lastActive',
    power: 'sensr_power',
    lastConnection: 'sensr_lastConnection',
    heartbeat: 'sensr_heartbeat',
    endpoint: 'hubsensors'
  },
  reverseDevice: {},
  reverseSensor: {},
};
props.keys.forEach(el => {
  props.reverseDevice[props.device[el]] = el;
  props.reverseSensor[props.sensor[el]] = el;
});


const obsErr = (err: any, sub: any) => {
  sub.next(err);
  throw err;
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private auth: AuthService) { }

  getBusinessUsers(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const filter = {
      fields: {
        id: true,
        pendingBusinessId: true,
        business_id: true,
        first_name: true,
        last_name: true,
        email: true,
        phone: true,
        roles: true,
        passwordReset: true,
        emailVerified: true,
        sms2FA: true,
      },
    };

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/users?filter=' +
      JSON.stringify(filter),
    );
  }

  addBusinessUsers(users: object[]): Observable<any> {
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/add_users`, { users: users });
  }

  removeBusinessUsers(userIds: string[]): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/remove_users',
      {
        users: userIds,
      },
    );
  }

  promoteBusinessUsers(promoteUsersForm: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/promote_users',
      promoteUsersForm,
    );
  }

  promoteBusinessUser(userId: string, role: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    let data = { users: [userId], role: role };
    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/promote_users',
      data,
    );
  }

  demoteBusinessUsers(userIds: string[]): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/demote_users',
      {
        users: userIds,
      },
    );
  }

  demoteBusinessUser(userId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/demote_users', { users: [userId] },
    );
  }

  getBusinessDevice(deviceId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const filter = {
      where: {
        id: deviceId,
      },
      include: [{ relation: 'deviceEvents' }, { relation: 'user' }],
    };

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/devices?filter=' +
      JSON.stringify(filter),
    );
  }

  getBusinessDevices(excludeRelations?: any, eventFilter?: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    let filter = {
      include: []
    };

    if (!excludeRelations?.user)
      filter.include.push({ relation: 'user' });
    if (!excludeRelations?.businessSubscription)
      filter.include.push({ relation: 'businessSubscription' });
    if (!excludeRelations?.deviceEvents)
      filter.include.push({ relation: 'deviceEvents', ...(eventFilter ? { scope: eventFilter } : {}) });
    if (!excludeRelations?.locationTracking)
      filter.include.push({ relation: 'locationTracking' });
    if (!excludeRelations?.segPestTrapData)
      filter.include.push({
        relation: 'segPestTrapData',
        scope: { order: ['pickup_time DESC', 'deploy_time DESC'] },
      });

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/devices' + (filter.include.length > 0 ? `?filter=${encodeURIComponent(JSON.stringify(filter))}` : '')
    );
  }

  addBusinessDevice(addDeviceForm: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/addDevice',
      {
        device_serial: addDeviceForm.deviceSerial,
        devicename: addDeviceForm.deviceName,
        devicedesc: addDeviceForm.deviceDescription,
      },
    );
  }

  ambiguifyDevice = (device: any) => {
    device.initial = { ...device };
    let deviceType = device.sensor_serial ? 'sensor' : 'device',
      k = props[deviceType];
    for (let i of props.keys) {
      device[i] = device[k[i]];
      delete device[k[i]];
    }

    /* currently added fields to ambiguified devices: 
        deviceType<str, 'sensor' || 'device'>, 
        gps<obj, {lat: <float>, lng: <float>}>,
        gps_label<str>
        user_full_name<str>
        ambiguified<bool, true>
        last_tech_check<string>
        initial<obj: a copy of the original device object unambiguified>

        this is important for disambiguation
    */
    device.deviceType = deviceType;

    device.gps = (!device.segPestTrapData || !device.segPestTrapData.length || device.segPestTrapData[0].pickup_time) ? { lat: -1001, lng: -1001 } :
      (!device.segPestTrapData[0].placement_lat_long || device.segPestTrapData[0].placement_lat_long.indexOf(', ') === -1) ? { lat: -1000, lng: -1000 } :
        ((c => device.gps = { lat: parseFloat(c[0]), lng: parseFloat(c[1]) })(device.segPestTrapData[0].placement_lat_long.split(', ')));

    device.gps_label = device.gps.lat <= -1000 ? device.gps.lat == -1001 ?
      'Not currently deployed' : 'No GPS data available' : `${device.gps.lat.toFixed(5)}, ${device.gps.lng.toFixed(5)}`;

    device.user_full_name = device.user ? `${device.user.first_name} ${device.user.last_name}` : 'Unassigned';

    device.ambiguified = true;

    device.last_tech_check = (k => k ? prettyPrintDate(k, { time: true }) : 'N/A')(device.segPestTrapData?.find(el => el.pickup_time != null)?.pickup_time);

    return device;
  };

  disambiguifyDevice(device: any) {
    let k = props[device.deviceType];
    for (let i of props.keys) {
      device[k[i]] = device[i];
      delete device[i];
    }
    for (let i of ['deviceType', 'gps', 'gps_label', 'user_full_name', 'ambiguified', 'last_tech_check'])
      delete device[i];
    return device;
  }

  getAmbiguousBusinessDevice(serial: string): Observable<any> {
    if (!serial)
      throw `Invalid serial: ${serial}`;

    let k = props[serial.match(/SH(B|H|P).*/) ? 'device' : 'sensor'];
    const filter = {
      where: { [k.serial]: serial },
      include: [
        { relation: k.events },
        { relation: 'user' },
        { relation: 'segPestTrapData' }
      ]
    };
    return new Observable<any>(subscriber => {
      this.http.get(
        `${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/${k.endpoint}?filter=${JSON.stringify(filter)}`
      ).pipe(
        catchError(err => {
          subscriber.next(err);
          throw err;
        })
      ).subscribe((devices: any) => {
        subscriber.next(this.ambiguifyDevice(devices[0]));
      });
    });
  }

  getAmbiguousBusinessDevices(): Observable<any> {
    return new Observable<any>(subscriber => {
      forkJoin([this.getBusinessDevices(), this.getBusinessHubSensors()]).pipe(
        map((data) => [...data[0], ...data[1]]),
        map((devices) => devices.sort((a, b) => {
          const nA = a.devicename.toUpperCase(), nB = b.devicename.toUpperCase();
          return nA > nB ? 1 : nA < nB ? -1 : 0;
        })),
        catchError(err => obsErr(err, subscriber))
      ).subscribe(res => {
        subscriber.next(res.map(this.ambiguifyDevice));
      });
    });
  }

  getBusinessHubSensor(sensorId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const filter = {
      where: {
        id: sensorId,
      },
      include: [{ relation: 'sensorHistories' }, { relation: 'user' }],
    };

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/hubsensors?filter=' +
      JSON.stringify(filter),
    );
  }


  getBusinessHubSensors(excludeRelations?: any, historyFilter?: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    let filter = {
      include: []
    };

    if (!excludeRelations?.user)
      filter.include.push({ relation: 'user' });
    if (!excludeRelations?.businessSubscription)
      filter.include.push({ relation: 'businessSubscription' });
    if (!excludeRelations?.sensorHistories)
      filter.include.push({ relation: 'sensorHistories', ...(historyFilter ? { scope: historyFilter } : {}) });
    if (!excludeRelations?.segPestTrapData)
      filter.include.push({
        relation: 'segPestTrapData',
        scope: { order: ['pickup_time DESC', 'deploy_time DESC'] },
      });

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/hubsensors' + (filter.include.length > 0 ? `?filter=${encodeURIComponent(JSON.stringify(filter))}` : '')
    );
  }

  addBusinessSensor(addSensorForm: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/addSensor',
      {
        sensor_serial: addSensorForm.sensorSerial,
        devicename: addSensorForm.sensorName,
        devicedesc: addSensorForm.sensorDescription,
      },
    );
  }

  assignBusinessDevice(assignDeviceForm: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/device/assign',
      assignDeviceForm,
    );
  }

  assignBusinessDevices(assignDevicesForm: any): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/devices/assign',
      assignDevicesForm,
    );
  }

  unassignBusinessDevices(deviceSerials: string[]): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/devices/unassign',
      deviceSerials,
    );
  }

  pickupBusinessDevice(body: any): Observable<any> {
    body.checked_by_user_id = this.auth.getCurrentUser().id;
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/pickup`, body);
  }

  pickupBusinessDevices(serials: string[]): Observable<any> {
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/pickup-units`, {
      serials: serials,
      user_id: this.auth.getCurrentUser().id
    });
  }

  getBusinessAccount(customerId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/account?customerId=' +
      customerId,
    );
  }

  getBusinessAccsWithDevices(): Observable<any> {
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/accounts-with-counts`);
  }

  getBusinessAccounts(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const filter = {
      include: [{ relation: 'segment' }],
      where: {
        inactive: { eq: null },
      },
    };

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/accounts?filter=' +
      JSON.stringify(filter),
    );
  }

  getBusinessAccountLocation(locationId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/account-location?locationId=' +
      locationId,
    );
  }

  getSubaccountLocations(): Observable<any> {
    return this.http.get(
      `${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/sub-accounts-locations`);
  }

  getBusinessAccountLocations(customerId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const filter = {
      where: {
        inactive: { eq: null },
      },
    };

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/account-locations?customerId=' +
      customerId +
      '&filter=' +
      JSON.stringify(filter),
    );
  }

  getBusinessAccountDevices(
    customerId?: string,
    customerLocationId?: string,
    active?: boolean,
    alerts?: boolean,
    filter?: any,
  ): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    const endpoint =
      env.apiUrl + '/businesses/' + businessId + '/account-devices';

    const queryList: string[] = [];

    if (customerId) {
      queryList.push('customerId=' + customerId);
    }

    if (customerLocationId) {
      queryList.push('customerLocationId=' + customerLocationId);
    }

    if (active === true || active === false) {
      queryList.push('active=' + active);
    }

    if (alerts) {
      queryList.push('alerts=' + alerts);
    }

    if (filter) {
      queryList.push('filter=' + JSON.stringify(filter));
    }

    return this.http.get(endpoint + '?' + queryList.join('&'));
  }

  getBusinessAccountDevicesCount(
    customerId?: string,
    customerLocationId?: string,
    active?: boolean,
    alerts?: boolean,
  ): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const endpoint =
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/account-devices/count';

    const queryList: string[] = [];

    if (customerId) {
      queryList.push('customerId=' + customerId);
    }

    if (customerLocationId) {
      queryList.push('customerLocationId=' + customerLocationId);
    }

    if (active === true || active === false) {
      queryList.push('active=' + active);
    }

    if (alerts) {
      queryList.push('alerts=' + alerts);
    }

    return this.http.get(endpoint + '?' + queryList.join('&'));
  }

  addBusinessAccount(account: BusinessAccount): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    account.business_id = businessId;

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/accounts',
      account,
    );
  }

  editBusinessAccount(account: BusinessAccount): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const where = {
      id: account.id,
    };

    return this.http.patch(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/accounts?where=' +
      JSON.stringify(where),
      account,
    );
  }

  removeBusinessAccount(accountId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/accounts/remove',
      {
        customerId: accountId,
      },
    );
  }

  addBusinessAccountLocation(
    customerId: string,
    accountLocation: BusinessAccountLocation,
  ): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    accountLocation.bus_sub_id = customerId;

    return this.http.post(
      env.apiUrl + '/businesses/' + businessId + '/account-locations',
      accountLocation,
    );
  }

  editBusinessAccountLocation(
    accountLocation: BusinessAccountLocation,
  ): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    const locationId = accountLocation.id;

    return this.http.patch(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/account-locations/' +
      locationId,
      accountLocation,
    );
  }

  removeBusinessAccountLocations(locationIds: string[]): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/account-locations/remove',
      {
        locations: locationIds,
      },
    );
  }

  getBusinessTypes(): Observable<any> {
    return this.http.get(env.apiUrl + '/segments');
  }

  getBusinessDeviceBySerial(deviceSerial: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.get(
      env.apiUrl +
      '/businesses/' +
      businessId +
      '/device?deviceSerial=' +
      deviceSerial,
    );
  }

  chargebeeGetHostPage(deviceSerial: string, planId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      `${env.apiUrl}/businesses/${businessId}/business-billings/generateCheckout`,
      { cf_serialnum: deviceSerial, plan_id: planId },
    );
  }

  chargebeeQueryHostId(hostId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      `${env.apiUrl}/businesses/${businessId}/business-subscription/queryHostId`,
      { host_id: hostId },
    );
  }

  updatePaymentSource(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.get(
      `${env.apiUrl}/businesses/${businessId}/business-billings/updatePaymentSource`,
    );
  }

  validateDeviceSerial(deviceSerial: string): Observable<any> {
    return this.http.get(
      `${env.apiUrl}/devices/validateDeviceSerial?device_serial=${deviceSerial}`,
    );
  }

  validateSensorSerial(sensorSerial: string): Observable<any> {
    return this.http.get(
      `${env.apiUrl}/hubsensors/validate-sensor-serial?sensor_serial=${sensorSerial}`,
    );
  }

  chargebeePortalToken(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      `${env.apiUrl}/businesses/${businessId}/business-billings/managePaymentMethods`,
      {},
    );
  }

  chargebeePastDue(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      `${env.apiUrl}/businesses/${businessId}/business-billings/pastdue`,
      {},
    );
  }

  getInvoices(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.get(
      `${env.apiUrl}/businesses/${businessId}/business-billings/invoiceList`,
    );
  }

  getInvoicePDF(invoiceId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.get(
      `${env.apiUrl}/businesses/${businessId}/business-billings/invoicePDF?invoice_id=${invoiceId}`,
    );
  }

  getBusinessSubscriptions(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    const filter = {
      include: [{ relation: 'device' }, { relation: 'sensor' }],
    };

    return this.http.get(
      `${env.apiUrl
      }/businesses/${businessId}/business-subscriptions?filter=${JSON.stringify(
        filter,
      )}`,
    );
  }

  cancelBusinessSubscription(deviceId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      `${env.apiUrl}/businesses/${businessId}/deactivate-device?deviceId=${deviceId}`,
      null,
    );
  }

  activateBusinessSubscription(deviceId: string): Observable<any> {
    const businessId = this.auth.getCurrentBusId();

    return this.http.post(
      `${env.apiUrl}/businesses/${businessId}/reactivate-device?deviceId=${deviceId}`,
      null,
    );
  }

  businessHasPaymentMethod(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    const filter = {
      where: {
        and: [
          { business_id: businessId },
          {
            or: [
              { bb_payment_source_id: { neq: null } },
              { bb_payment_terms: 30 },
            ],
          },
        ],
      },
    };

    return this.http.get(`${env.apiUrl}/businesses/${businessId}/business-billings?filter=${JSON.stringify(filter)}`,);
  }

  getDeviceSerialMappings(): Observable<any> {
    const filter = {
      fields: ['prefix'],
      where: {
        type: 'device',
      },
    };

    return this.http
      .get(
        `${env.apiUrl}/serial-mappings?filter=${JSON.stringify(
          filter,
        )}`,
      )
      .pipe(map((smaps: any[]) => smaps.map((smap) => smap.prefix)));
  }

  doUsersExist(emails: string[]): Observable<any> {
    let encodedEmails = [];
    for (let i in emails)
      encodedEmails.push(encodeURIComponent(emails[i]));
    return this.http
      .get(
        `${env.apiUrl}/users/exists?emails=${encodedEmails.join('&emails=')}`,
      )
      .pipe(map((users: any[]) => users.map((user) => user.email)));
  }

  createUser(user: any): Observable<any> {
    return this.http.post(`${env.apiUrl}/signup`, user);
  }

  updateUser(id: string, body: any): Observable<any> {
    delete body.sms2FA;
    return this.http.patch(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/users?where=${JSON.stringify({ id: id })}`, body);
  }

  updateUserEmail(user: any): Observable<any> {
    return this.http.patch(`${env.apiUrl}/users/${user.id}/update-email`, user.email);
  }

  updateUser2FA(user: any, enable2FA: boolean, resendOTP?: boolean, otp?: string, phone?: string): Observable<any> {
    return this.http.patch(`${env.apiUrl}/users/${user.id}/update-2fa`, { enable2FA: enable2FA, resendOTP: resendOTP, otp: otp, phone: phone });
  }

  getBusinessRoles(): Observable<any> {
    return this.http.get(`${env.apiUrl}/users/getBusinessRoles`);
  }

  /* not currently used, but likely will be in a future notification point update */
  getDeviceNotificationPoints(deviceId: string): Observable<any> {
    return this.http.get(`${env.apiUrl}/devices/${deviceId}/notification-points`)
  }
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  updateNotificationPoint(data: any, id: string): Observable<any> {
    return this.http.patch(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/notification-points?where=${JSON.stringify({ id: id })}`, data);
  }

  createNotificationPoint(data: any): Observable<any> {
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/notification-points`, { ...data, business_id: this.auth.getCurrentBusId() });
  }

  getBusinessNotificationPoints(filter?: any): Observable<any> {
    let filterStr = filter ? `?filter=${JSON.stringify(filter)}` : '';
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/notification-points${filterStr}`);
  }

  deleteNotificationPoint(id: string): Observable<any> {
    return this.http.delete(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/notification-points?where=${JSON.stringify({ id: id })}`);
  }

  updateBusiness(businessForm: any): Observable<any> {
    return new Observable<any>(subscriber => {
      this.http.patch(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}`, businessForm).pipe(
        catchError(err => {
          subscriber.next(err);
          throw err;
        })
      ).subscribe((bus) => {
        subscriber.next(bus);
        this.auth.getCurrentBusiness();
      });
    });
  }

  geocode(address: string, city: string, state: string, country: string): Observable<any> {
    let encodedAddress = encodeURIComponent(`${address} ${city} ${state} ${country}`);
    return this.http.get(`${env.googleMapsApiUrl}/geocode/json?address=${encodedAddress}&key=${env.googleApiKey}&language=en&region=us`);
  }

  resendVerificationEmail(email: string): Observable<any> {
    return this.http.post(`${env.apiUrl}/users/resend-verification?email=${encodeURIComponent(email)}`, { email: email });
  }

  changePassword(cur: string, changed: string): Observable<any> {
    return this.http.post(`${env.apiUrl}/users/change_password`, { curPassword: cur, newPassword: changed });
  }

  patchDevice(deviceId: string, newData: any, sensor?: boolean) {
    return this.http.patch(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/${sensor ? 'hubsensors' : 'devices'}?where=${JSON.stringify({ id: deviceId })}`, { id: deviceId, ...newData });
  }

  patchAmbiguousDevice(device: any, newData?: any) {
    return this.http.patch(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/${props[device.deviceType].endpoint}?where=${JSON.stringify({ id: device.id })}`, { id: device.id, ...this.disambiguifyDevice({ deviceType: device.deviceType, ...(newData || device) }) });
  }

  updateScheduledReports(data: any, id: string): Observable<any> {
    return this.http.patch(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/scheduled-reports?where=${JSON.stringify({ id: id })}`, data);
  }

  createScheduledReports(data: any): Observable<any> {
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/scheduled-reports`, { ...data, business_id: this.auth.getCurrentBusId() });
  }

  getBusinessScheduledReports(): Observable<any> {
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/scheduled-reports`);
  }

  /* currently unused, but depending on how we play with scheduling reports in the future i figured id leave it here */
  deleteScheduledReports(id: string): Observable<any> {
    return this.http.delete(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/scheduled-reports?where=${JSON.stringify({ id: id })}`);
  }
  /*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

  sendReportEmail(id: number, email: string, b64: string, html: string): Observable<any> {
    let options = { report_id: id, email: email, b64: b64, html: html };
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/email-report`, options);
  }

  getDeployments(): Observable<any> {
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/deployments`);
  }

  getDeviceEvents(filter?: any): Observable<any> {
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/device-events${filter ? ('?filter=' + encodeURIComponent(JSON.stringify(filter))) : ''}`);
  }

  getSensorHistories(filter?: any): Observable<any> {
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/sensor-histories${filter ? ('?filter=' + encodeURIComponent(JSON.stringify(filter))) : ''}`);
  }

  getSegPesttrapData(start?: Date, end?: Date, active?: boolean): Observable<any> {
    return this.get('seg-pesttrap-data', { start: start, end: end, active: active });
  }

  getPerUnitSubs(): Observable<any> {
    const businessId = this.auth.getCurrentBusId();
    return this.http.get(`${env.apiUrl}/businesses/${businessId}/business-subscriptions?filter[fields][bs_plan_id]=true&filter[fields][bs_business_id]=true&filter=${JSON.stringify(
      { where: { bs_plan_id: 'skyhawk-ent-sensors-per-unit' } },
    )}`);
  }

  getSub(serial: string): Observable<any> {
    let businessId = this.auth.getCurrentBusId();
    let url = `${env.apiUrl}/businesses/${businessId}/business-subscriptions?filter[where][bs_device_serial]=${serial}`;
    url += 'filter[fields][bs_business_id]=true&filter[fields][bs_device_serial]=true';

    return this.http.get(url);
  }

  subscribeSerials(body: string[]): Observable<any> {
    return this.http.post(
      `${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/subscribe-devices`, body
    );
  }

  subscribeDevices(body: any): Observable<any> {
    return this.http.post(
      `${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/subscribe-devices-extended`, body
    );
  }

  getDevices(filter?: any): Observable<any> {
    return this.get('devices', { filter: filter });
  }

  getSensors(filter?: any): Observable<any> {
    return this.get('hubsensors', { filter: filter })
  }

  parseParam(key, val) {
    switch (typeof val) {
      case 'undefined':
        return;
      case 'object':
        if (val === null)
          return;
        if (val.getTime)
          val = val.getTime();
        else
          val = encodeURIComponent(JSON.stringify(val));
      default:
        return `${key}=${val}`;
    }
  }

  getEndpointUrl(endpoint: string, params?: any,): string {
    let paramStr = '?';
    if (typeof params == 'object') {
      if (Array.isArray(params))
        params = params.reduce((prev, cur) => ({ ...prev, ...cur }), {});
      if (params)
        paramStr += Object.entries(params)
          .map(el => this.parseParam(...el))
          .filter(el => !!el)
          .join('&');
    }
    return `${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/${endpoint}${paramStr}`;
  }

  assignTechniciansToLocation(technicianIds: string[], locationId: string): Observable<any> {
    const assignedBy = this.auth.getCurrentUser().id;
    const payload = {
      technicianIds: technicianIds,
      assignedBy: assignedBy,
      locationId: locationId
    };
    return this.http.post(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/technician-assignments`, payload);
  }

  unassignTechnicianFromLocation(locationId: string, technicianId: string): Observable<any> {
    return this.http.delete(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/technician-assignments?locationId=${locationId}&technicianId=${technicianId}`);
  }

  getTechnicianAssignments(locationId: string): Observable<any> {
    let filter = {
      where: {locationId: locationId},
      include: [
        {relation: 'technician'}
      ]
    };
    return this.http.get(`${env.apiUrl}/businesses/${this.auth.getCurrentBusId()}/technician-assignments?filter=${JSON.stringify(filter)}`);
  }
  generateVerificationReport(from: number, to: number, customerId: string, locationId: string): Observable<HttpResponse<Blob>> {
    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('customerId', customerId)
      .set('locationId', locationId);

    return this.http.get(`${env.apiUrl}/verificationreport/${this.auth.getCurrentBusId()}/generate`, {
      params,
      responseType: 'blob',
      observe: 'response'
    });
  }
  
  get(endpoint: string, params?: any): Observable<any> {
    return this.http.get(this.getEndpointUrl(endpoint, params));
  }

  post(endpoint: string, data: any, params?: any): Observable<any> {
    return this.http.post(this.getEndpointUrl(endpoint, params), data);
  }

  patch(endpoint: string, data: any, params?: any): Observable<any> {
    return this.http.patch(this.getEndpointUrl(endpoint, params), data);
  }

  delete(endpoint: string, params?: any): Observable<any> {
    return this.http.delete(this.getEndpointUrl(endpoint, params));
  }
}
