import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { DeviceDetailsComponent } from './device-details.component';
import { DeviceLogModalModule } from 'app/main/modals/device-log-modal/device-log-modal.module';
import { DeploymentHistoryModalModule } from 'app/main/modals/deployment-history-modal/deployment-history-modal.module';
import { GalleryModule } from '../../general/gallery/gallery.module';
import { MatInputModule } from '@angular/material/input';
import { LoadingModule } from '../../general/loading/loading.module';
const routes: Routes = [
  {
    path: 'devices/details',
    component: DeviceDetailsComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [DeviceDetailsComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    DeviceLogModalModule,
    DeploymentHistoryModalModule,
    GalleryModule,
    MatInputModule,
    LoadingModule
  ],
  providers: [DatePipe],
  exports: [DeviceDetailsComponent],
})
export class DeviceDetailsModule {}
