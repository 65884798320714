import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { AddCustomerLocationModalModule } from 'app/main/modals/add-customer-location-modal/add-customer-location-modal.module';
import { EditCustomerLocationModalModule } from 'app/main/modals/edit-customer-location-modal/edit-customer-location-modal.module';
import { CustomerLocationsComponent } from './customer-locations.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingModule } from '../../general/loading/loading.module';


const routes: Routes = [
  {
    path: 'customers/locations',
    component: CustomerLocationsComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [CustomerLocationsComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    AddCustomerLocationModalModule,
    EditCustomerLocationModalModule,
    MatTableModule,
    MatMenuModule,
    MatSortModule,
    PerfectScrollbarModule,
    MatSnackBarModule,
    LoadingModule
  ],
  exports: [CustomerLocationsComponent],
})
export class CustomerLocationsModule {}
