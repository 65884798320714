import { Component, OnDestroy, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, ChargebeeService, HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { forkJoin, from, Subject, throwError } from 'rxjs';
import { catchError, map, skipWhile, takeUntil } from 'rxjs/operators';
import { Input, Output, EventEmitter } from '@angular/core';
import { prettyPrintDate } from 'assets/formatting';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';


const darkTheme = [ 
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ color: "#d59563" }] },
  { featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#d59563" }] },
  { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#263c3f" }] },
  { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#6b9a76" }] },
  { featureType: "road", elementType: "geometry", stylers: [{ color: "#38414e" }] },
  { featureType: "road", elementType: "geometry.stroke", stylers: [{ color: "#212a37" }] },
  { featureType: "road", elementType: "labels.text.fill", stylers: [{ color: "#9ca5b3" }] },
  { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#746855" }] },
  { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#1f2835" }] },
  { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ color: "#f3d19c" }] },
  { featureType: "transit", elementType: "geometry", stylers: [{ color: "#2f3948" }] },
  { featureType: "transit.station", elementType: "labels.text.fill", stylers: [{ color: "#d59563" }] },
  { featureType: "water", elementType: "geometry", stylers: [{ color: "#17263c" }] },
  { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#515c6d" }] },
  { featureType: "water", elementType: "labels.text.stroke", stylers: [{ color: "#17263c" }] },
];
const defaultZoom = 3;
const defaultCenter = {lat: 39.2, lng: -97.55};

@Component({
    selector: 'app-device-map',
    templateUrl: './device-map.component.html',
    styleUrls: ['./device-map.component.scss'] })
  export class DeviceMapComponent {
    mapCfg: any = {
      styles: [
        ...(localStorage.getItem('skyhawk_ee__theme')?.includes('dark') ? darkTheme : []),
        {featureType: 'poi', elementType: 'labels', stylers: [{visibility: 'off'}]}
      ],
    };
    displayedColumns: string[] = ['name', 'serial', 'lastUpdate'];

    dataSource: any;
    data: any;
    zoom: any = 3;
    center: any = {lat: 39.2, lng: -97.55};
    loading: boolean = true;
    constructor(
      private httpService: HttpService,
      private modalService: ModalService,
    ){
      this.getDevices();
    }



    getDevices(): void {
      this.httpService.getAmbiguousBusinessDevices().pipe(
          catchError(this.modalService.errorDialog)
      ).subscribe((res: any) => {  
        res.forEach(el => {
          if(el.locationTracking && el.locationTracking.length > 0){
            let loc = el.locationTracking[el.locationTracking.length - 1],
              gps = { lng: parseFloat(loc.longitude), lat: parseFloat(loc.latitude) };
            el.lastLocation = { ...loc, gps: gps };
            el.lastUpdate = prettyPrintDate(el.lastLocation.created, {time: true});
          } else
            el.lastUpdate = "N/A";
        });
        res.sort((a, b) => !a.lastLocation ? 1 : !b.lastLocation ? -1 : a.lastUpdate > b.lastUpdate ? -1 : a.lastUpdate < b.lastUpdate ? 1 : 0);

        this.data = res.filter(el => el.locationTracking?.length > 0);
        this.loading = false;
        this.dataSource = new MatTableDataSource(res);
      });
  }

  navTo(device: any){
    if(!device.lastLocation){
      this.zoom = defaultZoom;
      this.center = defaultCenter;
    } else {
      this.zoom = 3;
      setTimeout(() => (this.zoom = 16, this.center = device.lastLocation.gps), 100);
    }
  }
  }