import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

const routes: Routes = [
    {
      path: 'loading',
      component: LoadingComponent,
    },
  ];

@NgModule({
  declarations: [LoadingComponent],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    BrowserModule,
    CommonModule,
  ],
  exports: [LoadingComponent],
})
export class LoadingModule {}
