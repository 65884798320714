import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, endWith, last, map, switchMap, takeUntil, } from 'rxjs/operators';
import { Location } from '@angular/common';
import { locale as english } from './i18n/en';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { prettyPrintPhone } from 'assets/formatting';

@Component({
  selector: 'app-customer-locations',
  templateUrl: './customer-locations.component.html',
  styleUrls: ['./customer-locations.component.scss'],
})
export class CustomerLocationsComponent implements OnInit, OnDestroy {
  @Input() customerId: string;
  loading: boolean = true;
  customerName: string;
  extCustId: string;

  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  selection = new SelectionModel<object>(true, []);
  displayedColumns: string[] = ['select', 'name', 'ext_loc_cust_id', 'full_address', 'contact_full_name', 'prettyEmail', 'prettyPhone', 'actions'];
  queryInput = new FormControl('');
  urlParams: any = new URLSearchParams(window.location.search);
  embedded: boolean;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private router: Router,
    private httpService: HttpService,
    private modalService: ModalService,
    private location: Location,
    private snackbar: MatSnackBar,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.embedded = !window.location.href.includes('/customers/locations');
    if(!this.embedded)
      this.customerId = this.urlParams.get('id');
    this.httpService
      .getBusinessAccount(this.customerId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((customer) => {
        this.customerName = customer.name;
        this.extCustId = customer.ext_cust_id;
      });

    this.fetchRows();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchRows(reload?: any): void {
    this.selection.clear();
    if(reload === undefined)
      this.loading = true;
    this.httpService
      .getBusinessAccountLocations(this.customerId)
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((locations) => this.parseLocations(locations)),
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        }),
      )
      .subscribe((locations) => {
        this.dataSource = new MatTableDataSource(locations);
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
          typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (el, val) => {
          for(let i of ['name', 'ext_loc_cust_id', 'full_address', 'contact_full_name', 'prettyEmail', 'prettyPhone'])
            if(el[i] && `${el[i]}`.toLowerCase().indexOf(val) !== -1)
              return true;
            return false;
        };
        this.loading = false;
      });
  }

  private parseLocations(locations: any[]): Observable<any[]> {
    const locationJobs: Observable<any>[] = [];

    locations.forEach((location) => {
      let address = `${location.address_1}`;
      if (location.address_2) 
        address += ` ${location.address_2}`;
      address += `, ${location.city}, ${location.state} ${location.zip}`;
      location.full_address = address;
      location.contact_full_name = `${location.contact_first_name} ${location.contact_last_name}`;
      location.prettyEmail = location.contact_email.toLowerCase();
      location.prettyPhone = prettyPrintPhone(location.contact_phone);

      // Get active devices
      locationJobs.push(
        this.httpService
          .getBusinessAccountDevicesCount(this.customerId, location.id, true)
          .pipe(
            map((res) => {
              location.device_count = res.count;
            }),
          ),
      );
    });

    return forkJoin(locationJobs).pipe(endWith(locations), last());
  }

  showAddLocationModal(): void {
    this.modalService
      .showAddCustomerLocationModal()
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((location) => {
        if (location) {
          this.addLocation(location);
        }
      });
  }

  private addLocation(location: any): void {
    this.httpService
      .addBusinessAccountLocation(this.customerId, location)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError((err) => {
          this.modalService.showModal(
            'Error',
            'An error has occured. Please try again.',
          );

          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.snackbar.open('Location successfully added', 'OK', {duration: 2000});
        this.fetchRows(0);
      });
  }

  showEditLocationModal(location: any): void {
    this.modalService
      .showEditCustomerLocationModal(location)
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((newLocation) => {
        if (newLocation) {
          this.editLocation(newLocation);
        }
      });
  }

  private editLocation(location: any): void {
    if(!location.ext_loc_cust_id)
      delete location.ext_loc_cust_id;
    this.httpService
      .editBusinessAccountLocation(location)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError((err) => {
          this.modalService.showModal(
            'Error',
            'An error has occured. Please try again.',
          );

          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.snackbar.open('Location successfully updated', 'OK', {duration: 2000})
        this.fetchRows(0);
      });
  }

  goToLocationDetails(location: any): void {
    this.router.navigate([
      '/customers',
      this.customerId,
      'locations',
      location.id,
      'details',
    ]);
  }

  showRemoveLocationModal(location: any): void {
    this.modalService
      .showConfirmModal(
        `Removal of a location means you will no longer be able to view
        historical data of devices deployed at this location.
        This can not be undone. Are you sure?`,
      )
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((confirm) => {
        if (confirm)
          this.removeLocations(location ? [location.id] : _.map(this.selection.selected, 'id') as string[]);
      });
  }
  
  showLocationNotificationsModal(location: any){
    this.modalService.showLocationNotificationsModal(location);
  }

  goBack(): void {
    //this.navigationService.goBack();
    this.location.back();
  }

  private removeLocations(locationIds: string[]): void {
    this.httpService
      .removeBusinessAccountLocations(locationIds)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError((err) => {
          this.modalService.showModal(
            'Error',
            'An error has occured. Please try again.',
          );

          return throwError(err);
        }),
      )
      .subscribe((res) => {
        const errors = this.getErrors(res);

        if (errors.length) {
          // Some location operations failed
          this.modalService.showModal(
            errors.length === locationIds.length
              ? 'Failed With Errors'
              : 'Completed With Errors',
            errors,
          );
        } else {
          // Show success modal
          this.snackbar.open('Locations successfully removed', 'OK', {duration: 2000})
        }
        this.fetchRows(0);
      });
  }

  private getErrors(response: any[]): any[] {
    const errors = response.filter((item) => item.error);

    // Some location operations failed
    errors.forEach((error) => {
      let name: string;

      if (error.locationId) {
        const location = this.dataSource.data.find((row) => row.id === error.locationId);
        name = location.name;
      } else {
        name = 'Error';
      }

      error.message = `${name}: ${error.message}`;
    });

    return errors;
  }
}
