<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <loading-component [complete]="!loading"></loading-component>
  <div *ngIf="!loading">
    <div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <mat-form-field class="search-devices-field">
            <mat-label>{{ 'DEVICES.ACTIONS.FILTER' | translate }}</mat-label>
            <input [formControl]="queryInput" matInput type="text"/>
            <button mat-button *ngIf="queryInput.value" matSuffix mat-icon-button (click)="queryInput.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field>
            <mat-select [formControl]="technicianFilter">
              <mat-option [value]="0">(All technicians)</mat-option>
              <mat-option *ngFor="let technician of technicians" value="{{technician}}">{{technician}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <perfect-scrollbar>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="devicename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let device"> {{ device.devicename }}</mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="devicedesc">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let device"> {{device.devicedesc}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="app_tc">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Alert count </mat-header-cell>
          <mat-cell *matCellDef="let device"> {{device.app_tc}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gps_label">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
          <mat-cell *matCellDef="let device">
            <ng-container *ngIf="device.gps">
              <a [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" (opened)="popoverOpened()">
                {{ device.gps_label }}
              </a>          
            </ng-container>
            <ng-container *ngIf="!device.gps">
              {{ device.gps_label }}
            </ng-container>
            <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
              <mat-card>
                <mat-card-content>
                  <google-map *ngIf="showMap" width="100%" height="300px"[center]="value">
                    <map-marker [position]="value"></map-marker>
                  </google-map>
                </mat-card-content>
              </mat-card>
            </mde-popover>
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="lastEventDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Last alert </mat-header-cell>
          <mat-cell *matCellDef="let device"> {{ device.lastEventDate }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastEventBody">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Details </mat-header-cell>
          <mat-cell *matCellDef="let device"> {{ device.lastEventBody }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let device; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div *ngIf="dataSource.data.length == 0">
        No devices with active alerts. 
      </div>
    </perfect-scrollbar>
  </div>
</div>