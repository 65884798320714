<div>
  <h2 mat-dialog-title>{{ ('MODAL.TITLES.UNDEPLOYED_DEVICE_DETAILS' | translate) + ' (' + data.device.name + ')' }}</h2>
  <mat-dialog-content style="height: 50vh">
    <perfect-scrollbar [config]="{suppressScrollX : true, suppressScrollY : true}" #perfectScrollBar>
      <mat-table *ngIf="dataSource?.data?.length" [dataSource]="dataSource" class="mat-elevation-z8" matSort multiTemplateDataRows>

        <ng-container matColumnDef="pretty_deploy_time">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
          <mat-cell *matCellDef="let el"> {{ el.pretty_deploy_time }} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="deploy_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let el"> {{el.deploy_name}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="result_type">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Result </mat-header-cell>
          <mat-cell *matCellDef="let el"> {{ el.result_type }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="expand" stickyEnd>  
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let el">
            <button mat-icon-button 
            class="action-button"
            (click)="(expandedElement = expandedElement === el ? null : el); $event.stopPropagation()">
              <mat-icon style="font-size:30px!important" *ngIf="expandedElement !== el">keyboard_arrow_down</mat-icon>
              <mat-icon style="font-size:30px!important" *ngIf="expandedElement === el">keyboard_arrow_up</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let el" [attr.colspan]="displayedColumns.length">
            <div [@detailExpand]="el == expandedElement ? 'expanded' : 'collapsed'" class="table-element-detail">
              <ng-container *ngIf="el == expandedElement">
                <div style="padding-left:5px;">
                  <table>
                    <tr>
                        <td> Deploy time: </td>
                        <td> {{ el.pretty_deploy_time }} </td>
                    </tr>
                    <tr>
                        <td> Deployed by: </td>
                        <td> {{ el.user_name }} </td>
                    </tr>
                    <tr>
                        <td> Deployment name: </td>
                        <td> {{ el.deploy_name }} </td>
                    </tr>
                    <tr>
                        <td style="padding-right:10px;"> Deployment notes: </td>
                        <td> {{ el.deploy_notes }} </td>
                    </tr>
                    <tr>
                        <td> Pickup notes: </td>
                        <td> {{ el.pickup_notes }} </td>
                    </tr>
                    <tr>
                        <td> Pickup time: </td>
                        <td> {{ el.pretty_pickup_time }} </td>
                    </tr>
                    <tr>
                        <td> Result: </td>
                        <td> {{ el.result_type }} </td>
                    </tr>
                  </table>
                </div>

              </ng-container>
            </div>
          </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let el; columns: displayedColumns;"
        [class]="el.businessSubscription ? '' : 'no-subscription'"
        [class.table-element-detail]="expandedElement === el">
        </mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>
      </mat-table>
      <div *ngIf="!dataSource?.data?.length" class="no-content">No deployments found.</div>
    </perfect-scrollbar>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <span>{{dataSource?.data.length || '0'}} total</span>
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
