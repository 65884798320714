export const locale = {
  lang: 'en',
  data: {
    DEVICES: {
      TITLE: 'Devices',
      ACTIONS: {
        ADD: 'Add Device',
        SUBSCRIBE: 'Subscribe',
        ASSIGN: 'Assign',
        UNASSIGN: 'Unassign',
        REMOVE: 'Remove',
        FILTER: 'Filter devices',
        DETAILS: 'Details',
        HISTORY: 'History',
        VIEW: 'View',
      },
      TABLE: {
        HEADER: {
          NAME: 'Name',
          SERIAL: 'Serial',
          ACTIONS: 'Actions',
          GPS: 'GPS Thumbnail',
          ASSIGNED_TECH: 'Assigned User',
          LAST_DEPLOYED: 'Last Deployed',
          LAST_TECH_CHECK: 'Last Tech Check',
          IMAGE: 'Image',
        },
        FOOTER: {
          EMPTY: 'No data to display',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
  },
};
