<div class="page-layout blank p-24">
    <div class="main-content" *ngIf="!loading">
            <div class="map-container-container">
                <google-map width="100%" height="100%" [options]="mapCfg" [zoom]="zoom" [center]="center">
                    <ng-container *ngFor="let el of data">
                        <map-marker [position]="el.lastLocation.gps" [options]="{visible: true}"></map-marker>
                    </ng-container>
                </google-map>
            </div>
            <div class="list-container">
                <perfect-scrollbar>
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8"  multiTemplateDataRows>
                    
                        <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let device"> 
                            <div class="name">{{ device.name }}</div>
                        </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="serial">
                        <mat-header-cell *matHeaderCellDef> Serial </mat-header-cell>
                        <mat-cell *matCellDef="let device"> {{device.serial}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lastUpdate">
                            <mat-header-cell *matHeaderCellDef> Last update </mat-header-cell>
                            <mat-cell *matCellDef="let device"> {{device.lastUpdate}} </mat-cell>
                            </ng-container>
    
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let device; columns: displayedColumns;" [class]="'row-class ' + (device.lastLocation ? '' : 'no-location')" (click)="navTo(device)">
                        </mat-row>
                    </mat-table>
                </perfect-scrollbar>
            </div>
        </div>
    </div>