<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.ADD_DEVICE' | translate }}</h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{
      suppressScrollX: true,
      scrollYMarginOffset: 10
    }"
  >
    <form [formGroup]="serialForm" fxLayout="column">
      <mat-form-field appearance="standard">
        <mat-label>Device Serial</mat-label>
        <input matInput type="text" formControlName="deviceSerial" required />
        <mat-icon matSuffix>location_on</mat-icon>
        <mat-error *ngIf="serialForm.get('deviceSerial').errors?.required">
          Device Serial is required
        </mat-error>
        <mat-error *ngIf="serialForm.get('deviceSerial').errors?.invalidSerial">
          {{ invalidSerialMsg ? invalidSerialMsg : 'Device Serial is invalid' }}
        </mat-error>
      </mat-form-field>
    </form>

    <form *ngIf="validSerial" [formGroup]="deviceForm" fxLayout="column">
      <mat-checkbox [checked]="customInfo" (change)="customInfo = !customInfo">
        Check this box to enter a custom name and description
      </mat-checkbox>
      <mat-form-field appearance="standard" *ngIf="customInfo">
        <mat-label>Device Name</mat-label>
        <input matInput type="text" formControlName="deviceName" maxlength="16" required />
        <mat-error *ngIf="deviceForm.get('deviceName').invalid">
          Device Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" *ngIf="customInfo">
        <mat-label>Device Description</mat-label>
        <input matInput type="text" formControlName="deviceDescription" maxlength="24"/>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button
      *ngIf="!validSerial"
      mat-button
      (click)="validateSerial()"
      [disabled]="serialForm.invalid"
    >
      {{ 'MODAL.ACTIONS.VALIDATE' | translate }}
    </button>
    <button
      *ngIf="validSerial"
      mat-button
      (click)="openChargebee()"
      [disabled]="disableAddBtn || (deviceForm.invalid && customInfo)"
    >
      {{ 'MODAL.ACTIONS.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
