import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthService, ModalService } from 'app/services';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { emailValidator } from 'validators';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginError: any;
  submitting: boolean;
  version: string;
  mfaForm: FormGroup;
  mfa: boolean;
  logoSrc = "assets/images/logos/skyhawk-text.png";

  private redirect: string;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit(): void {
    for(let i of Array.from(document.body.classList))
      if(i.includes('dark')){
        this.logoSrc = "assets/images/logos/skyhawk-text-dark.png";
        break;
      }

    this.route.queryParams.subscribe((params) => {
      this.redirect = params.redirect;
    });

    const email = localStorage.getItem('userEmail');
    this.version = environment.appVersion;

    this.loginForm = this._formBuilder.group({
      email: [email ? email : '', [Validators.required, emailValidator()]],
      password: ['', Validators.required],
      remember: [email !== null],
    });

    this.mfaForm = this._formBuilder.group({
      code: ['', Validators.required]
    });

  }

  resendOtp(): void {
    this.authService.login({
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        resend: true
      }, this.loginForm.get('remember').value)     
    .pipe(
      catchError((err) => {
        this.snackbar.open('Error sending verification code', 'OK', {duration: 2000});
        return throwError(err);
      }),
    )
    .subscribe(res => {
      this.snackbar.open('Verification code sent', 'OK', {duration: 2000});
    });
  }

  onSubmit(): void {
    this.submitting = true;
    this.loginError = null;

    const credentials = {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value,
      otp: this.mfa ? this.mfaForm.get('code').value : undefined,
    };

    const remember = this.loginForm.get('remember').value;
    if (remember) {
      localStorage.setItem('userEmail', credentials.email);
    } else {
      localStorage.removeItem('userEmail');
    }

    this.authService
      .login(credentials, this.loginForm.get('remember').value)
      .pipe(
        catchError((err) => {
          this.submitting = false;
          this.loginError = err;
          return throwError(err);
        }),
      )
      .subscribe(res => {
        this.submitting = false;
        if(res == 'otp'){
          this.mfa = true;
        } else {
          const resetPassword = this.authService.getCurrentUser().reset;
          if (resetPassword) {
            this.router.navigate(['/change-password'], {
              queryParamsHandling: 'preserve',
            });
          } else if (this.redirect) {
            this.router.navigateByUrl(this.redirect);
          } else {
            this.router.navigate(['/']);
          }
        }
      });
  }

  sendVerificationEmail(email: string): void {
    this.authService
      .sendVerificationEmail(email)
      .pipe(
        catchError((err) => {
          this.loginError = {code: 418, message: 'Error sending verification email. Please try again.'};
          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.loginError = null;
        this.modalService.showModal(
          'Success',
          'Verification email sent. Please check your inbox and click on the link to activate your account.',
        );
      });
  }

  sendConfirmationEmail(email: string): void {
    this.authService
      .sendConfirmationEmail(email)
      .pipe(
        catchError((err) => {
          this.loginError = {code: 418, message: 'Error sending business confirmation email. Please try again.'};
          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.loginError = null;
        this.modalService.showModal(
          'Success',
          'Verification email sent. Please check your inbox and click on the link to activate your account.',
        );
      });
  }

  toggleShow(controlName: string): void {
    let el = document.querySelector(`input[formcontrolname="${controlName}"]`);
    el.getAttribute('type') == 'password' ? el.setAttribute('type', 'text') : el.setAttribute('type', 'password');
  }
}
