//b̶e̶ ̶c̶a̶r̶e̶f̶u̶l̶:̶ ̶t̶h̶i̶s̶ ̶a̶l̶l̶ ̶s̶u̶c̶k̶s̶ - this is fine (01/31/23)

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    //checks if user is logged in, otherwise attempts to refresh token and redirects to login if fails
    const loginRedirect = () => ((window.location.pathname = 'login'), this.router.createUrlTree(['/login']));
    //this.router.createUrlTree(['/login'])) will never actually be reached. todo: cleaner implementation sometime
    return this.authService.isLoggedIn() ? of(true) :
      this.authService.refreshToken().pipe(
        catchError(() => of(loginRedirect())),
        map(() => !!this.authService.isLoggedIn() || loginRedirect())
      );
  }
}
