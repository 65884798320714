<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.ASSIGN_DEVICES' | translate }}</h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
    <form [formGroup]="assignDevicesForm" fxLayout="column">
      <mat-form-field appearance="standard">
        <mat-label>Technician To Assign To</mat-label>
        <mat-select formControlName="tech" required>
          <mat-option *ngFor="let user of userList" [value]="user.id">
            {{ user.label }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="assignDevicesForm.get('tech').invalid">
          Technician is required
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button
      mat-button
      (click)="sendDevicesAssign()"
      [disabled]="!assignDevicesForm.valid"
    >
      {{ 'MODAL.ACTIONS.ASSIGN' | translate }}
    </button>
  </mat-dialog-actions>
</div>
