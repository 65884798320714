import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuard } from 'app/guards';
import { Error403Component } from './error-403.component';

const routes: Routes = [
  {
    path: 'error-403',
    component: Error403Component,
    data: { title: 'Page Not Found' },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [Error403Component],
  imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule],
})
export class Error403Module {}
