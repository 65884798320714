<div class="page-layout blank p-24 inner-scroll">
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!embedded">
    <mat-form-field class="search-users-field">
      <mat-label>{{ 'USERS.ACTIONS.FILTER' | translate }}</mat-label>
      <input [formControl]="queryInput" matInput type="text"/>
      <button mat-button *ngIf="queryInput.value" matSuffix mat-icon-button (click)="queryInput.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="8px" fxFlexOffset="8px">
      <div fxLayout="row" fxLayoutGap="8px" fxFlexOffset="8px">
        <button mat-raised-button color="accent" (click)="showAddUsersModal()">
          {{ 'USERS.ACTIONS.ADD' | translate }}
        </button>
      </div>
      <button
        mat-raised-button
        color="warn"
        [disabled]="!filteredSelection.length"
        (click)="showRemoveUsersModal()"
      >
        {{ 'USERS.ACTIONS.REMOVE' | translate }}
      </button>
    </div>
  </div>
  <perfect-scrollbar>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let user">
          <mat-checkbox (click)="$event.stopPropagation()" 
          (change)="$event ? selection.toggle(user) : null"
          [checked]="selection.isSelected(user)"> 
          </mat-checkbox>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="emailLabel">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{ user.emailLabel }} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="fullName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.fullName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="prettyPhone">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.prettyPhone}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="businessRole">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.businessRole}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let user">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User actions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="showEditUserModal(user)">
              <mat-icon>edit</mat-icon>
              <span>Edit user</span>
            </button>
            <button mat-menu-item [disabled]="user.passwordReset === 1 || !user.emailVerified" (click)="showResetPasswordModal(user.email)">
              <mat-icon>lock</mat-icon>
              <span>Reset password</span>
            </button>
            <button mat-menu-item [disabled]="!user.pendingBusinessId" (click)="sendConfirmationEmail(user.email)">
              <mat-icon>email</mat-icon>
              <span>Resend confirmation email </span>
            </button>
            <button mat-menu-item (click)="showRemoveUsersModal([user])">
              <mat-icon>delete</mat-icon>
              <span>Remove user </span>
            </button>
          </mat-menu>
          
        </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" 
      [class]="row.pendingBusinessId ? 'pending' : ''">
      </mat-row>
    </mat-table>
  </perfect-scrollbar>
</div>
