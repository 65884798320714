import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { AddUsersModalModule } from 'app/main/modals/add-users-modal/add-users-modal.module';
import { EditUserModalModule } from 'app/main/modals/edit-user-modal/edit-user-modal.module';
import { UsersComponent } from './users.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [UsersComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    AddUsersModalModule,
    EditUserModalModule,
    MatTableModule,
    MatMenuModule,
    MatSortModule,
    PerfectScrollbarModule,
    MatSnackBarModule
  ],
  exports: [UsersComponent],
})
export class UsersModule {}
