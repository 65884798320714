import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

interface UserRequestForm {
  business: string;
  name: string;
  email: string;
  phone: string;
}

interface UserCredentials {
  email: string;
  password: string;
  otp?: string;
  resend?: boolean;
}

interface TokenResponse {
  token: string;
  ttl: number;
}

interface ResetPasswordForm {
  email: string;
}

interface ChangePasswordForm {
  curPassword: string;
  newPassword: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: BehaviorSubject<any>;
  currentBusiness: BehaviorSubject<any>;
  streamline: BehaviorSubject<any>;

  private authToken: BehaviorSubject<string>;
  private jwtHelper: JwtHelperService;
  private refreshTokenInterval: Subscription;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUser = new BehaviorSubject<any>(null);
    this.currentBusiness = new BehaviorSubject<any>(null);
    this.streamline = new BehaviorSubject<any>(false);
    this.authToken = new BehaviorSubject<string>(null);
    this.jwtHelper = new JwtHelperService();
  }

  signupRequest(userRequestForm: UserRequestForm): Observable<string> {
    return this.http.post(
      environment.apiUrl + '/signup_request',
      userRequestForm,
      { responseType: 'text' },
    );
  }

  login(userCredentials: UserCredentials, remember: boolean): Observable<string | void> {
    window.sessionStorage.clear();
    return this.http
      .post<TokenResponse>(
        environment.apiUrl + '/users/login?remember=' + remember,
        userCredentials,
        { withCredentials: true },
      )
      .pipe(
        map((res: any) => {
          if(res.message === 'OTP required'){
            return 'otp';
          } else {
            const user = this.jwtHelper.decodeToken(res.token);
            const error = this.validateUser(user);
            if (error) {
              throw error;
            }

            this.authToken.next(res.token);
            this.currentUser.next(user);
            this.currentBusiness.next(user.business);
            this.setupRefreshTokenInterval(res.ttl);
          }
        }),
      );
  }

  logout(): void {
    this.http
      .post(environment.apiUrl + '/users/logout', null, {
        withCredentials: true,
      })
      .pipe(
        map(() => {
          this.authToken.next(null);
          this.currentUser.next(null);
          this.currentBusiness.next(null);
          this.refreshTokenInterval.unsubscribe();
          this.refreshTokenInterval = null;
        }),
      ).subscribe(() => {
        this.router.navigate(['/login']);
      });

    window.sessionStorage.clear();
  }

  resetPassword(resetPasswordForm: ResetPasswordForm): Observable<string> {
    return this.http.post(
      environment.apiUrl + '/users/reset_password',
      resetPasswordForm,
      { responseType: 'text' },
    );
  }

  changePassword(changePasswordForm: ChangePasswordForm): Observable<string> {
    return this.http.post(
      environment.apiUrl + '/users/change_password',
      changePasswordForm,
      { responseType: 'text' },
    );
  }

  refreshToken(): Observable<void> {
    return this.http
      .post<TokenResponse>(environment.apiUrl + '/users/refresh_token', null, {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          const user = this.jwtHelper.decodeToken(res.token);

          const error = this.validateUser(user);
          if (error) {
            throw error;
          }

          this.authToken.next(res.token);
          this.currentUser.next(user);
          this.currentBusiness.next(user.business);
          this.setupRefreshTokenInterval(res.ttl);
        }),
      );
  }

  sendVerificationEmail(email: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/users/resend-verification?email=' + encodeURIComponent(email),
      null,
    );
  }

  sendConfirmationEmail(email: string): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/users/resend-business-verification?email=${encodeURIComponent(email)}`, null
    );
  }
  
  isAdmin(): boolean {
    return this.currentUser.value.roles.includes('busadm');
  }

  getToken(): string {
    return this.authToken.value;
  }

  getCurrentUser(): any {
    if(!this.currentUser.value)
      this.refreshToken();
    return this.currentUser.value;
  }

  getCurrentBusId(): any {
    return this.getCurrentBusiness().id;
  }

  isStreamline(): any {
    return !!this.streamline.value;
  }

  getCurrentBusiness(): any {
    if(!this.currentBusiness.value)
      this.refreshToken();
    return this.currentBusiness.value;
  }

  validateBusinessId(id: string): any {
    if(id && id != 'null' && id != 'undefined')
      return true;
    else 
      throw {error: 'L01', message: 'Invalid business ID.'};
  }

  isLoggedIn(): boolean {
    return this.authToken.value != null && this.getCurrentUser();
  }

  private setupRefreshTokenInterval(sec: number): void {
    if (this.refreshTokenInterval) {
      return;
    }

    // Add a 10% time buffer
    const buffer = Math.floor(sec * 0.1);

    this.refreshTokenInterval = interval((sec - buffer) * 1000).subscribe(
      () => {
        this.refreshToken().subscribe();
      },
    );
  }

  private validateUser(user: any): any {
    if (!user.bus) {
      return {
        code: 401,
        message: 'User is not associated with a business.',
      };
    }

    if (
      user.roles.indexOf('busadm') === -1 &&
      user.roles.indexOf('busfin') === -1
    ) {
      return {
        code: 401,
        message:
          'At this time, only accounts with administrator privileges can access the portal. Please contact an administrator if you have any questions.',
      };
    }

    return null;
  }
}
