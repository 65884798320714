<div id="password-confirm" fxLayout="column">
  <div
    id="password-confirm-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      id="password-confirm-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <div class="logo">
        <mat-icon class="mat-accent s-96">mail</mat-icon>
      </div>

      <div class="title">Password reset!</div>

      <div class="subtitle">
        <p>Your password has been successfully reset.</p>
        <p>We have sent you an email with your temporary password.</p>
      </div>

      <div class="message">
        <a class="link" [routerLink]="'/login'">Go back to login page</a>
      </div>
    </div>
  </div>
</div>
