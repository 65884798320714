import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService, HttpService } from 'app/services';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.authService.currentUser.pipe(
      filter((user) => user != null),
      switchMap(() => this.httpService.businessHasPaymentMethod()),
      catchError((err) => {
        return of(false);
      }),
      map((res) => {
        if (!res.length) {
          this.router.navigateByUrl('/payment-method');
          return this.router.createUrlTree(['/payment-method']);
        }

        return true;
      }),
    );
  }
}
