import { NgModule } from '@angular/core';
import { GalleryComponent } from './gallery.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [GalleryComponent],
  imports: [BrowserModule, CommonModule],
  exports: [GalleryComponent],
})
export class GalleryModule {}
