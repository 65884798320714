<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="m-0" *ngIf="!embedded">
    <mat-form-field class="search-devices-field">
      <mat-label>{{ 'CUSTOMERS.ACTIONS.FILTER' | translate }}</mat-label>
      <input [formControl]="queryInput" matInput type="text"/>
      <button mat-button *ngIf="queryInput.value" matSuffix mat-icon-button (click)="queryInput.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-raised-button color="accent" (click)="showAddCustomersModal()"> Add </button>
  </div>
  <perfect-scrollbar>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort multiTemplateDataRows>

      <!--
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let customer">
          <mat-checkbox (click)="$event.stopPropagation()" 
          (change)="$event ? selection.toggle(customer) : null"
          [checked]="selection.isSelected(customer)"
          [disabled]="!customer.businessSubscription">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      -->

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ext_cust_id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ext. ID </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.ext_cust_id }} </mat-cell>
      </ng-container>
    
    
      <ng-container matColumnDef="contact_full_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Contact </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{customer.contact_full_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contact_email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.contact_email }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pretty_contact_phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.pretty_contact_phone }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="segment_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.segment_name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active_devices">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Active devices </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.active_devices }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="devices_with_alerts">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Devices w/ alerts </mat-header-cell>
        <mat-cell *matCellDef="let customer"> {{ customer.devices_with_alerts }} </mat-cell>
      </ng-container>


      <ng-container matColumnDef="expand" stickyEnd>  
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let customer">
          <button mat-icon-button 
          class="action-button"
          (click)="setExpandedElement(customer); $event.stopPropagation()">
            <mat-icon style="font-size:30px!important" *ngIf="expandedElement !== customer">keyboard_arrow_down</mat-icon>
            <mat-icon style="font-size:30px!important" *ngIf="expandedElement === customer">keyboard_arrow_up</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let customer">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Customer actions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="showEditCustomerModal(customer)">
              <mat-icon>edit</mat-icon>
              <span>Edit customer</span>
            </button>
            <button mat-menu-item (click)="showRemoveCustomerModal(customer)">
              <mat-icon>delete</mat-icon>
              <span>Remove customer</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let customer" [attr.colspan]="displayedColumns.length">
          <div [@detailExpand]="customer == expandedElement ? 'expanded' : 'collapsed'" class="table-element-detail">
            <ng-container *ngIf="customer == expandedElement">
              <app-customer-locations [customerId]="customer.id"></app-customer-locations>
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let customer; columns: displayedColumns;"
      [class.table-element-detail]="expandedElement?.id === customer.id">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>
    </mat-table>
  </perfect-scrollbar>
</div>