<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <mat-accordion class="example-headers-align" style="width:100%">
    <!-- Business profile -->
    <mat-expansion-panel hideToggle [expanded]="testPath('business_profile', true)" (afterExpand)="setPath('business_profile')"> 
      <mat-expansion-panel-header>
        <mat-panel-title>
          Business profile
        </mat-panel-title>
        <mat-panel-description>
          Basic information regarding your business <mat-icon>domain</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="businessForm" fxLayout="column">
        <mat-form-field appearance="standard">
          <mat-label>Business Name</mat-label>
          <input matInput type="text" formControlName="name" maxlength="255" required />
          <mat-error *ngIf="businessForm.get('name').hasError('required')">
            Business Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address 1</mat-label>
          <input matInput type="text" formControlName="address_1" maxlength="255" required />
          <mat-error *ngIf="businessForm.get('address_1').hasError('required')">
            Address 1 is required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address 2</mat-label>
          <input matInput type="text" formControlName="address_2" maxlength="255" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="city" maxlength="255" required />
          <mat-error *ngIf="businessForm.get('city').hasError('required')">
            City is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" required>
            <mat-option disabled hidden value="invalid">Select a state</mat-option>
            <mat-option *ngFor="let state of states" [value]="state.short">
            {{ state.long }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="businessForm.get('state').invalid">
            State is required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="zip" maxlength="5" required />
          <mat-error *ngIf="businessForm.get('zip').errors">
            Please enter a valid 5-digit zip code
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Main Phone Number</mat-label>
          <input matInput type="tel" formControlName="phone" mask="(000) 000-0000"/>
          <mat-error *ngIf="businessForm.get('phone').hasError('mask')">Please enter a valid phone number</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Main Email Address</mat-label>
          <input matInput type="text" formControlName="email" maxlength="255" />
        </mat-form-field>
      </form>
      <div fxLayout="row" fxLayoutGap="8px" class="btn-row">
        <button mat-button color="accent" [disabled]="saveDisabled(businessForm)" (click)="updateBusiness(businessForm.value, 'Business profile saved')">
          Save
        </button>
        <button mat-button color="warn" type="button" [disabled]="resetDisabled(businessForm)" (click)="businessForm.initialize()">
          Reset
        </button>
      </div>
    </mat-expansion-panel>

    <!-- User profile -->
    <mat-expansion-panel hideToggle [expanded]="testPath('user_profile', true)" (afterExpand)="setPath('user_profile')"> 
      <mat-expansion-panel-header>
        <mat-panel-title>
          User profile
        </mat-panel-title>
        <mat-panel-description>
          Manage your personal user account <mat-icon>person</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="userForm">
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required />
          <mat-error
            *ngIf="
              userForm.get('email').errors?.required ||
              userForm.get('email').errors?.emailInvalid
            "
          >
            Enter a valid email address
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="12px">
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="first_name" required />
            <mat-error *ngIf="userForm.get('first_name').errors?.required">
              First name required
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="last_name" required />
            <mat-error *ngIf="userForm.get('last_name').errors?.required">
              Last name required
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Phone Number</mat-label>
          <input
            [disabled]="true"
            matInput
            type="tel "
            formControlName="phone"
            mask="(000) 000-0000"

          />
          <mat-error *ngIf="userForm.get('phone').errors">
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle formControlName="sms2FA" (change)="userForm.value.sms2FA ? userForm.get('phone').disable() : userForm.get('phone').enable()">Enable Two-Factor Authentication</mat-slide-toggle>
      </form>
      <div fxLayout="row" fxLayoutGap="8px" class="btn-row">
        <button mat-button color="accent" [disabled]="saveDisabled(userForm)" (click)="updateUser()">
          Save
        </button>
        <button mat-button color="warn" type="button" [disabled]="resetDisabled(userForm)" (click)="userForm.initialize()">
          Reset
        </button>
      </div>
    </mat-expansion-panel>

    <!-- Notification points -->
    <mat-expansion-panel hideToggle [expanded]="testPath('notification_points')" (afterExpand)="setPath('notification_points')"> 
      <mat-expansion-panel-header>
        <mat-panel-title>
          Notification points
        </mat-panel-title>
        <mat-panel-description>
          Create & configure points of contact to receive global device notifications and scheduled reports <mat-icon>notifications</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="name-container">
              <mat-form-field appearance="standard">
                <input 
                [disabled]="!row.edit" 
                matInput 
                placeholder="(800) 786-1410" 
                type="tel" 
                maxlength="255" 
                [id]="'phone_' + (row.id || row.tempId)" 
                [class]="'name-input ' + (!activeRow && row.phone ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : '')" 
                [(ngModel)]="row.phone" 
                mask="(000) 000-0000"
                /> 
                <mat-error *ngIf="!notiValidity.phone"></mat-error>
              </mat-form-field>
            </div>
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="name-container">
              <mat-form-field appearance="standard">
                <input 
                [disabled]="!row.edit" 
                matInput 
                placeholder="who.is@mike.jones.com" 
                type="text" 
                maxlength="255" 
                [id]="'email_' + (row.id || row.tempId)" 
                [class]="'name-input ' + (!activeRow && row.email ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : '')" 
                [(ngModel)]="row.email" 
                /> 
                <mat-error *ngIf="!notiValidity.email"></mat-error>
              </mat-form-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="name-container">
              <mat-form-field appearance="standard">
                <input 
                [disabled]="!row.edit"
                matInput 
                placeholder="Mike Jones" 
                type="text" 
                maxlength="255" 
                [id]="'description_' + (row.id || row.tempId)" 
                [class]="'name-input ' + (!activeRow && row.description ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : '')" 
                [(ngModel)]="row.description"
                />
              </mat-form-field>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="text_alerts">
          <mat-header-cell *matHeaderCellDef mat-sort-header> SMS alerts </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle 
            [disabled]="!row.edit || !row.phone || !notiValidity.phone" 
            [(ngModel)]="row.text_alerts"
            [class]="!activeRow && row.text_alerts ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : ''" 
            ></mat-slide-toggle>     
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email_alerts">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email alerts </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle 
            [disabled]="!row.edit || !row.email || !notiValidity.email" 
            [(ngModel)]="row.email_alerts"
            [class]="!activeRow && row.email_alerts ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : ''" 
            ></mat-slide-toggle>    
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="assigned_units">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Assigned units </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle 
            [disabled]="!row.edit || !(row.phone || row.email)" 
            [(ngModel)]="row.assigned_units"
            [class]="!activeRow && row.assigned_units ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : ''" 
            ></mat-slide-toggle>       
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unassigned_units">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Unassigned units </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle 
            [disabled]="!row.edit || !(row.phone || row.email)" 
            [(ngModel)]="row.unassigned_units"
            [class]="!activeRow && row.unassigned_units ? 'disabled-passive-opacity' : activeRow && !row.edit ? 'opaque' : ''" 
            ></mat-slide-toggle>       
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="controls">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button class="edit-btn" [disabled]="(!row.edit && activeRow) || !assessValidity(row)" (click)="toggleEdit(row)">
              <ng-container *ngIf="row.edit">
                <mat-icon>save</mat-icon>
              </ng-container>
              <ng-container *ngIf="!row.edit">
                <mat-icon>edit</mat-icon>
              </ng-container>
            </button>    

            <ng-container *ngIf="!row.edit">
              <button mat-icon-button class="edit-btn" [disabled]="activeRow" (click)="deleteNotificationPoint(row)">
              <mat-icon>delete</mat-icon>
              </button>    
            </ng-container>
            <ng-container *ngIf="row.edit">
              <button mat-icon-button class="edit-btn" (click)="cancelNotificationPointEdit(row)">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container> 
            <!--
            <button mat-icon-button class="edit-btn" (click)="logRow(row)">
              <mat-icon>comment</mat-icon>
            </button>
            -->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let el; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div fxLayout="row" fxLayoutGap="8px" class="btn-row">
        <button mat-button color="accent" [disabled]="activeRow" (click)="addNotificationPoint()">
          Create New
        </button>
      </div>
    </mat-expansion-panel>
    
    <!-- Change password -->
    <mat-expansion-panel hideToggle [expanded]="testPath('change_password')" (afterExpand)="setPath('change_password')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Change password
        </mat-panel-title>
        <mat-panel-description>
          Set a strong password to keep your account secure <mat-icon>lock</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-change-password></app-change-password>
    </mat-expansion-panel>

    <!-- Streamline mode -->
    <!--
    <mat-expansion-panel hideToggle [expanded]="testPath('streamline_mode')" (afterExpand)="setPath('streamline_mode')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Streamline mode
        </mat-panel-title>
        <mat-panel-description>
          Simplify device deployment by disabling some enterprise features<mat-icon>polymer</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div>
        <mat-slide-toggle [(ngModel)]="streamlineMode" (change)="toggleStreamline()">
          Streamline mode
        </mat-slide-toggle> 
        <div>
        <br>
        Streamline mode disables the enterprise mobile application's device deployment workflow and reverts users to SkyhawkCE views,
        <br> 
        providing a streamlined user experience closer to SkyhawkCE. This disables or changes the behavior of some features across applications:
        <ul>
          <li>Mobile application         
            <ul>
              <li>Reverts to SkyhawkCE views</li>
              <li>Removes enterprise deployment workflow</li>
            </ul>
          </li>
          <li>Enterprise portal 
            <ul>
              <li>Dashboard displays device activity summaries instead of deployment summaries</li>
              <li>Removes deployments summary report from Data and Analytics and scheduled reporting</li>
              <li>Removes deployment results from global search</li>
            </ul>
          </li>
        </ul>
        No existing data is lost by enabling streamline mode, and you can go back at any time. 
        </div>
      </div>
    </mat-expansion-panel>
    -->
    
    <!-- Global device view -->
    <mat-expansion-panel hideToggle disabled (click)="toggleGlobalDeviceView($event)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Enable global device view
        </mat-panel-title>
        <mat-panel-description>
          Allow technicians to see all unassigned enterprise devices in mobile app
          <mat-slide-toggle [(ngModel)]="viewGlobalDevices" (change)="toggleGlobalDeviceView($event)"></mat-slide-toggle> 
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel hideToggle disabled (click)="toggleAllowTechSubs($event)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Enable tech subscriptions
        </mat-panel-title>
        <mat-panel-description>
          Allow technicians to generate subscriptions when attempting to deploy a device an unsubscribed device
          <mat-slide-toggle [(ngModel)]="allowTechSubs" (change)="toggleAllowTechSubs($event)"></mat-slide-toggle> 
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
  <loading-component [complete]="complete"></loading-component>
</div>
