import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationTurkish } from './navigation/i18n/tr';
import { navigation } from './navigation/navigation';
import { AnalyticsService } from './providers/analytics/analytics.service';
import { AuthService } from './services';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  billingNavigation: any;
  currentNavigation: string;

  title: string;
  segment: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private cookieService: NgcCookieConsentService,
    private analyticsService: AnalyticsService,
  ) {
    // Get default navigation
    this.navigation = navigation;
    this.billingNavigation = this.getBillingNavigation();

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);
    this._fuseNavigationService.register('billing', this.billingNavigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');
    this.currentNavigation = 'main';

    // Add languages
    this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationTurkish,
    );

    // Use a language
    this._translateService.use('en');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     */

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     *  setTimeout(() => {
     *    this._translateService.setDefaultLang('en');
     *    this._translateService.setDefaultLang('tr');
     *  });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.title = this.titleService.getTitle();
    this.analyticsService.startTrackerWithId('G-Z20RT9EFP9');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let n = event.urlAfterRedirects;
        n = n.substring(1);
        this.analyticsService.trackView(n, environment.appVersion);
      }
    });

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        /* tslint:disable-next-line */
        let containsTheme = false;
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
          if (className == localStorage.getItem('skyhawk_ee__theme'))
            containsTheme = true;
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
        if(localStorage.getItem('skyhawk_ee__theme') && containsTheme)
          this.document.body.classList.add(localStorage.getItem('skyhawk_ee__theme'));
      });


    if(localStorage.getItem('skyhawk_ee__theme') && localStorage.getItem('skyhawk_ee__theme') !== 'theme-default' && document.body.classList.contains('theme-default'))
      document.body.classList.replace('theme-default', localStorage.getItem('skyhawk_ee__theme'));
  


    // Subscribe to route changes for page title updates
    /*
    combineLatest([
      this.authService.currentBusiness.pipe(skipWhile((bus) => bus == null)),
      this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)),
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(([bus]) => {
          const segment = bus.segment?.name.toLowerCase();

          return this.getSegmentName(segment);
        }),
        map((pre) => {
          const child = this.activatedRoute.firstChild;

          if (child.snapshot.data.title) {
            return pre + child.snapshot.data.title;
          }

          // Fallback to current title
          return pre + this.title;
        }),
      )
      .subscribe((newTitle) => this.titleService.setTitle(newTitle));

    this.authService.currentUser
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((user) => user != null),
      )
      .subscribe((val) => {
        const roleIds: string[] = val.roles;

        if (roleIds.indexOf('busadm') !== -1) {
          // Admin
          if (this.currentNavigation !== 'main') {
            this._fuseNavigationService.setCurrentNavigation('main');
            this.currentNavigation = 'main';
          }
        } else {
          // Billing Admin
          if (this.currentNavigation !== 'billing') {
            this._fuseNavigationService.setCurrentNavigation('billing');
            this.currentNavigation = 'billing';
          }
        }
      });
      */

    // Subscribe to cookie consent observables to handle main events
    this.cookieService.popupOpen$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {});

    this.cookieService.popupClose$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {});

    this.cookieService.initialize$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event: NgcInitializeEvent) => {});

    this.cookieService.statusChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event: NgcStatusChangeEvent) => {});

    this.cookieService.revokeChoice$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {});

    this.cookieService.noCookieLaw$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event: NgcNoCookieLawEvent) => {});
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key: any): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Set page title
   *
   * @param title
   */
  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  private getSegmentName(segment: string): string {
    switch (segment) {
      case 'pest':
        return 'Rodent Recon empowered by Skyhawk | ';
      default:
        return '';
    }
  }

  private getBillingNavigation(): any {
    if (!this.navigation) {
      return;
    }

    return this.navigation.filter((nav: any) => nav.id === 'billing');
  }
}
