<div id="error-403" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="error-code">403</div>

    <div class="message">
      Sorry but you are not authorized to access the page you are looking for
    </div>

    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon s-24">search</mat-icon>
      <input placeholder="Search for something" fxFlex />
    </div>

  </div>
</div>
