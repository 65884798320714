import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthGuard, PaymentGuard, RoleGuard } from 'app/guards';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AsyncWrapperGuard implements CanActivate {
  constructor(
    private authGuard: AuthGuard, 
    private paymentGuard: PaymentGuard,
    private roleGuard: RoleGuard
  ) {}
  
  //executes canActivate functions of auth.guard, payment.guard, and role.guard in that order
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<any>(subscriber => {
      const errHandler = err => {
        subscriber.next(err);
        throw err;
      };
      this.authGuard.canActivate(next, state).pipe(
        catchError(errHandler)
      ).subscribe(() => {
        this.paymentGuard.canActivate(next, state).pipe(
          catchError(errHandler)
        ).subscribe(() => {
          this.roleGuard.canActivate(next, state).pipe(
            catchError(errHandler)
          ).subscribe(res => {
            subscriber.next(res);
          });
        });
      });
    });
  }
}
