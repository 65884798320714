import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeploymentNotificationsModalComponent } from './deployment-notifications-modal.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table'; 
import { LoadingModule } from 'app/main/components/general/loading/loading.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  declarations: [DeploymentNotificationsModalComponent],
  imports: [
    MatDialogModule,
    TranslateModule,
    FuseSharedModule,
    MatButtonModule,
    MatTableModule,
    PerfectScrollbarModule,
    MatSortModule,
    LoadingModule
  ],
  exports: [DeploymentNotificationsModalComponent],
})
export class DeploymentNotificationsModalModule {}
