<div
  class="navbar-header"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo">
    <img
      *ngIf="!segment"
      class="logo-icon"
      src="assets/images/logos/skyhawk.png"
    />
    <span *ngIf="!segment" class="logo-text">SKYHAWK</span>
    <img
      *ngIf="segment === 'pest'"
      src="assets/images/logos/rodent-recon.png"
    />
  </div>

  <button
    mat-icon-button
    class="toggle-sidebar-folded"
    (click)="toggleSidebarFolded()"
    fxHide.lt-lg
  >
    <mat-icon>menu</mat-icon>
  </button>

  <button
    mat-icon-button
    class="toggle-sidebar-opened"
    (click)="toggleSidebarOpened()"
    fxHide.gt-md
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div
  class="navbar-content"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
>
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
