<h2 mat-dialog-title class="modal-title">Generate Verification Report</h2>

<mat-dialog-content [formGroup]="reportForm" class="modal-content">
  <mat-form-field appearance="fill" class="date-picker">
    <mat-label>Date range</mat-label>
    <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <div *ngIf="isLoading" class="loading-spinner">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p>Downloading your report...</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="modal-actions">
  <button mat-button class="cancel-button" (click)="onCancel()" [disabled]="isLoading">Cancel</button>
  <button mat-raised-button color="primary" class="download-button" (click)="onDownload()" [disabled]="isLoading">
    Download Report
  </button>
</mat-dialog-actions>
