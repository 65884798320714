import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { LocationNotificationsModalComponent } from './location-notifications-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserModule } from '@angular/platform-browser'; //bandaid runbax
import { CommonModule } from '@angular/common'; //couldnt miss out on r2

@NgModule({
  declarations: [LocationNotificationsModalComponent],
  imports: [
    NgxMaskModule.forRoot(),
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    BrowserModule,
    CommonModule
    
  ],
  exports: [LocationNotificationsModalComponent],
})
export class LocationNotificationsModalModule {}
