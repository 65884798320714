import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SettingsComponent } from './settings.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { LoadingModule } from '../../general/loading/loading.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CbPaymentMethodModule } from '../../billing/cb-payment-method/cb-payment-method.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    GooglePlaceModule,
    LoadingModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    CdkAccordionModule,
    CbPaymentMethodModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ChangePasswordModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatTableModule,
    MatSortModule,
    NgxMaskModule
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
