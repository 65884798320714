export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://rc-api.sky-hawk.io/api/v3',
  chargebee: 'picapd',
  cookieDomain: 'rc-manage.sky-hawk.io',
  googleMapsApiUrl: 'https://maps.googleapis.com/maps/api',
  googleApiKey: 'AIzaSyB5QP1a0OZleIqskik3J6WEtGwTLRcJiVA',
  appVersion: require('../../package.json').version + '-stage',
};
