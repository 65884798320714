<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div
      id="login-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <div class="logo">
        <img [src]="logoSrc"/>
      </div>

      <div class="header">
        <div class="title">{{mfa ? 'MULTI-FACTOR AUTHENTICATION' : 'LOGIN TO YOUR ACCOUNT'}}</div>
        <div class="error" *ngIf="loginError" [@animate]="fadeInOut">
          {{ loginError.message }}
          <a *ngIf="loginError.message == 'Account not verified.'" class="link" (click)="sendVerificationEmail(loginForm.get('email').value)">
            Resend verification email.
          </a>
          <a *ngIf="loginError.message == 'User is not associated with a business.'" class="link" (click)="sendConfirmationEmail(loginForm.get('email').value)">
            Resend business confirmation email.
          </a>
        </div>
      </div>
      <ng-container *ngIf="!mfa">
        <form
          name="loginForm"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <mat-error *ngIf="loginForm.get('email').hasError('required')">
              Email is required
            </mat-error>
            <mat-error
              *ngIf="
                !loginForm.get('email').hasError('required') &&
                loginForm.get('email').hasError('emailInvalid')
              "
            >
              Please enter a valid email address
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" />
            <mat-icon matSuffix class="secondary-text" (click)="toggleShow('password')">remove_red_eye</mat-icon>
            <mat-error>Password is required</mat-error>
          </mat-form-field>

          <div
            class="remember-forgot-password"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between center"
          >
            <mat-checkbox class="remember-me" formControlName="remember"
              >Remember Email</mat-checkbox
            >

            <a class="forgot-password" [routerLink]="'/forgot-password'">
              Forgot Password?
            </a>
          </div>

          <button
            mat-raised-button
            color="accent"
            class="submit-button"
            [disabled]="loginForm.invalid || submitting"
          >
            LOGIN
          </button>
        </form>

        <div class="register" fxLayout="column" fxLayoutAlign="center center">
          <span class="text">Don't have an account?</span>
          <a class="link" [routerLink]="'/register'">Request an account</a>
        </div>
      </ng-container>

      <ng-container *ngIf="mfa">
        <div>We've sent you a text message containing your verification code.</div>
        <br>
        <form
          name="mfaForm"
          [formGroup]="mfaForm"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <mat-form-field appearance="outline">
            <mat-label>Verification code</mat-label>
            <input matInput formControlName="code" />
            <mat-error *ngIf="mfaForm.get('code').hasError('required')">
              Verification code required
            </mat-error>
          </mat-form-field>

          <br>
          <br>

          <button
            mat-raised-button
            color="accent"
            class="submit-button"
            [disabled]="mfaForm.invalid || submitting"
          >
            LOGIN
          </button>
        </form>

        <div class="register" fxLayout="column" fxLayoutAlign="center center">
          <span class="text">Didn't receive the text?</span>
          <a class="link" (click)="resendOtp()">Send another code</a>
        </div>
      </ng-container>

      <div class="version">
        <span>v{{version}}</span>
      </div>
    </div>
  </div>
</div>
