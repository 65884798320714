import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { Subject, throwError} from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { emailValidator } from 'validators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { AuthService } from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  user: any;
  rolesList: any[];
  rolesObj: any;
  userEmail: FormControl;
  users: string[];
  userForm: FormGroup;
  currentUserId = this.authService.currentUser['_value'].id;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private httpService: HttpService,
    private modalService: ModalService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    protected dialogRef: MatDialogRef<EditUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this.user = data.user;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      email: new FormControl(this.data.user.email, [Validators.required, emailValidator()]),
      first_name: new FormControl(this.data.user.first_name, [Validators.required]),
      last_name: new FormControl(this.data.user.last_name, [Validators.required]),
      phone: new FormControl(this.data.user.phone, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      role: new FormControl('', [Validators.required])
    });
    this.rolesList = [
      {id: 0, name: 'user', display_name: 'User'},
      {id: 10, name: 'busadm', display_name: 'Admin'},
      {id: 11, name: 'busfin', display_name: 'Billing Admin'}
    ];
    this.userForm.get('role').setValue(this.rolesList.find(el => el.display_name == this.user.businessRole).name);
  
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ *\

                     ,__________________________________,
                     
                   things that i still think should happen
             ~*   in the database/api even though we handled   *~
                       it clientside in the enterprise 
                                  portal
                     ,_________________________________,

      1. globally update rolemappings to remove business billing
        role from users that are business admins
      2. add business user role and globally update rolemappings to
        add bususr role to all business users that are not billing
        or business admins
      3. add display_name field to role model and add display names
        to entries (THE CODE ASSUMES THIS ONE IS BEING DONE!)

    \* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onResize(event: Event): void {
    //this.updateDialogPos();
  }

  save(changeCurrentEmailConfirmed?: boolean): void {
    if(!changeCurrentEmailConfirmed && 
        this.user.id == this.currentUserId && 
        this.userForm.value.email !== this.authService.currentUser['_value'].email
      ) {
      this.modalService
      .showConfirmModal(`You are attempting to change the email address that you are logged in under. Doing so will cause you to be forcibly logged out. Do you wish to continue?`)
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => !res || this.save(true));
    } else {
      let body = {...this.userForm.value, role: undefined, roles: undefined};

      let error = err => {
        this.modalService.showModal(
          'Error',
          'An error has occured. Please try again.',
        );
        return throwError(err);
      };

      let close = () => {
        this.snackbar.open('User updated successfully', 'OK', {duration: 2000})
        if(changeCurrentEmailConfirmed)
          this.authService.logout();
        this.dialogRef.close(true);

      }
          
      
      //@patching to the '/businesses/{id}/users?where={id}' endpoint will always send a 
      //  verification email to the value specified in the payload (regardless of whether or not it has changed),
      //  so we have to remove the value from the payload unless it has changed in order to avoid
      //  people getting bunk verification emails every time they modify a user :shrug:
      this.httpService.updateUser(this.user.id, {...body, ...(this.user.email != body.email ? {} : {email: undefined})}).pipe(
        takeUntil(this._unsubscribeAll),
        catchError(error)
      ).subscribe(res => {
        if(this.userForm.value.role !== this.rolesList.find(el => el.display_name == this.user.businessRole).name)
          //need to promote or demote user. but if we need to promote, we need to demote first otherwise it gets wack
          this.httpService.demoteBusinessUser(this.user.id)
          .pipe(
            takeUntil(this._unsubscribeAll),
            catchError(error)
          ).subscribe(() => {
            if(this.userForm.value.role == 'user')
              close();
            else 
              this.httpService.promoteBusinessUser(this.user.id, this.userForm.value.role).pipe(
                takeUntil(this._unsubscribeAll),
                catchError(error)
              ).subscribe(() => {
                close();
              })
          });
        else {
          //no need to promote or demote user
          close();
        }
      });
    }
  }
}
