<div>
  <div *ngIf="!newUsers.length">
    <h2 mat-dialog-title>{{ 'MODAL.TITLES.ADD_USERS' | translate }}</h2>
    <mat-dialog-content
      fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
    >
      <p>You can add multiple users at once by clicking the '+' icon</p>
      <mat-form-field appearance="standard" class="w-100-p">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          [formControl]="userEmail"
          (keyup.enter)="addUser()"
          required
        />
        <button
          mat-icon-button
          matSuffix
          color="accent"
          (click)="addUser()"
          title="Add email"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <mat-error
          *ngIf="userEmail.errors?.required || userEmail.errors?.emailInvalid"
        >
          Enter a valid email address
        </mat-error>
        <mat-error *ngIf="userEmail.errors?.userAlreadyAdded">
          User already added
        </mat-error>
      </mat-form-field>
      <mat-list *ngIf="users.length">
        <mat-list-item *ngFor="let user of users">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="w-100-p"
          >
            <span>{{ user }}</span>
            <button mat-icon-button color="warn" (click)="removeUser(user)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mt-12">
      <button mat-button mat-dialog-close>
        {{ 'MODAL.ACTIONS.CANCEL' | translate }}
      </button>
      <button
        mat-button
        (click)="doUsersExist()"
        [disabled]="!userEmail.valid && !users.length"
      >
        {{ 'MODAL.ACTIONS.ADD' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngIf="newUsers.length">
    <h2 mat-dialog-title>{{ 'MODAL.TITLES.CREATE_USER' | translate }}</h2>
    <mat-dialog-content
      fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
    >
      <form [formGroup]="newUserForm">
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required />
          <mat-error
            *ngIf="
              newUserForm.get('email').errors?.required ||
              newUserForm.get('email').errors?.emailInvalid
            "
          >
            Enter a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Confirm Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="confirmEmail"
            required
          />
          <mat-error
            *ngIf="
              newUserForm.get('confirmEmail').errors?.required ||
              newUserForm.get('confirmEmail').errors?.emailInvalid
            "
          >
            Enter a valid email address
          </mat-error>
          <mat-error
            *ngIf="newUserForm.get('confirmEmail').errors?.emailMismatch"
          >
            Emails must match
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="12px">
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" required />
            <mat-error *ngIf="newUserForm.get('firstName').errors?.required">
              First name required
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" required />
            <mat-error *ngIf="newUserForm.get('lastName').errors?.required">
              Last name required
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Phone Number</mat-label>
          <input
            matInput
            type="tel "
            formControlName="phone"
            mask="(000) 000-0000"
            required
          />
          <mat-error *ngIf="newUserForm.get('phone').errors">
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="12px">
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              formControlName="password"
              required
            />
            <mat-error *ngIf="newUserForm.get('password').errors?.required">
              Password required
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              type="password"
              formControlName="confirmPassword"
              required
            />
            <mat-error
              *ngIf="newUserForm.get('confirmPassword').errors?.required"
            >
              Password required
            </mat-error>
            <mat-error
              *ngIf="
                newUserForm.get('confirmPassword').errors?.passwordMismatch
              "
            >
              Passwords must match
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mt-12">
      <button mat-button mat-dialog-close>
        {{ 'MODAL.ACTIONS.CANCEL' | translate }}
      </button>
      <button mat-button [disabled]="newUserForm.invalid" (click)="shiftUser()">
        {{ 'MODAL.ACTIONS.CREATE' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
