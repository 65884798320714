import { Component, OnDestroy, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {
  HttpService,
  ModalService,
  PickupSensor,
} from 'app/services';
import { defer, from, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatAll,
  filter,
  map,
  switchMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';
import Sketch from "@arcgis/core/widgets/Sketch";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Polygon from "@arcgis/core/geometry/Polygon";
import Point from "@arcgis/core/geometry/Point";
import { NavigationService } from 'app/services';
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Basemap from "@arcgis/core/Basemap";
import * as geometry from "@arcgis/core/geometry";
import { prettyPrintDate } from 'assets/formatting';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, OnDestroy { 
    private _unsubscribeAll: Subject<any>;

    constructor(
        private navService: NavigationService
    ){
        this._unsubscribeAll = new Subject();

    }
    ngOnInit(): void {
        
    }

    ngOnDestroy(): void {
        
    }
    
    back(){ this.navService.goBack(); }
}