import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, HttpService, ModalService } from 'app/services';
import { Subject } from 'rxjs';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { prettyPrintDate } from 'assets/formatting';
import { Router } from '@angular/router';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const beginDate = new Date(Date.now() - (1*86400000)); //set to 1 day before
const today = new Date();
const dpOvBeginDate = new Date(Date.now() - (7*86400000));
const csvSafetyRegex = /(,)|(\n)|(    )|(#)/g;
dpOvBeginDate.setHours(0,0,0,0);
beginDate.setHours(0,0,0,0);
today.setHours(0,0,0,0);

const styles = {
    defaultStyle: {
      fontSize: 9
    },
  },
  layouts = {
    thinBorders: {
      hLineWidth: (i, node) => 1,
      vLineWidth: (i, node) => 1,
    },
    kindaLightBorders: {
      hLineColor: (i, node) => '#555555',
      vLineColor: (i, node) => '#555555'
    },
    lightBorders: {
      hLineColor: (i, node) => '#999999',
      vLineColor: (i, node) => '#999999'
    },
    reallyLightBorders: {
      hLineColor: (i, node) => '#DDDDDD',
      vLineColor: (i, node) => '#DDDDDD'
    }
  },
  margins = {
    none: [0, 0, 0, 0],
    noBorder: [-5, 0, 0, 0],
    bottomBorder: [-5, 0, 0, 2]
  },
  borders = {
    bottom: [false, false, false, true],
    none: [false, false, false, false]
  },
  svgs = {
    warehouse: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path style="fill:#696969" d="M528 352H352V240c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16v256c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zM304 464H144v-64h160v64zm0-128H144v-64h160v64zm192 128H352v-64h144v64zm101.9-353.9L346.3 5.3c-17-7-35.7-7.1-52.6 0L42.1 110.1C16.5 120.7 0 145.5 0 173.2V496c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V173.2c0-8.3 4.9-15.7 12.5-18.8L312.2 49.6c5.1-2.1 10.6-2.1 15.7 0l251.6 104.8c7.6 3.2 12.5 10.6 12.5 18.8V496c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V173.2c0-27.7-16.5-52.5-42.1-63.1z"/></svg>',
    device: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path style="fill:#696969" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-64 452c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v8zm64-80c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"/></svg>',
    tasks: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path style="fill:#696969" d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"/></svg>',
  },
  footer = {
    text: 'Generated in SkyhawkEE • PICA Product Development, LLC ©2024',
    alignment: 'center',
    color: '#CCC',
    italics: true,
    fontSize: 8
  },
  buildTextCell = (text: string, options ? : any): any => {
    let cell: any = {};
    if(options?.borderBottom){
      cell.border = borders.bottom;
      cell.margin = margins.bottomBorder;
      delete options.borderBottom;
    } else {
      cell.border = borders.none;
      cell.margin = margins.noBorder;
    }
    return {
      text: text,
      ...cell,
      ...(options || {})
    };
  },
  buildSvgCell = (svg: string, options ? : any): any => {
    let cell: any = {};
    if(options?.borderBottom){
      cell.border = borders.bottom;
      cell.margin = margins.bottomBorder;
      delete options.borderBottom;
    } else {
      cell.border = borders.none;
      cell.margin = margins.noBorder;
    }
    return {
      svg: svg,
      ...cell,
      ...(options || {})
    };
  },
  buildHeader = (obj: any): any => {
    return {
      layout: {
        ...layouts.thinBorders,
        ...layouts.kindaLightBorders
      },
      table: {
        headerRows: 2,
        widths: ['*', 45],
        heights: [15, 'auto'],
        body: [
          [
            buildTextCell(`${obj._title} (${obj._beginDate ? (prettyPrintDate(obj._beginDate) + ' - ') : ''}${prettyPrintDate(obj._endDate)})`, {
              fontSize: 14
            }),
            buildSvgCell(obj._svg, {
              fit: [45, 30],
              rowSpan: 2,
              bottomBorder: true
            })
          ],
          [
            buildTextCell(obj._businessName, {
              italics: true,
              fontSize: 9,
              borderBottom: true,
              colSpan: 2
            }),
            {}
          ]
        ]
      }
    }
  };

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  reports: any = [
    {
          title: 'Deployment Detail Report',
          textLabel: 'Deployment Details',
          id: 'DeploymentDetails',
          iframe: null,
          svg: svgs.tasks,
          generateContent: 'deploymentDetailsContent',
          generateCsv: 'deploymentDetailsCsv',
          initialFilterProps: {
            beginDate: beginDate,
          },
          filterProps: null,
          filter: (el => {
            let _ = this.reports.DeploymentDetails.filterProps;
            if(!_)
              return true;
            if(_.customers?.length)
              if(!_.customers.find(j => j == el.bus_sub_id))
                return false;
            if(_.locations?.length)
              if(!_.locations.find(j => j == el?.id))
                return false;
            if(!el.segPesttrapData)
              return false;
  
            el.segPesttrapData = el.segPesttrapData.filter(j => {
              if(_.status == 'active' && j.pickup_time)
                return false;
              if(_.status == 'inactive' && !j.pickup_time)
                return false;
              return true;
            });
            if(el.segPesttrapData.length == 0)
              return false;
            return true;
          }),
          modalKey: 'showCustomizeDeploymentDetailsReportModal',
          loading: true,
          data: <any>{}
        },
        {
          title: 'Seven Day Deployment Report',
          textLabel: 'Deployment Overview',
          id: 'DeploymentOverview',
          iframe: null,
          svg: svgs.warehouse,
          generateContent: 'deploymentOverviewContent',
          generateCsv: 'deploymentOverviewCsv',
          loading: true,
          initialFilterProps: {beginDate: dpOvBeginDate},
          data: <any>{}
        },
        {
          title: 'Device Alert Report',
          textLabel: 'Device Alerts',
          id: 'DeviceAlerts',
          iframe: null,
          svg: svgs.device,
          initialFilterProps: {
            beginDate: beginDate,
          },
          modalKey: 'showCustomizeDevicesReportModal',
          filterProps: null,
          filter:  (el => {
            let _ = this.reports.DeviceAlerts.filterProps || this.reports.DeviceAlerts.initialFilterProps;
            if(_.hideHubs && (el.device_serial || el.sensor_serial).match(/SHH.*/)){
              return false;
            }
            if(_.users?.length)
              if(!_.users.find(j => j == el.user_id))
                return false;
            if(_.devices?.length)
              if(!_.devices.find(j => j == el.deviceId))
                return false;
            if(_.locations?.length)
              if(!_.locations.find(j => this.activeSegPesttrapData.find(k => k.ext_cus_location_id == j && k.device_id == el.deviceId)))
                return false;
            if(_.beginDate?.getTime() > (new Date(el.date)).getTime())
              return false;
            if(_.endDate?.getTime() < (new Date(el.date)).getTime())
              return false;
            if(_.events?.length){
              let eventMatch = false;
              let body = el?.body?.toLowerCase();
              for(let i of _.events){
                if(eventMatch)
                  break;
                else 
                  switch(i){
                    case 'magnetremoved': 
                      if(body.match(/(magnet|reed) remove/))
                        eventMatch = true;
                      break;
                    case 'magnetreplaced':
                      if(body.match(/(magnet|reed) replace/))
                        eventMatch = true;
                      break;
                    case 'accel':
                      if(body.match(/vibration/))
                        eventMatch = true;
                      break;
                    case 'pir':
                      if(body.match(/pir\/ir triggered/))
                        eventMatch = true;
                      break;
                    case 'missedheartbeat':
                      if(body.match(/miss.*heartbeat/))
                        eventMatch = true;
                      break;
                    default: '';
                  }
                }
    
              if(!eventMatch)
                  return false;
            }
            return true;
          }),
          generateContent: 'deviceAlertsContent',
          generateCsv: 'deviceAlertsCsv',
          loading: true,
          data: <any>{}
        },
        {
          title: 'System Verification Report',
          textLabel: 'System Verification Report',
          id: 'SystemVerificationReport',
          iframe: null,
          generateContent: 'systemVerificationReportContent',
          modalKey: 'showCustomizeSystemVerificationReportModal',
          loading: false,
          data: <any> {}
        }
      ];
  urlParams: any = new URLSearchParams(window.location.search);
  technicians: any = [];
  deployments: any = [];
  devices: any = [];
  events: any = [];
  activeSegPesttrapData: any = [];
  canShare: boolean = !!(navigator as any).canShare;
  streamline: boolean;
  segPesttrapData: any;
  users: any;
  locations: any;
  businessName = this.authService.currentBusiness.value.name;
  tabIndex = parseInt(this.urlParams.get('tab') || '0');
  optionSelect = {
    customers: null,
    locations: null,
    users: null,
    devices: null,
  };
  _activeReport: any = {};


  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router,
  ){
    this._unsubscribeAll = new Subject();
    this.reports.forEach(el => {
      this.reports[el.textLabel] = el;
      this.reports[el.id] = el;
    });
    if(this.urlParams.get('audit')){
      this.tabIndex = 2;
      let auditDate = new Date(Date.now() - (7*86400000)); //set to 7 days before
      auditDate.setHours(0,0,0,0);
      this.reports[2].filterProps = {beginDate: auditDate, locations: [this.urlParams.get('audit')]};
    }
    this.activeReport = this.tabIndex;
  }

  get DeploymentOverview(){ return this.reports.DeploymentOverview; }
  get DeploymentDetails(){ return this.reports.DeploymentDetails; }
  get DeviceAlerts(){ return this.reports.DeviceAlerts; }
  get SystemVerificationReport(){ return this.reports.SystemVerificationReport; }
  get activeReport(){ return this._activeReport; }
  set activeReport(report){
    if(typeof report == 'number')
      report = this.reports[report];
    let startTime = Date.now();
    report.loading = true;
    report.loadingCallback = e => {
      report.loading = false;
    };
    this._activeReport = report;
    this.generateReport(report)
      .then(() => report.loadingCallback())
      .catch(err => {
        report.loadingCallback();
        this.modalService.errorDialog(err);
    });
  }



  ngOnInit(): void {
    //populate customers, locations, devices, users select options for filters
    let sortFn = (a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0;
    Promise.all([
      this.httpService.getSubaccountLocations().toPromise().then(res => {
        res = res.filter(el => !el.inactive);
        if (res.length > 0 && res[0].locations && res[0].locations.length > 0) {
          const defaultCustomerId = res[0].id;
          const defaultLocationId = res[0].locations[0].id;
          const verificationReport = this.reports['SystemVerificationReport'];
          verificationReport.filterProps = {
            customerId: defaultCustomerId,
            locationId: defaultLocationId,
            beginDate: beginDate
          };
        }
        this.optionSelect.customers = res.map(el => ({value: el.id, label: el.name}))
          .sort(sortFn);
        this.optionSelect.locations = res.flatMap(el => el.locations || [])
          .map(el => ({value: el.id, label: el.name}))
          .sort(sortFn);
      }),
      this.httpService.getBusinessUsers().toPromise().then(res => {
        this.optionSelect.users = res.map(el => ({value: el.id, label: `${el.first_name} ${el.last_name}`}))
          .sort(sortFn);
      }),
      Promise.all([
        this.httpService.getBusinessHubSensors({users: true, businessSubscription: true, sensorHistories: true, segPestTrapData: true}).toPromise(),
        this.httpService.getBusinessDevices({users: true, businessSubscription: true, sensorHistories: true, segPestTrapData: true, deviceEvents: true, locationTracking: true}).toPromise()
      ]).then(res =>{
        let devices = [...res[0], ...res[1]];
        this.optionSelect.devices = devices.map(el => ({value: el.id, label: el.devicename})).sort(sortFn);
      })
    ]);    
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  injectIframe(report){
    let iframe = document.createElement('iframe');
    iframe.classList.add('report-iframe', report.id);
    document.querySelector(`div[id=${report.id}]`).appendChild(iframe);
    iframe.addEventListener("load", e => report.loadingCallback(e));
    report.iframe = iframe;
    return iframe;
  }
  
  changeTab($event): void {
    const report = this.reports[$event.tab.textLabel];

    if (!report) {
      console.error('Report not found for tab:', $event.tab.textLabel);
      return;
    }

    this.router.navigate([], { queryParams: { tab: $event.index }, replaceUrl: true });
    this.activeReport = report;
  }

  showScheduledReportModal(): void {
    this.modalService.showScheduledReportModal();
  }


  customizeReport(report): void {
    let savedProps = report.filterProps || {};
    if(!savedProps.beginDate)
    savedProps.beginDate = beginDate;
    this.modalService[report.modalKey](this.optionSelect, savedProps).afterClosed()
    .subscribe(res => {
      //pass `true` to reset, or an object of {[filterProperties]: [values]}
      if(!res)
        return;
      if(res === true)
        res = report.initialFilterProps;
      else {
        Object.entries(res).forEach(el => {
          let [key, val] = el;
          if(typeof val === undefined)
            delete res[key];
          else if(Array.isArray(val) && val.length == 0)
            delete res[key];
          else if(val instanceof Date){
            if (key === 'endDate') val.setHours(23,59,59,999);
            else val.setHours(0,0,0,0);

            if(val.getTime() > Date.now())
              delete res[key];
          }
        })
      }
      report.filterProps = res;
      this.activeReport = report;
    });
  }

  async shareReport(){
    if(!this.canShare)
      return this.modalService.showModal('Unsupported', 'Sharing is not supported by this browser. Please download the report and share it through your email client.');
    
    try {
      let promise = new Promise((res, rej) => {
          this.activeReport.pdf.getBlob(blob => res(blob));
      });
      let blob: any = await promise;
      let file = new File([blob], `${this.activeReport.title} - Skyhawk Enterperise.pdf`, {type: blob.type});
      let shareData: any = {
            title: 'Skyhawk Enterprise',
            text: this.activeReport.title,
            files: [file],
          };
      await navigator.share(shareData);
    } catch (err){
      if(err.message == 'Abort due to cancellation of share.')
        return;
      this.modalService.errorDialog(err);
      console.error(err);
    }
  }

  async generateReport(report) {
    return new Promise(async (res, rej) => {
      try {
        if (report.generateContent === 'systemVerificationReportContent') {
          await this.loadVerificationData(report);
          res(true);
        } else {
          let content = await this[report.generateContent](report);
          if (!Array.isArray(content)) {
            content = [];
          }
          report.pdf = pdfMake.createPdf({
            info: {
              title: report.title
            },
            content: [
              buildHeader({
                _title: report.title,
                _beginDate: report.filterProps?.beginDate || report.initialFilterProps?.beginDate || beginDate,
                _endDate: report.filterProps?.endDate || report.initialFilterProps?.endDate || today,
                _svg: report.svg,
                _businessName: this.businessName
              }),
              ' ',
              ...content
            ],
            footer: footer,
            ...styles
          });
          if (!report.iframe) {
            this.injectIframe(report);
          }
          report.pdf.getDataUrl((url) => {
            report.iframe.src = url;
          });
          res(true);
        }
      } catch(err) {
        rej(err);
      }
    });
  }

  loadDeploymentDetails(report): Promise<any> {
    let data: any = {
      locationsKey: {},
      usersKey: {}
    };
      
    return Promise.all([
      this.httpService.getSegPesttrapData(
        report.filterProps?.beginDate || beginDate,
        report.filterProps?.endDate
      ).toPromise().then(res => {
        data.segPesttrapData = res;
      }),
      this.httpService.getBusinessUsers().toPromise().then(res => {
        data.users = res;
        for(let user of data.users){
          if(user.first_name && user.last_name)
            user.fullName = `${user.first_name} ${user.last_name}`;
          else
            user.fullName = '[name not found]';
          data.usersKey[user.id] = user;
        }
      }),
      this.httpService.getSubaccountLocations().toPromise().then(res => {
        for(let customer of res){
          if(customer.locations)
            for(let location of customer.locations)
              location.customerName = customer.name;
        }
        data.locations = res.flatMap(el => el.locations || []);
        for(let location of data.locations){
          location.segPesttrapData = [];
          data.locationsKey[location.id] = location;
        }
      })
    ]).then(() => {
      for(let location of data.locations){
        location.segPesttrapData = data.segPesttrapData.filter(el => el.ext_cus_location_id == location.id).reverse();
        location.headerLabel = `[${location.customerName}] ${location.name}`;
      }
      data.locations = data.locations
        .filter(report.filter)
        .filter(el => el.segPesttrapData?.length > 0)
        .sort((a, b) => a.headerLabel?.toLowerCase() > b.headerLabel?.toLowerCase() ? 1 : a.headerLabel?.toLowerCase() < b.headerLabel?.toLowerCase() ? -1 : 0);
      return report.data = data;
    });
  }

  loadDeploymentOverview(report): Promise<any> {
    let data: any = {},
      locationsKey = {},
      usersKey = {};
    return Promise.all([
      this.httpService.getSegPesttrapData(dpOvBeginDate).toPromise()
        .then(res => data.segPesttrapData = res),
      this.httpService.getBusinessUsers().toPromise().then(res => {
        data.users = res;
        for(let user of data.users){
          if(user.first_name && user.last_name)
            user.fullName = `${user.first_name} ${user.last_name}`;
          else
            user.fullName = '[User not found]';
          usersKey[user.id] = user;
        }
      }),
      this.httpService.getSubaccountLocations().toPromise().then(res => {
        for(let customer of res){
          if(customer.locations)
            for(let location of customer.locations)
              location.customerName = customer.name;
        }
        data.locations = res.flatMap(el => el.locations || []);
        for(let location of data.locations){
          location.total_alerts = 0;
          location.segPesttrapData = [];
          locationsKey[location.id] = location;
        }
      })
    ]).then(() => {
      for(let location of data.locations){
        let techniciansObj = {};

        location.segPesttrapData = data.segPesttrapData.filter(el => el.ext_cus_location_id == location.id);
        location.initial_deploy_time = data.segPesttrapData[0]?.deploy_time;
        if(!data.segPesttrapData.find(el => el.pickup_time == null)){
          let pickupSort = data.segPesttrapData.sort((a, b) => 
            a.pickup_time > b.pickup_time ? 1 : a.pickup_time < b.pickup_time ? -1 : 0
          );
          location.final_pickup_time = pickupSort[pickupSort.length - 1];
        }

        for(let i = 0; i < location.segPesttrapData.length; i++){
          if(location.segPesttrapData[i].total_alerts)
            location.total_alerts += location.segPesttrapData[i].total_alerts;
          if(!techniciansObj[location.segPesttrapData[i].user_id])
            techniciansObj[location.segPesttrapData[i].user_id] = usersKey[location.segPesttrapData[i].user_id]?.fullName || '[User not found]';
        }
        location.technicians = Object.values(techniciansObj).sort();
      }

      data.locations = data.locations?.sort((a, b) => 
        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : 
        a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 0
      );
      
      return data;
    });
  }

  loadDevices(report){
    let data = [],
    devices = [],
    sensors = [],
    sptd = [],
    units = [];
    let evFilter;
  
    if(report.filterProps?.endDate || report.initialFilterProps?.endDate)
      evFilter = {
        where: {
          created: {
              between: [
                (report.filterProps?.beginDate || report.initialFilterProps?.beginDate || beginDate).getTime(), 
                (report.filterProps?.endDate || report.initialFilterProps?.endDate).getTime()
              ]
          }
        }
      };
    else 
      evFilter = {
        where: {
          created: {
            gt: (report.filterProps?.beginDate || report.initialFilterProps?.beginDate || beginDate).getTime()
          }
        }
      };
    return Promise.all([
      this.httpService.getBusinessDevices({segPestTrapData: true, businessSubscription: true, locationTracking: true}, evFilter).toPromise().then(res => devices = res),
      this.httpService.getBusinessHubSensors({segPestTrapData: true, businessSubscription: true }, evFilter).toPromise().then(res => sensors = res),
      this.httpService.getSegPesttrapData(null, null, true).toPromise().then(res => sptd = res)
    ]).then(() => {
      this.activeSegPesttrapData = sptd;
      units = [...devices, ...sensors];
      units.sort((a, b) => {
        const nameA = a.devicename?.toUpperCase();
        const nameB = b.devicename?.toUpperCase();
        return nameA < nameB ? -1 : +(nameA > nameB) || 0;
      });
      let events = [];
      units.forEach(unit => {
        unit.key = unit.sensorHistories ? 'sensorHistories' : 'deviceEvents';
        if(unit[unit.key])
          if(unit[unit.key].length > 0){
            unit.name = unit.devicename;
            unit.events = unit[unit.key].filter(el => !/.* just checked-in.*/.test(el.body));
            unit.events.forEach(event => {
              event.date = new Date(event.created);
              event.deviceName = unit.devicename;
              event.deviceSerial = (unit.sensor_serial || unit.device_serial);
              event.deviceId = unit.id;
              event.createdLabel = prettyPrintDate(event.created, {time: true});
              event.technician = unit.user_id;
            });
            events.push(...unit.events);
            this.devices.push(unit);
          }
      });
      data = events;
      return data;
    });
  }
  
  async deploymentDetailsContent(report){
    report.data = await this.loadDeploymentDetails(report);
    let headerRow = (cells) => cells.map(el => ({
      text: el,
      bold: true,
      border: [false, false, false, true]
    }));
    
    return report.data.locations.map(el => {
      return [{
        widths: ['*', '*'],
        columns: [{
          layout: {
            ...layouts.reallyLightBorders,
            ...layouts.thinBorders
          },
          table: {
            headerRows: 1,
            widths: ['auto', '*'],
            body: [
              [buildTextCell(` `, {fontSize: 11, colSpan: 2}), {}],
              [buildTextCell(el.headerLabel, {
                fontSize: 11,
                colSpan: 2,
                borderBottom: true
              }), {}],
            ]
          }
        }, '']
      }, 
      ' ',
      {
        layout: {
          hLineWidth: () => 2
        },
        table: {
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', '*'],
          body: [headerRow(['Deploy date', 'Serial', 'Device', 'Tech ', 'Alerts', 'Result']), ...el.segPesttrapData.map((j, i, arr) => {
            let options = {
              fillColor: (i % 2) ? '#CCC' : undefined, 
              borders: [false, false, false, false]
            };
            return [
              {
                text: j.deploy_time ? prettyPrintDate(j.deploy_time, {time: true}) : '[not found]', 
                border: options.borders, 
                fillColor: options.fillColor
              },
              {
                text: j.device_serial || '[not found]', 
                border: options.borders, 
                fillColor: options.fillColor
              },
              {
                text: j.deploy_name || '[not found]', 
                border: options.borders, 
                fillColor: options.fillColor
              },
              {
                text: report.data.usersKey[j.user_id]?.fullName || '[User not found]', 
                border: options.borders, 
                fillColor: options.fillColor
              },
              {
                text: j.total_alerts || '0', 
                border: options.borders, 
                fillColor: options.fillColor
              },
              {
                text: j.pickup_time ? 
                `Pickup: ${prettyPrintDate(j.pickup_time, {time: true})}${j.pickup_by ? (' by ' + j.pickup_by) : ''}
                Result: ${j.result_type ? j.result_type : 'Unspecified'}
                Notes: ${j.pickup_notes || 'N/A'}` : 'N/A (Deployment active)',
                border: options.borders, 
                fillColor: options.fillColor
              },
            ];
          })]
        }
      }
    ];
    }); 
  }

  async deploymentOverviewContent(report) {
    report.data = await this.loadDeploymentOverview(report);
    return report.data.locations
      .filter(el => el.segPesttrapData?.length > 0)
      .flatMap(el => [{
        widths: ['*', '*'],
        columns: [{
          layout: {
            ...layouts.reallyLightBorders,
            ...layouts.thinBorders
          },
          table: {
            headerRows: 1,
            widths: ['auto', '*'],
            body: [
              [buildTextCell(el.name, {
                fontSize: 11,
                colSpan: 2,
                borderBottom: true
              }), {}],
              [buildTextCell('Customer'), buildTextCell(el.customerName || 'N/A')],
              [buildTextCell('Technician(s)'), buildTextCell(el.technicians.length > 1 ? 
                "Multiple" : el.technicians.length ? 
                  el.technicians[0] : "N/A"
              )],
              [buildTextCell('Initial deployment date'), buildTextCell(el.initial_deploy_time ?
                prettyPrintDate(el.initial_deploy_time, {time: true}) : 'N/A'
              )],
              [buildTextCell('Final pickup time'), buildTextCell(el.final_pickup_time ?
                prettyPrintDate(el.final_pickup_time, {time: true}) : 'N/A'
              )],
              [buildTextCell('Total alerts (last 7 days)  '), buildTextCell(el.total_alerts || '0')]
            ]
          }
        }, '']
      }, ' ']);
  }

  async deviceAlertsContent(report){
    report.data = await this.loadDevices(report);
    let filteredData = report.data.filter(report.filter);
    let headerRow = (cells) => cells.map(el => ({
      text: el,
      bold: true,
      border: [false, false, false, true]
    })), rows = [],
    j = 0;
    for(let i = 0; i < filteredData.length; i++){
      let options = {
        fillColor: (i % 2) ? '#CCC' : undefined, 
        borders: (filteredData.length > (i + 1) && filteredData[i + 1].deviceSerial != filteredData[i].deviceSerial) ? [false, false, false, true] : [false, false, false, false]
      };
      rows.push([
        {text: filteredData[i].deviceSerial, border: options.borders, fillColor: options.fillColor}, 
        {text: filteredData[i].deviceName, border: options.borders, fillColor: options.fillColor}, 
        {text: filteredData[i].createdLabel, border: options.borders, fillColor: options.fillColor},  
        {text: filteredData[i].body.replace(/^\n/, ''), border: options.borders, fillColor: options.fillColor}, 
      ]);
    }
    return [{
        layout: {
          hLineWidth: () => 2
        },
        table: {
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', '*'],
          body: [headerRow(['Device', 'Name', 'Time', 'Alert']), ...rows]
        }
    }];
  }

  async deviceAlertsCsv(report){
    let csvStr = 'Device,Name,Time,Alert', 
        filteredData = await report.data.filter(report.filter);
    for(let i of filteredData){
      let serial = i.deviceSerial.replaceAll(csvSafetyRegex, ''),
      deviceName = i.deviceName.replaceAll(csvSafetyRegex, ''),
      createdLabel = i.createdLabel.replaceAll(csvSafetyRegex, ''),
      body = i.body.replaceAll(csvSafetyRegex, '');
      csvStr +=  `\n${serial}, ${deviceName}, ${createdLabel}, ${body}`;
    }
    return csvStr;
  }

  async deploymentOverviewCsv(report){
    let csvStr = 'Location,Customer,Technician(s),Initial deployment date,Final pickup time,Total alerts (Last 7 days)', 
        filteredData = report.data.locations.filter(el => el.segPesttrapData?.length > 0);
    for(let i of filteredData){
      let customer = i.customerName.replaceAll(csvSafetyRegex, ''),
      name = i.name.replaceAll(csvSafetyRegex, ''),
      technicians = (i.technicians?.length > 1 ? "Multiple" : ((i.technicians && i.technicians[0]) || "[name not found]")).replaceAll(csvSafetyRegex, ''),
      deployTime = (i.initial_deploy_time ? prettyPrintDate(i.initial_deploy_time, {time: true}) : 'N/A').replaceAll(csvSafetyRegex, ''),
      pickupTime = (i.final_pickup_time ? prettyPrintDate(i.final_pickup_time, {time: true}) : 'N/A').replaceAll(csvSafetyRegex, ''),
      totalAlerts = `${i.total_alerts ?? 'N/A'}`.replaceAll(csvSafetyRegex, '');
      csvStr +=  `\n${name}, ${customer}, ${technicians}, ${deployTime}, ${pickupTime}, ${totalAlerts}\n`;
    }
    return csvStr;
  }

  async deploymentDetailsCsv(report){
    let csvStr = 'Customer,Location,Deploy date,Serial,Device,Tech,Alerts,Result', 
        filteredData = report.data.locations;
    for(let i of filteredData){
      let customer = i.customerName.replaceAll(csvSafetyRegex, ''),
          name = i.name.replaceAll(csvSafetyRegex, '');
      
      for(let j of i.segPesttrapData){
        let deployTime = (j.deploy_time ? prettyPrintDate(j.deploy_time, {time: true}) : 'N/A').replaceAll(csvSafetyRegex, ''),
            deviceSerial = j.device_serial.replaceAll(csvSafetyRegex, ''),
            deviceName = j.deploy_name.replaceAll(csvSafetyRegex, ''),
            tech = (report.data.usersKey[j.user_id]?.fullName || '[User not found]').replaceAll(csvSafetyRegex, ''),
            alerts = `${j.total_alerts ?? 0}`.replaceAll(csvSafetyRegex, ''),
            result = j.pickup_time ? 
              `Pickup: ${prettyPrintDate(j.pickup_time, {time: true})}${j.pickup_by ? (' by ' + j.pickup_by) : ''} // Result: ${j.result_type ? j.result_type : 'Unspecified'} // Notes: ${j.pickup_notes || 'N/A'}` : 
              'N/A (Deployment active)';
        csvStr += `\n${customer}, ${name}, ${deployTime}, ${deviceSerial}, ${deviceName}, ${tech}, ${alerts}, ${result}\n`;
      }
    }
    return csvStr;
  }

  async exportCsv(report){
    let csvStr = await this[report.generateCsv](report);
    let uri = encodeURI(`data:text/csv;charset=utf-8,${csvStr}`);
    let a = document.createElement("a");
    a.href = uri;
    a.download = `Skyhawk ${report.textLabel} ${prettyPrintDate({fs: true})}`;
    a.hidden = true;
    document.body.appendChild(a);
    a.innerHTML = "(ꈍᴗꈍ✿)";
    a.click();
    document.body.removeChild(a);
  }

  async systemVerificationReportContent(report) {
    report.data = await this.loadVerificationData(report);
    let headerRow = (cells) => cells.map(el => ({
      text: el,
      bold: true,
      border: [false, false, false, true],
    }));

    return report.data.map(el => {
      return [{
        layout: { hLineWidth: () => 2 },
        table: {
          headerRows: 1,
          widths: ['auto', 'auto', 'auto', 'auto'],
          body: [
            headerRow(['Event', 'Status', 'Timestamp', 'Technician']),
            ...el.events.map(event => [
              { text: event.type, border: [false, false, false, false] },
              { text: event.status, border: [false, false, false, false] },
              { text: prettyPrintDate(event.timestamp, { time: true }), border: [false, false, false, false] },
              { text: event.technician || '[Technician Not Found]', border: [false, false, false, false] },
            ]),
          ],
        },
      }];
    });
  }

  async loadVerificationData(report): Promise<any> {
    const from = report.filterProps?.beginDate || beginDate;
    from.setHours(0, 0, 0, 0);

    let n = new Date();
    let to = report.filterProps?.endDate || n;

    if (to.toDateString() !== n.toDateString() && to < n) to.setHours(23, 59, 59, 999);
    else to = n;

    const customerId = report.filterProps?.customerId || '';
    const locationId = report.filterProps?.locationId || '';

    return this.httpService.generateVerificationReport(from.getTime(), to.getTime(), customerId, locationId)
      .toPromise()
      .then(response => {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const fileUrl = window.URL.createObjectURL(blob);
        if (!report.iframe) {
          report.iframe = this.injectIframe(report);
        }
        report.iframe.src = fileUrl;
      })
      .catch(error => {
        console.error('Error generating verification report:', error);
        this.modalService.errorDialog(error);
      });
  }
}