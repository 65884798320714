<div id="error-500" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="error-code">500</div>

    <div class="message">Well, you broke the internet!</div>

    <div class="sub-message">
      Just kidding, looks like we have an internal issue. Please try again in a few minutes. 
    </div>

    <div>Persistent issue? Reach out to <a href="https://support.sky-hawk.com/">support.</a></div>
  </div>
</div>
