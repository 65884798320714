import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { PickupSensorModalModule } from 'app/main/modals/pickup-sensor-modal/pickup-sensor-modal.module';
import { CustomerLocationDetailsComponent } from './customer-location-details.component';
import { GalleryModule } from '../../general/gallery/gallery.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AssignTechnicianDialogComponent } from '../../../modals/assign-technician-modal/assign-technician-dialog.component';
import { HeatMapModalModule } from 'app/main/modals/heat-map-modal/heat-map-modal.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const routes: Routes = [
  {
    path: 'customers/:customerId/locations/:locationId/details',
    component: CustomerLocationDetailsComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [CustomerLocationDetailsComponent,
    AssignTechnicianDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatCardModule,
    MatCheckboxModule,
    GoogleMapsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MdePopoverModule,
    PickupSensorModalModule,
    GalleryModule,
    MatTabsModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    PerfectScrollbarModule,
    MatSnackBarModule,
    HeatMapModalModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule
  ],
  exports: [CustomerLocationDetailsComponent],
})
export class CustomerLocationDetailsModule {
}
