<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.ADD_LOCATION' | translate }}</h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
    <form [formGroup]="locationForm" fxLayout="column">
      <mat-form-field appearance="standard">
        <mat-label>Location Name</mat-label>
        <input matInput type="text" formControlName="name" required />
        <mat-icon matSuffix>location_on</mat-icon>
        <mat-error *ngIf="locationForm.get('name').invalid">
          Location Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>External Location Id</mat-label>
        <input matInput type="text" formControlName="extLocCustId" />
        <mat-icon matSuffix>link</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Address 1</mat-label>
        <input matInput type="text" formControlName="address1" required />
        <mat-error *ngIf="locationForm.get('address1').invalid">
          Address 1 is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Address 2</mat-label>
        <input matInput type="text" formControlName="address2" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>City</mat-label>
        <input matInput type="text" formControlName="city" required />
        <mat-icon matSuffix>location_city</mat-icon>
        <mat-error *ngIf="locationForm.get('city').invalid">
          City is required
        </mat-error>
      </mat-form-field>
      <span fxLayout fxLayoutAlign="space-between">
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" required>
            <mat-option *ngFor="let state of states" [value]="state.short">
              {{ state.long }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="locationForm.get('state').invalid">
            State is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="zip" required />
          <mat-error *ngIf="locationForm.get('zip').invalid">
            A valid Zip Code is required
          </mat-error>
        </mat-form-field>
      </span>
      <span fxLayout fxLayoutAlign="space-between">
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>Contact First Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="contactFirstName"
            required
          />
          <mat-error *ngIf="locationForm.get('contactFirstName').invalid">
            Contact First Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>Contact Last Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="contactLastName"
            required
          />
          <mat-error *ngIf="locationForm.get('contactLastName').invalid">
            Contact Last Name is required
          </mat-error>
        </mat-form-field>
      </span>
      <mat-form-field appearance="standard">
        <mat-label>Contact Email</mat-label>
        <input matInput type="email" formControlName="contactEmail" />
        <mat-icon matSuffix>email</mat-icon>
        <mat-error
          *ngIf="locationForm.get('contactEmail').hasError('required')"
        >
          Contact Email is required
        </mat-error>
        <mat-error
          *ngIf="locationForm.get('contactEmail').hasError('emailInvalid')"
        >
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Contact Phone</mat-label>
        <input
          matInput
          type="tel"
          formControlName="contactPhone"
          mask="(000) 000-0000"
        />
        <mat-icon matSuffix>phone</mat-icon>
        <mat-error *ngIf="locationForm.get('contactPhone').errors">
          Please enter a valid phone number
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button
      mat-button
      (click)="sendCustomer()"
      [disabled]="!locationForm.valid"
    >
      {{ 'MODAL.ACTIONS.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
