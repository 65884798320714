import { Component } from '@angular/core';
import { AuthService } from 'app/services';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  streamline: boolean;
  constructor(
    private auth: AuthService
  ) {
    this.auth.streamline.subscribe(res => this.streamline = res);
  }
}
