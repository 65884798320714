import { Component, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { catchError, map, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { prettyPrintPhone } from 'assets/formatting';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnDestroy {
  query: string = "";
  @Output() queryReturn = new EventEmitter<any>();
  embedded = !window.location.href.includes("/users");
  users: any[];
  loading: boolean;
  businessName: string;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  selection = new SelectionModel<object>(true, []);
  displayedColumns: string[] = ['select', 'emailLabel', 'fullName', 'prettyPhone', 'businessRole', 'actions'];
  queryInput = new FormControl('');
  private _unsubscribeAll: Subject<any>;

  get filteredSelection(): any[] {
    return this.selection.selected.filter(el => 
      this.dataSource.filteredData.includes(el)
    );
  }
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private modalService: ModalService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.authService.currentBusiness.pipe(
      takeUntil(this._unsubscribeAll),
      skipWhile((bus) => bus == null),
    ).subscribe((business) => {
      this.businessName = business.name;
    });
    this.route.queryParams.subscribe(params => {
      this.query = params.query || "";
      this.updateQuery();
    });
    this.fetchRows();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchRows(reload?: any): void {
    if(reload === undefined)
      this.loading = true;
    this.selection.clear();
    this.httpService
      .getBusinessUsers()
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog),
        map((users: any[]) => {
          users.forEach(user => {         
            user.businessRole = user.roles.find(el => el.name == 'busadm') ? 'Admin' : user.roles.find(el => el.name == 'busfin') ? 'Billing Admin' : 'User';
            user.prettyPhone = prettyPrintPhone(user.phone);
            user.emailLabel = `${user.email.toLowerCase()}${user.pendingBusinessId ? ' (Pending)' : ''}`;
            user.fullName =  `${user.first_name} ${user.last_name}`;
          });
          return users;
        }),
        map((users: any[]) =>
          // Sort users alphabetically
          users.sort((a, b) => {
            const _a = a.email.toUpperCase(), _b = b.email.toUpperCase();
            return _a < _b ? -1 : _a > _b ? 1 : 0;
          }),
        ),
      )
      .subscribe((users) => {
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
          typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
        this.dataSource.filterPredicate = (el, val) => {
          for(let i of ['email', 'first_name', 'last_name', 'phone'])
            if(el[i] && `${el[i]}`.toLowerCase().indexOf(val?.toLowerCase()) !== -1)
              return true;
          return false;
        };
        this.dataSource.sort = this.sort;
        this.applyFilter();

        if(this.embedded)
          this.updateQuery();
        else
          this.queryInput.valueChanges.subscribe(() => this.applyFilter());

        this.loading = false;
      });
  }

  applyFilter(){
    this.dataSource.filter = this.queryInput.value;
  }

  //this is just for the global search functionality
  updateQuery(){ 
    if(!this.dataSource)
      return;
    this.dataSource.filter = this.query;
    this.queryReturn.next({component: 'Users', data: this.dataSource.filteredData?.length});
  }

  showAddUsersModal(): void {
    this.modalService
      .showAddUsersModal()
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        let users = [...data.existingUsers, ...data.newUsers];
        if (users.length == 0)
          return;

        this.httpService.addBusinessUsers(users).pipe(
          takeUntil(this._unsubscribeAll),
          catchError(this.modalService.errorDialog)
        ).subscribe((res) => {
            const errors = [...this.getErrors(res)];
            if (errors.length) {
              // List error messages
              this.modalService.showModal(
                errors.length === users.length
                  ? 'Tasks Failed'
                  : 'Completed With Errors',
                errors,
              );
            } else 
              this.snackbar.open('Users successfully added', 'OK', {duration: 2000});
            this.fetchRows(0);
          });
      });
  }

  showRemoveUsersModal(users?: object[]): void {
    if(!users)
      users = this.filteredSelection;
    this.modalService
      .showConfirmModal(
        `The following users will be removed:\n
        ${users.map((el: any) => '  • ' + el.fullName + ' (' + el.emailLabel + ')').sort().join('\n')}\n
        Do you want to continue?`,
      )
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((confirm) => {
        if (confirm) {
          const userIds = _.map(users, 'id') as string[];
          this.removeUsers(userIds);
        }
      });
  }

  private removeUsers(userIds: string[]): void {
    this.httpService
      .removeBusinessUsers(userIds)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
      )
      .subscribe((res) => {
        const errors = this.getErrors(res);

        if (errors.length) {
          // Some user operations failed
          this.modalService.showModal(
            errors.length === userIds.length
              ? 'Failed With Errors'
              : 'Completed With Errors',
            errors,
          );
        } else         
          this.snackbar.open('Users successfully removed', 'OK', {duration: 2000});
        this.selection = new SelectionModel<object>(true, []);
        this.fetchRows(0);
      });
  }

  showEditUserModal(user: any): void {
    this.modalService.showEditUserModal(user).afterClosed()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(isUserUpdated => !isUserUpdated || this.fetchRows(0));
  }

  showResetPasswordModal(email: string): void {
    this.modalService
      .showConfirmModal(
        `Are you sure you want to reset the password for ${email}?`,
      )
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((confirm) => {
        if (confirm) {
          this.resetPassword(email);
        }
      });
  }

  private resetPassword(email: string): void {
    if (!email) {
      return;
    }

    this.authService
      .resetPassword({ email: email })
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
      ).subscribe(() => {
        this.snackbar.open('Reset password successfully sent to user\'s email', 'OK', {duration: 5000});
        this.fetchRows(0);
      });
  }

  private getErrors(response: any[]): any[] {
    const errors = response.filter((item) => item.error);

    // Some user operations failed
    errors.forEach((error) => {
      let email: string;
      if (error.userId)
        email = this.dataSource.data.find(user => user.id === error.userId).email;
      else if (error.email)
        email = error.email;
      else
        email = 'Error';
      error.message = `${email}: ${error.message}`;
    });

    return errors;
  }

  sendConfirmationEmail(email: string): void {
    this.authService //i don't totally love that i'm calling this from auth service but it's fine
      .sendConfirmationEmail(email)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
      ).subscribe(() => {
        this.snackbar.open('Business confirmation email sent', 'OK', {duration: 2000})
      });
  }
}
