<h2 mat-dialog-title>Select Technicians</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput [formControl]="searchControl" placeholder="Search technicians">
    <button mat-icon-button matSuffix (click)="searchControl.setValue('')">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>

  <mat-selection-list [(ngModel)]="selectedTechnicians" multiple>
    <mat-list-option *ngFor="let technician of filteredTechnicians" [value]="technician.id">
      {{ technician.name }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button color="primary" (click)="onAssignClick()">Assign</button>
</mat-dialog-actions>


