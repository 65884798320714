<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.DEVICE_LOG' | translate }}</h2>
  <h4 class="subheader">30 Days of Device History</h4>
  <h4 class="subheader">SN#: {{data.deviceSerial}}</h4>
  <mat-dialog-content>
    <perfect-scrollbar>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort devicelog>
     
        <ng-container matColumnDef="dateFormatted">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.dateFormatted }} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="body">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Body </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.body }} </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </perfect-scrollbar>
    <ng-container *ngIf="dataSource.data.length == 0">
      <span style="margin:23px;">No events to show.</span>
    </ng-container>
  </mat-dialog-content>
</div>
