import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PickupSensorModalComponent } from './pickup-sensor-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [PickupSensorModalComponent],
  imports: [
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  exports: [PickupSensorModalComponent],
})
export class PickupSensorModalModule {}
