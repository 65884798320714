<div class="page-layout blank">
    <app-device-list></app-device-list>
    <!--div fxLayout="row" fxLayoutAlign="space-between center" class="m-0">
        <div></div>
        <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle value="0" aria-label="map">
              <mat-icon>map</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="1" aria-label="list">
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div -->
    <!-- div class="row main-content">
        <mat-tab-group>
            <mat-tab label="List">
                <app-device-list></app-device-list>
            </mat-tab>
            <mat-tab label="Locations">
                <app-device-map></app-device-map>
            </mat-tab>
        </mat-tab-group>
    </div -->
</div>