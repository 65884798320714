import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuard } from 'app/guards';
import { Error404Component } from './error-404.component';

const routes: Routes = [
  {
    path: 'error-404',
    component: Error404Component,
    data: { title: 'Page Not Found' },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [Error404Component],
  imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule],
})
export class Error404Module {}
