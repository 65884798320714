import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currYear: number;
  version: string;

  constructor() {}

  ngOnInit(): void {
    this.currYear = new Date().getFullYear();
    this.version = environment.appVersion;
  }
}
