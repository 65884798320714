import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BusinessAccount, HttpService } from 'app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { emailValidator } from 'validators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-add-customer-modal',
  templateUrl: './add-customer-modal.component.html',
  styleUrls: ['./add-customer-modal.component.scss'],
})
export class AddCustomerModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  customerForm: FormGroup;
  businessTypes: any[];

  private _unsubscribeAll: Subject<any>;

  constructor(
    private httpService: HttpService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<AddCustomerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);

    this._fuseTranslationLoaderService.loadTranslations(english);

    this.customerForm = new FormGroup({
      businessName: new FormControl('', [Validators.required]),
      businessType: new FormControl('', [Validators.required]),
      extCustId: new FormControl(''),
      contactFirstName: new FormControl('', [Validators.required]),
      contactLastName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [emailValidator()]),
      contactPhone: new FormControl(''),
    });

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Get current list of business types
    this.httpService
      .getBusinessTypes()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((types) => {
        this.businessTypes = types;
      });

    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  sendCustomer(): void {
    const newCustomer: BusinessAccount = {
      name: this.customerForm.get('businessName').value,
      segment_id: this.customerForm.get('businessType').value,
      ext_cust_id: this.customerForm.get('extCustId').value,
      contact_first_name: this.customerForm.get('contactFirstName').value,
      contact_last_name: this.customerForm.get('contactLastName').value,
      contact_email: this.customerForm.get('contactEmail').value,
      contact_phone: this.customerForm
        .get('contactPhone')
        .value.replace(/-/g, ''),
    };

    this.dialogRef.close(newCustomer);
  }
}
