import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { Subject, throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { AuthService } from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
const tile = (title, icon, data) => ({title: title, icon: `fa ${icon}`, data: data});

@Component({
    selector: 'app-device-details-modal',
    templateUrl: './device-details-modal.component.html',
    styleUrls: ['./device-details-modal.component.scss'],
})
export class DeviceDetailsModalComponent
extends BaseModal
implements OnDestroy {
    device: any;
    tiles: any;
    init: any = {};
    cur: any = {};
    phoneInput: FormControl;
    phoneChips: FormControl;
    emailInput: FormControl;
    emailChips: FormControl;
    deviceNotes: FormControl;
    deviceName: FormControl;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    phoneRegex = /\(\d\d\d\) \d\d\d-\d\d\d\d/;
    phoneSort = (a, b) => (f => f(a) > f(b))(j => j.match(/\d*/)[0]);
    emailSort = (a, b) => a > b ? 1 : a < b ? -1 : 0;
    private _unsubscribeAll: Subject < any > ;
    private adm = this.authService.isAdmin();
    constructor(
        private datePipe: DatePipe,
        private httpService: HttpService,
        private modalService: ModalService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private snackbar: MatSnackBar,
        protected dialogRef: MatDialogRef < DeviceDetailsModalComponent > ,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super(dialogRef, data);       
        this._fuseTranslationLoaderService.loadTranslations(english);
        this.device = data.device;
        this.defineValue('phone', this.phoneToArr(this.device.phone));
        this.defineValue('email', this.emailToArr(this.device.email));
        this.defineValue('notes', this.device.notes);
        this.defineValue('name', this.device.name);
        this.phoneInput = new FormControl();
        this.phoneChips = new FormControl();
        this.emailInput = new FormControl();
        this.emailChips = new FormControl();
        this.deviceNotes = new FormControl(this.device.notes);
        this.deviceName = new FormControl(this.device.name, [Validators.required, Validators.maxLength(50)]);

        if(!this.adm)
            this.phoneInput.disable(), 
            this.phoneChips.disable(),
            this.emailInput.disable(),
            this.emailChips.disable(), 
            this.removable = false, 
            this.selectable = false;


        this._unsubscribeAll = new Subject();

        this.tiles = [
            tile('Heartbeat', 'fa-heart', function(){let hb = this.device.heartbeat, 
                connection = this.device.lastConnection, 
                power = this.device.power;
            
            if(!hb || hb === '000' || !power)
              return 'Power off';
        
            if(!connection)
              return 'Awaiting first heartbeat';
        
            let hbTime = (Number(hb) * 3600000);
            
            if (this.device.deviceType == 'sensor'){
              hbTime = ((32400000 + (hbTime * 1.1)) + connection);   
            } else {
              if (this.device.serial.substring(0, 3) === 'SHH') {
                hbTime = ((3600000 + (hbTime * 1.1)) + connection);
              } else {
                hbTime = ((hbTime + (hbTime * 1.05)) + connection);
              }
            }
        
            if(hbTime < Date.now())
              return 'Missed heartbeat';
        
            return 'Good heartbeat';
            }.bind(this)()),
            tile('Assigned user', 'fa-user', this.device.user_full_name),
            tile('Pause timer', 'fal fa-pause-circle', ((j, k) => j > k ? (j - k) : 0)(this.device.disarmTime, Date.now())),
            ...(this.device.deviceType == 'device' ? [
                tile('Alert limiter', 'fa-bell-on', (j => `${j} minute${j ? '' : 's'}`)(this.device[`${this.device.bsm_dc ? 'bsm' : 'st'}_delay`])),
            ] : []),
            ...(/SHH.*/.test(this.device.serial) ? [
                tile('Sensitivity', 'fa-sliders-h', this.device.issensitivityon ? this.device.sensor_adjust : 'Disabled')
            ] : []),
            ...(this.device.deviceType == 'sensor' ? [
                tile('Sensor RSSI', 'fas fa-signal-stream', this.device.sensr_rssi),
                tile('Sensor setting', 'fa-sliders-h', this.device.sensr_setting)
            ] : []),
            ...(['Kiwi', 'Mouse', 'Trap', 'KiwiIR'].includes(this.device.type) ? [
                tile('Reed state', 'fa-link', ['Disconnected', 'Connected', 'Events only'][this.device.reedStatus])
            ] : []),
            tile('Last active', 'fa-running', (this.device.lastActive ? this.datePipe.transform(this.device.lastActive, 'EE M/d, h:mm a') : 'N/A')),           
        ];
    }

    defineValue(key, val){
        switch(typeof val){
            case 'object':
                this.init[key] = Array.isArray(val) ? [...val] : {...val};
                this.cur[key] = val;
                break;
            default: 
                this.init[key] = `${val}`;
                this.cur[key] = val;
        }
        return val;
    }

    phoneToArr(phoneStr): Array<string> {
        return !phoneStr || phoneStr == '' ? [] :
            phoneStr.split(',').sort(this.phoneSort).map(el => el.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3'));
    }

    emailToArr(emailStr): Array<string> {
        return !emailStr || emailStr == '' ? [] :
            emailStr.split(',').sort(this.emailSort);
    }

    phoneToStr(phoneArr): string {
        return phoneArr?.join(',').replace(/( |\(|\)|-)/g, '') || '';
    }

    emailToStr(emailArr): string {
        return emailArr?.join(',') || ''
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onResize(event: Event): void {
        //this.updateDialogPos();
    }

    add(e: MatChipInputEvent, key: any): void {
        if(!this[`${key}Regex`].test(e.value))
            return this[`${key}Input`].setErrors({[`${key}Invalid`]: true});
            
        this.cur[key].push(e.value);
        this.cur[key].sort(this[`${key}Sort`]);
        e.chipInput!.clear();
      }

    remove(j: any, arr: any): void {
        (i => i >= 0 && arr.splice(i, 1))(arr.indexOf(j));
    }

    saveDisabled(){
        if(this.deviceName.hasError('required') || this.deviceName.hasError('maxlength'))
            return true;
        /* 
        //this is really resource intensive
        if(JSON.stringify(this.init.phone) !== JSON.stringify(this.cur.phone) || JSON.stringify(this.init.email) !== JSON.stringify(this.cur.email))
            if(!this.adm)  
                return true;
        */      
        for(let i of Object.keys(this.init)){
            let vals = [this.init[i], this.cur[i]];
            if(typeof this.init[i] == 'object')
                vals = vals.map(j => JSON.stringify(j));
            if(vals[0] !== vals[1])
                return false;
        }
    
        return true;
    }

    save(): void {
        let newData = {
            phone: this.phoneToStr(this.cur.phone),
            email: this.emailToStr(this.cur.email),
            name: this.cur.name,
            notes: this.cur.notes || "",
        };
        this.saveDisabled() || this.httpService.patchAmbiguousDevice(this.device, newData).pipe(
            takeUntil(this._unsubscribeAll),
            catchError((err) => {
              this.modalService.showModal(
                `Error`,
                `Failed to save changes to device ${this.device.id}`
              );
              return throwError(err);
            }),
          ).subscribe(() => {
            this.snackbar.open('Device updated successfully', 'OK', {duration: 2000});
            this.device = {...this.device, ...newData};
            this.dialogRef.close(this.device);
          });
    }

    getCanvasURL(): string{
        let url = document.querySelector('canvas').toDataURL();
        /*
        console.log('url:', url);
        let el = document.createElement('a');
        el.href = url;
        el.target = "_blank";
        el.style.display = 'none';
        document.body.appendChild(el);
        el.click(); 
        document.body.removeChild(el);
        */
        return url;
    }

    printQR() {
        let win = window.open('');
        win.document.write('<img src="' + this.getCanvasURL() + '" onload="window.print();window.close()" />');
        win.focus();
    }
}