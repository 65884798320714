<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <loading-component [complete]="!loading" [style]="embedded ? 'table-inline' : null"></loading-component>
  <div *ngIf="!embedded" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <button mat-mini-fab color="primary" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h3 class="m-0">{{ device.name + ' (' + (device.serial) + ')' }}</h3>
  </div>
  <div fxLayout="row wrap">
    <!-- seriously need to clean this up sometime. it's becoming a huge mess -->
    <fuse-widget
      fxLayout="column"
      fxFlex="100"
      fxFlex.gt-xs="25"
      fxFlex.gt-md="20"
      *ngFor="let tile of deviceTiles"
    >
      <div class="fuse-widget-front mat-elevation-z2" *ngIf="tile.title !== 'Image'">
        <div
          class="pl-12 pr-5 py-12 h-25"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="h3">
            {{ tile.title }}
          </div>
          <ng-container *ngIf="tile.editable">
            <button mat-icon-button (click)="toggleEditing(tile)" *ngIf="!tile.editing" class="edit-btn">
              <mat-icon class="edit-icon" fontSet="fa" fontIcon="fa-edit"></mat-icon>
            </button>
            <button mat-icon-button (click)="toggleEditing(tile)" *ngIf="tile.editing" class="edit-btn">
              <mat-icon class="edit-icon" fontSet="fa" fontIcon="fa-check"></mat-icon>
            </button>
          </ng-container>
        </div>
        <!--<div class="tile-body-wrapper-fade top"></div>-->
        <div
          [class]="'count-wrapper pt-6 tile-body-wrapper ' + (tile.editing ? 'editing' : '')"
          fxLayout="column"
          fxLayoutAlign="center center"
          fusePerfectScrollbar
              [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
        >
          <ng-container *ngIf="!tile.editing">
            <ng-container *ngIf="tile.icon">
                <div class="icon-badge-container">
                  <icon class="{{'fa ' + tile.icon}} icon-badge-icon device-tile-icon"></icon>
                  <div *ngIf="tile.badge" class="icon-badge">{{tile.badge}}</div>
                </div>
            </ng-container>
          </ng-container>
          <div *ngIf="tile.data !== 'View 30 Day Device Log' && tile.data !== imageUrl && tile.data !== 'View Deployment History'" 
          class="accent-fg tile-content" 
          [ngStyle]="{'font-size': calculateFontsize(tile.data.length), 'line-height': 1.5}"
          >
            <ng-container *ngIf="!tile.editing">
              {{ tile.data }}
            </ng-container>
            <ng-container *ngIf="tile.editing">
              <textarea matInput [value]="tile.data" [id]="tile.key"></textarea>
            </ng-container>
          </div>
          <div *ngIf="tile.data == 'View 30 Day Device Log'" 
          class="accent-fg tile-content clickable" 
          [ngStyle]="{'font-size': calculateFontsize(tile.data.length), 'line-height': 1.5}"
          (click)="showDeviceLogModal(device.serial, device.events)"
          >
            <span>{{ tile.data }}</span>
          </div>
          <div *ngIf="tile.data == 'View Deployment History'" 
          class="accent-fg tile-content clickable" 
          [ngStyle]="{'font-size': calculateFontsize(tile.data.length), 'line-height': 1.5}"
          (click)="showDeploymentHistoryModal()"
          >
            <span>{{ tile.data }}</span>
          </div>
        </div>
        
        <!--<div class="tile-body-wrapper-fade bottom"></div>-->
      </div>
      <div class="image-tile"
        *ngIf="tile.title == 'Image'"
        (click)="openGallery(tile.data)"
      >
        <img class="image" [src]="tile.data">
      </div>

    </fuse-widget>
  </div>  
</div>
