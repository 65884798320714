import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss'],
})
export class InputModalComponent extends BaseModal implements OnInit {
  val: any;
  inputVal: any;
  inputValues: any = {};
  submitDisabled: boolean;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<InputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this.submitDisabled = this.getDisabledState();
    this._fuseTranslationLoaderService.loadTranslations(english);
  }

  getDisabledState(){
    if(this.data.inputs){
      for(let input of this.data.inputs){
        if(input.validator && !input.validator(this.inputValues[input.name]))
          return true;
      }
    }
    return false;
  }

  changeHandler($event, prop){
    if($event.target.value)
      this.inputValues[prop] = $event.target.value;
    else 
      delete this.inputValues[prop];
    this.submitDisabled = this.getDisabledState();
  }

  ngOnInit(): void {
    this.showDialog();
  }
  cancel(): void {
    this.dialogRef.close(false);
  }
  close(): void {
    this.dialogRef.close(this.data.inputs ? this.inputValues : this.inputVal);
  }
}
