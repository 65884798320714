export const locale = {
  lang: 'en',
  data: {
    USERS: {
      TITLE: 'Users',
      ACTIONS: {
        ADD: 'Add Users',
        REMOVE: 'Remove',
        PROMOTE: 'Promote',
        DEMOTE: 'Demote',
        RESET_PASSWORD: 'Reset Password',
        FILTER: 'Filter users',
        SET_ROLE: 'Assign Role',
        RESEND_VERIFICATION: 'Resend Verification'
      },
      TABLE: {
        HEADER: {
          EMAIL: 'Email',
          FIRST_NAME: 'First Name',
          LAST_NAME: 'Last Name',
          PHONE: 'Phone',
          ROLE: 'Role',
          ACTIONS: 'Actions',
        },
        FOOTER: {
          EMPTY: 'No data to display',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
  },
};
