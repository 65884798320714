import { Component, OnDestroy, OnInit, ViewChild, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {
  HttpService,
  ModalService,
  PickupSensor,
} from 'app/services';
import { defer, from, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatAll,
  filter,
  map,
  switchMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { NavigationService } from 'app/services';
import { prettyPrintDate, prettyPrintPhone } from 'assets/formatting';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import {} from 'googlemaps';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AssignTechnicianDialogComponent } from '../../../modals/assign-technician-modal/assign-technician-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {
  VerificationReportModalComponent
} from '../../../modals/verification-report-modal/verification-report-modal.component';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-customer-location-details',
  templateUrl: './customer-location-details.component.html',
  styleUrls: ['./customer-location-details.component.scss'],
})
export class CustomerLocationDetailsComponent implements OnInit, OnDestroy {

  active: any;
  loading: boolean;
  locationName: string;
  showMap: boolean;
  extLocCustId: string;
  showGallery: boolean;
  gallerySrc: string;
  mapx: number;
  mapy: number;
  sorting: boolean;
  devices: any[];
  updateRequired: boolean;
  location: any;
  saving: boolean;
  inactive: boolean;
  mapLoaded: boolean;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('map') mapElement: any;
  map: google.maps.Map;
  markers:  any[] = [];
  drawingManager: any;
  dataSource: any;
  selection = new SelectionModel<object>(true, []);
  displayedColumns: string[] = ['select', 'devicename', 'device_serial', 'pretty_deploy', 'pretty_pickup', 'result_type', 'user_full_name', 'loc_img', 'gps', 'notes', 'actions'];
  queryInput = new FormControl('');
  activeFilter = new FormControl(true);
  activeObj: any;
  activeEl: any;
  eraserToolActive: boolean = false;
  private customerId: string;
  private locationId: string;
  private _unsubscribeAll: Subject<any>;

  technicianDataSource: MatTableDataSource<any>;
  technicianDisplayedColumns: string[] = ['name', 'email', 'phone', 'actions'];
  technicianQueryInput = new FormControl('');

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
  }
  isHeatMapOn:boolean = false;
  startDate = null;
  endDate = null;
  heatmap;
  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((params) => {
          this.customerId = params['customerId'];
          this.locationId = params['locationId'];

          return this.httpService.getBusinessAccountLocation(this.locationId);
        }),
      )
      .subscribe((location) => {
        this.httpService.geocode(location.address_1, location.city, location.state, "United States").pipe(
          takeUntil(this._unsubscribeAll),
          catchError(err => {
            throw err;
          })
        ).subscribe(res => {
          if(res.status == 'OK'){
            let coords = res.results[0].geometry.location
            this.mapx = coords.lng, this.mapy = coords.lat;
            this.fetchRows();
          }
        });
        this.locationName = location.name;
        this.extLocCustId = location.ext_loc_cust_id;
        this.location = location;
        delete this.location.inactive;
      });
    // Load the assigned technicians when the component is initialized
    this.fetchAssignedTechnicians();
  }

  fetchAssignedTechnicians(): void {
    this.httpService.getTechnicianAssignments(this.locationId)
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((assignments: any) => {
          // Sort technicians by name
          return assignments.map((assignment: any) => {
            return {
              ...assignment.technician,
              phone: prettyPrintPhone(assignment.technician.phone)
            };
          })
          .sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`;
            const nameB = `${b.first_name} ${b.last_name}`;
            return nameA.localeCompare(nameB);
          });
        })
      )
      .subscribe(technicians => {
        this.technicianDataSource = new MatTableDataSource(technicians);
        this.technicianDataSource.sort = this.sort;
        this.technicianQueryInput.valueChanges
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(value => this.filterTechnicians(value));
      });
  }

  filterTechnicians(searchText: string): void {
    if (searchText) {
      this.technicianDataSource.filter = searchText.trim().toLowerCase();
    } else {
      this.technicianDataSource.filter = '';
    }
  }

  unassignTechnician(technician: any): void {
    const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      width: '400px',
    });

    // Set the confirmation message with the technician's name
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to unassign technician ${technician.first_name} ${technician.last_name}?`;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.httpService.unassignTechnicianFromLocation(this.locationId, technician.id)
          .subscribe(() => {
            this.snackbar.open('Technician unassigned successfully!', 'Close', { duration: 3000 });
            this.fetchAssignedTechnicians();
          }, error => {
            this.snackbar.open('Error unassigning technician: ' + error.message, 'Close', { duration: 3000 });
          });
      }
    });
  }

  setMapOnAll(map: any | null) {
    for (let i = 0; i < this.markers.length; i++) {
      try {
        this.markers[i].setMap(map);
      }
      catch {
        this.markers[i].map = map;
      }
    }
  }
  // Removes the markers from the map, but keeps them in the array.
  hideMarkers(): void {
    this.setMapOnAll(null);
  }
  
  // Deletes all markers in the array by removing references to them.
  deleteMarkers(): void {
    this.hideMarkers();
    this.markers = [];
  }
  initializeOriginalMap(): void {
    if (!this.map) {
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        center: { lng: this.mapx, lat: this.mapy },
        zoom: 19,
        styles: [
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ]
        
      });
    }
    else {
      this.deleteMarkers();
    }
    this.devices.filter(el => !el.pickup_time).forEach(el => {
      let marker = new google.maps.Marker({
        position: el.gps || { lng: this.mapx, lat: this.mapy },
        map: this.map,  
        label: {
          text: el.device?.devicename || el.device?.sensorname || 'N/A',
          color: 'black',
          fontSize: '14px',
          className: 'marker-position',
      },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: el.total_alerts ? "red" : "lime",
          fillOpacity: 1,
          strokeColor:'gray',
          strokeWeight: 2,
          rotation: 0,
          scale: 5,
      }
      
      });
      marker.addListener('click', () => this.goToDeviceDetails(el));
      this.markers.push(marker);
    });
    this.mapLoaded = true;
  }

   initializeHeatMap(): void {
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints()
    });
     this.heatmap.set("radius", 30);
     this.mapLoaded = true;    
  }
  toggleHeatmap() {
    this.heatmap.setMap(this.heatmap.getMap() ? null : this.map);
    this.isHeatMapOn = !this.isHeatMapOn;
    if (this.isHeatMapOn) {
      this.deleteMarkers();
      this.heatmap.setData(this.getPoints());
    }
    else{
      this.initializeOriginalMap();
    }
  }

  tabIndex = 0;
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
  }
  getPoints() {
    let locations = [];
 
    if (this.startDate && this.endDate) {
      let startDateTime = new Date(this.startDate).getTime();
      let endDateTime = new Date(this.endDate).getTime();
      
      this.devices.forEach(el => {
        let deployTime = new Date(el.deploy_time).getTime();
        if (!el.device_serial?.includes("SHH") && deployTime > startDateTime && deployTime < endDateTime) {
          let loc = {location: new google.maps.LatLng(el.gps.lat, el.gps.lng), weight: el.total_alerts}
          locations.push(loc);
        }
      });
    }
    else {
      this.devices.filter(el => !el.device_serial?.includes("SHH")).forEach(el => {
        let loc = {location: new google.maps.LatLng(el.gps.lat, el.gps.lng), weight: el.total_alerts}
        locations.push(loc);
      });
    }

    return locations;
  }
  showHeatMapOptionModal(): void {
    this.modalService
      .showHeatMapOptionModal()
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.startDate=data.startDate;
        this.endDate=data.endDate;
        this.heatmap.setData(this.getPoints());
      });
  }

  showVerificationReportModal(): void {
    const dialogRef = this.dialog.open(VerificationReportModalComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const { fromDate, toDate } = result;

        const fromTimestamp = new Date(fromDate);
        fromTimestamp.setHours(0, 0, 0, 0);

        let n = new Date();
        let toTimestamp = new Date(toDate);

        if (toTimestamp.toDateString() !== n.toDateString() && toTimestamp < n) toTimestamp.setHours(23, 59, 59, 999);
        else toTimestamp = n;

        this.httpService.generateVerificationReport(fromTimestamp.getTime(), toTimestamp.getTime(), this.customerId, this.locationId)
          .subscribe((response: HttpResponse<Blob>) => {
            const contentDisposition = response.headers.get('Content-Disposition');
            const matches = /filename="(.+)"/.exec(contentDisposition);
            const fileName = matches && matches[1] ? matches[1] : 'verification-report.pdf';
            const downloadUrl = window.URL.createObjectURL(response.body);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
          }, error => {
            console.error('Error generating the report', error);
          });
      }
    });
  }

  openAssignTechniciansModal(): void {
    this.modalService.showTechnicianAssignmentListOfUsersModal(AssignTechnicianDialogComponent, {
      width: '600px',
      data: {
        locationId: this.locationId,
        assignedTechnicians: this.technicianDataSource.data
      },
    }).afterClosed().subscribe(result => {
      if (result && result.success) {
        this.fetchAssignedTechnicians();
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchRows(): Promise<boolean> {
    return new Promise((res, rej) => {
      try {
        this.loading = true;
        const devFilter = {
          include: [{ relation: 'segment' }, { relation: 'user' }],
        };

        this.httpService.getBusinessAccountDevices(this.customerId, this.locationId, null, null, devFilter)
        .pipe(
          takeUntil(this._unsubscribeAll),
          catchError(this.errorHandler)
        )
        .subscribe(devices => {
          this.parseDevices(devices);
          this.dataSource = new MatTableDataSource(devices);
          this.dataSource.sort = this.sort;
          this.dataSource.filterPredicate = (el, val) => {
              if(!val.active !== !el.pickup_time){
                if(val.query){
                  for(let i of ['devicename', 'device_serial', 'pretty_deploy', 'pretty_pickup', 'result_type', 'user_full_name'])
                    if(el[i] && `${el[i]}`.toLowerCase().indexOf(val.query) >= 0)
                      return true;
                } else 
                  return true;
              }
              return false;
          };
          const setFilter = () => this.dataSource.filter = ({query: this.queryInput.value.toLowerCase(), active: this.activeFilter.value});
          this.queryInput.valueChanges.subscribe(setFilter);
          this.activeFilter.valueChanges.subscribe(() => {
            this.activeFilter.value ? 
              this.displayedColumns.push('actions') : this.displayedColumns.splice(this.displayedColumns.length - 1, 1);
            setFilter();
          });
          this.devices = devices;
          setFilter();
          this.loading = false;
          this.initializeOriginalMap();
          this.initializeHeatMap();
          res(true);
        });
      } catch(err) {
        rej(err);
      }
    });
  }

  private parseDevices(devices: any[]): any[] {
    devices.forEach((device) => {
      if(!device.device)
        console.error(`device.device is undefined. This is likely the result of bunk data. Device object: ${device}`);
      else {
        this.parseCoords(device);
        device.devicename = device.device.devicename;
        device.notes = device.device.device_notes || device.deploy_notes || device.pickup_notes || false;
        device.device_notes = device.device.device_notes;
        device.user_full_name = device.user ? `${device.user.first_name} ${device.user.last_name}` : '';
        device.pretty_deploy = device.deploy_time ? prettyPrintDate(device.deploy_time, {time: true}) : '';
        device.pretty_pickup = device.pickup_time ? prettyPrintDate(device.pickup_time, {time: true}) : '';
      }
    });

    return devices.filter(device => !!device.device);
  }

  popoverOpened(): void {
    this.showMap = false;
    setTimeout(() => (this.showMap = true), 0); //why is there a timeout here ???
  }

  pickupOne(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let unit: any = this.selection.selected[0];
      this.modalService.showPickupSensorModal(unit)
      .afterClosed()
      .subscribe(pickupData => {
        if(pickupData){
          this.loading = true;
          this.httpService.pickupBusinessDevice(pickupData)
          .pipe(
            catchError(this.errorHandler)
          ).subscribe(res => {
            res ?
              resolve({} as any) :
              reject({failures: [unit.device_serial]});
          });
        } else
          resolve(undefined);
      });
    });
  }

  pickupMany(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.modalService.showConfirmModal(`${this.selection.selected.length} units will be picked up. Continue?`)
      .afterClosed()
      .subscribe(confirmed => {
        if(confirmed){
          this.loading = true;
          this.httpService.pickupBusinessDevices(this.selection.selected.map((el: any) => el.device_serial))
          .pipe(
            catchError(this.errorHandler)
          ).subscribe((res) => {
            res.failures ? reject(res) : resolve(res);
          });
        } else
          resolve(undefined);
    });
    })
  }

  pickup(): void {
    if(this.selection.selected.length < 1)
      return;

    (this.selection.selected.length == 1 ? 
      this.pickupOne() : 
      this.pickupMany()
    ).then(res => {
      if(res === undefined)
        return;

      if(res.failures)
        this.modalService.showModal('Error', `Pickup incomplete for following units:\n${res.failures.join(', ')}`);
      else
        this.snackbar.open('Unit pickup successful', 'OK', {duration: 2000});
      
      this.fetchRows().then(() => this.selection.clear());
    });
  }

  errorHandler(error){
    this.modalService.errorDialog(error);
    this.loading = false;
    return error;
  }
  
  goBack(): void {
    this.navigationService.goBack();
  }

  goToDeviceDetails(device: any): void {
    this.router.navigate(['/devices', `details`], {queryParams: {serial: device.device_serial || device.sensor_serial }});
  }

  private getErrors(response: any[]): any[] {
    const errors = response.filter((item) => item.error);

    // Some location operations failed
    errors.forEach((error) => {
      let name: string;

      if (error.deviceSerial) {
        const device = this.dataSource.data.find(
          el => el.device_serial === error.deviceSerial,
        );

        name = device.device.devicename;
      } else {
        name = 'Error';
      }

      error.message = `${name}: ${error.message}`;
    });

    return errors;
  }

  private parseCoords(device: any): void {
    if (
      !device.placement_lat_long ||
      device.placement_lat_long.indexOf(', ') === -1
    ) {
      // Error
      device.gps = {
        lat: -1000,
        lng: -1000,
      };

      return;
    }

    const coords = device.placement_lat_long.split(', ');

    device.gps = {
      lat: parseFloat(coords[0]),
      lng: parseFloat(coords[1]),
    };
  }


  showNotes(deviceName: string, deviceNotes?: string, deployNotes?: string, pickupNotes?: string): void {
    this.modalService.showModal(`${deviceName} Notes`, [
        'Device Notes:', 
        `${deviceNotes || 'N/A'}`, 
        '',
        'Deployment Notes:', 
        `${deployNotes || 'N/A'}`, 
        '',
        'Pickup Notes:', 
        `${pickupNotes || 'N/A'}`
    ]);
  }

  toggleAllRows() {
    if(this.dataSource.filteredData.length <= this.selection.selected.length)
      this.selection.clear();
    else
      this.selection.select(...this.dataSource.filteredData);
  }
}