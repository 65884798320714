import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { NavigationService } from 'app/services';
import { TranslateDefaultParser } from '@ngx-translate/core';


@Component({
    selector   : 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls  : ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    collapsed: boolean = true;
    fuseConfig: any;

    @Output()
    input: EventEmitter<any>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private route: ActivatedRoute
    )
    {
        // Set the defaults
        this.input = new EventEmitter();

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                let inputEl = <HTMLInputElement>document.querySelector('#fuse-search-bar-input');
                if(event.url.includes('/search')){
                    const urlParams = new URLSearchParams(window.location.search);
                    if(!inputEl.value)
                        inputEl.value = urlParams.get('query');
                    if(this.collapsed)
                        this.collapsed = false;
                } else {
                    this.collapsed = true;
                    !inputEl || (inputEl.value = "");
                }

            }
        });

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            )
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        if(window.location.href.includes("/search")){
            (<HTMLInputElement>document.querySelector('#fuse-search-bar-input')).value = "";
            this.search({target: {value: ''}});
        } else {
            this.collapsed = true;
        }
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
    }

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        this.input.emit(event.target.value);
    }

}
