<div>
    <h2 mat-dialog-title>Customize Deployment Details Report</h2>
    <mat-dialog-content fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
      <form [formGroup]="filterForm" fxLayout="column">
        <mat-form-field appearance="fill">
          <mat-label>Date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="beginDate" placeholder="Start date">
            <input matEndDate formControlName="endDate" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker (opened)="disableActions(true)" (closed)="disableActions(false)"></mat-date-range-picker>
        </mat-form-field>
        
        <mat-form-field appearance="standard">
          <mat-label>Customers</mat-label>
          <mat-select formControlName="customers" multiple (openedChange)="disableActions($event)">
            <mat-option class="select-all" (click)="toggleSelectAll('customers')" value="select-all">{{ isAllSelected.customers ? 'Deselect all' : 'Select all'}}</mat-option>
            <mat-option *ngFor="let el of data.optionSelect.customers" [value]="el.value">
              {{ el.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="standard">
          <mat-label>Deployments</mat-label>
          <mat-select formControlName="locations" multiple (openedChange)="disableActions($event)">
            <mat-option class="select-all" (click)="toggleSelectAll('locations')" value="select-all">{{ isAllSelected.locations ? 'Deselect all' : 'Select all'}}</mat-option>
            <mat-option *ngFor="let el of data.optionSelect.locations" [value]="el.value">
              {{ el.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="standard">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" (openedChange)="disableActions($event)">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" [class]="'mt-12 ' + (actionsDisabled ? 'hidden' : '')">
    <!-- mat-dialog-actions align="end" [class]="'mt-12 ' + (actionsDisabled ? 'hidden' : '')" -->
      <div style="flex-grow:1">
      <button mat-button (click)="dialogRef.close(true)" [disabled]="actionsDisabled">
        Reset
      </button>
      </div>
      <div>
      <button mat-button mat-dialog-close [disabled]="actionsDisabled">
        Cancel
      </button>
      <button mat-button (click)="dialogRef.close(filterForm.value)" [disabled]="actionsDisabled">
        Continue
      </button>
      </div>  
    </mat-dialog-actions>
  </div>
  