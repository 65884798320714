import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { AddCustomerModalModule } from 'app/main/modals/add-customer-modal/add-customer-modal.module';
import { EditCustomerModalModule } from 'app/main/modals/edit-customer-modal/edit-customer-modal.module';
import { CustomersComponent } from './customers.component';
import { CustomerLocationsModule } from '../customer-locations/customer-locations.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';

const routes: Routes = [
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [CustomersComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    AddCustomerModalModule,
    EditCustomerModalModule,
    CustomerLocationsModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatMenuModule
  ],
  exports: [CustomersComponent],
})
export class CustomersModule {}
