<div class="page-layout blank p-24 inner-scroll">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field class="search-subscriptions-field">
      <mat-label>{{ 'SUBSCRIPTIONS.ACTIONS.FILTER' | translate }}</mat-label>
      <input [formControl]="queryInput" matInput type="text"/>
      <button mat-button *ngIf="queryInput.value" matSuffix mat-icon-button (click)="queryInput.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="8px" fxFlexOffset="8px">
      <button
        mat-raised-button
        color="accent"
        [disabled]="!selection.selected.length"
        (click)="showActivateSubscriptionModal()"
      >
        {{ 'SUBSCRIPTIONS.ACTIONS.ACTIVATE' | translate }}
      </button>
      <button
        mat-raised-button
        color="warn"
        [disabled]="!selection.selected.length"
        (click)="showCancelSubscriptionModal()"
      >
        {{ 'SUBSCRIPTIONS.ACTIONS.CANCEL' | translate }}
      </button>
    </div>
  </div>
  <perfect-scrollbar>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="!$event || toggleAllRows()"
          [checked]="selection.hasValue() && selection.selected.length == dataSource.data.length"
          [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.data.length">
          </mat-checkbox>
        </mat-header-cell>            
        <mat-cell *matCellDef="let subscription">
          <mat-checkbox (click)="$event.stopPropagation()" 
          (change)="!$event || selection.toggle(subscription)"
          [checked]="selection.isSelected(subscription)"> 
          </mat-checkbox>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="bs_device_serial">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
        <mat-cell *matCellDef="let subscription"> {{ subscription.bs_device_serial }} </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="devicename">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Device name </mat-header-cell>
        <mat-cell *matCellDef="let subscription"> {{ subscription.devicename }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateFormatted">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Created </mat-header-cell>
        <mat-cell *matCellDef="let subscription"> {{ subscription.dateFormatted }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Subscription status </mat-header-cell>
        <mat-cell *matCellDef="let subscription"> {{ subscription.status }} </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </perfect-scrollbar>
</div>
