<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <mat-form-field class="search-devices-field">
          <mat-label>Filter events</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="filter"
            (keyup)="filterElements()"
          />
          <button
            mat-button
            matSuffix
            mat-icon-button
            (click)="filter = ''"
            *ngIf="filter && filter != ''"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

      </div>

      <div>
        <button mat-flat-button color="accent" (click)="downloadCsv(generateCsv())">
            <i class="fas fa-file-export"></i> Export CSV
        </button>
      </div>
    </div>

    <perfect-scrollbar>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
        <ng-container matColumnDef="dateFormatted">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.dateFormatted }} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="deviceName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Device Name </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.deviceName }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="serial">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.serial }} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="body">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Event </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.body }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Assigned User </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.user }} </mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </perfect-scrollbar>
  </div>