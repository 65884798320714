import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'NAV.DEPLOYMENTS.TITLE',
    type: 'item',
    icon: 'fa-crosshairs',
    url: '/dashboard',
  },
  {
    id: 'devices',
    title: 'Devices',
    translate: 'NAV.DEVICES.TITLE',
    type: 'item',
    icon: 'fa-phone-laptop',
    url: '/devices',
  },
  {
    id: 'users',
    title: 'Users',
    translate: 'NAV.USERS.TITLE',
    type: 'item',
    icon: 'fa-user',
    url: '/users',
  },
  {
    id: 'customers',
    title: 'Customers',
    translate: 'NAV.CUSTOMERS.TITLE',
    type: 'item',
    icon: 'fa-user-friends',
    url: '/customers',
  },
  {
    id: 'data',
    title: 'Data and Analytics',
    translate: 'NAV.DATA.TITLE',
    type: 'collapsable',
    icon: 'fa-analytics',
    children: [
      {
        id: 'reports',
        title: 'Reports',
        translate: 'NAV.REPORTS.TITLE',
        type: 'item', 
        icon: 'fa-chart-pie',
        url: '/reports'
      },
      {
        id: 'eventlog',
        title: 'Event Log',
        translate: 'NAV.EVENTLOG.TITLE',
        type: 'item', 
        icon: 'fa-list',
        url: '/eventlog'
      },
    ]
  },
  {
    id: 'billing',
    title: 'Billing',
    translate: 'NAV.BILLING.TITLE',
    type: 'collapsable',
    icon: 'fa-dollar-sign',
    children: [
      {
        id: 'update_billing',
        title: 'Update Billing',
        translate: 'NAV.UPDATE_BILLING.TITLE',
        type: 'item',
        icon: 'fa-credit-card',
        url: '/payment-method',
      },
      {
        id: 'invoices',
        title: 'View Invoices',
        translate: 'NAV.INVOICES.TITLE',
        type: 'item',
        icon: 'fa-file-invoice',
        url: '/invoices',
      },
      {
        id: 'subscriptions',
        title: 'Manage Subscriptions',
        translate: 'NAV.SUBSCRIPTIONS.TITLE',
        type: 'item',
        icon: 'fa-mobile-android',
        url: 'subscriptions',
      },
    ],
  },
];
