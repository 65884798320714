<div class="container" *ngIf="show || closing">
  <div [ngClass]="'backdrop ' + (show && !closing && !opening ? 'show' : '')" (click)="close()">
    <div *ngIf="loading">
      <i class="fas fa-spinner-third loading"></i>
    </div>  
  </div>
  <img class="current-image" [src]="imageUrls[index]" (load)="loading = false">
  <div class="actions-container">
    <a [href]="imageUrls[index]"><mat-icon fontSet="fa" fontIcon="fa-external-link"></mat-icon></a>
    <a (click)="close()"><mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon></a>
  </div>
</div>