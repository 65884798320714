import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputModalComponent } from './input-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  declarations: [InputModalComponent],
  imports: [
    NgxMaskModule.forRoot(),
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [InputModalComponent],
})
export class InputModalModule {}
