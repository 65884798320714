import { Input, Component, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'loading-component',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnDestroy, OnChanges {
  @Input() complete: boolean;
  @Input() display: string;
  @Input() style: string;  
  @Input() color: string;

  classlist = {
    'loading-backdrop': true,
    'solid': true,
    'opacity-transition': false,
    'hide': false,
    'flex': false,
    'light': false,
  };
  hide: boolean;
  lastDisplay: string;
  private _unsubscribeAll: Subject<any>;
  constructor() {
    this._unsubscribeAll = new Subject();
    this.evaluateClasses();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(): void {
    this.evaluateClasses();
  }

  evaluateClasses(){
    this.classlist['flex'] = this.display == 'flex';
    this.classlist['light'] = this.color == 'light';
    this.classlist['hide'] = !!this.complete;
  }
}