import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { AddNewDeviceModalModule } from 'app/main/modals/add-new-device-modal/add-new-device-modal.module';
import { AddUsersModalModule } from 'app/main/modals/add-users-modal/add-users-modal.module';
import { AssignDevicesModalModule } from 'app/main/modals/assign-devices-modal/assign-devices-modal.module';
import { DeploymentHistoryModalModule  } from 'app/main/modals/deployment-history-modal/deployment-history-modal.module';
import { EventLogComponent } from './eventlog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table'; 
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const routes: Routes = [
  {
    path: 'eventlog',
    component: EventLogComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [EventLogComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatCardModule,
    GoogleMapsModule,
    MdePopoverModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    AddUsersModalModule,
    AddNewDeviceModalModule,
    DeploymentHistoryModalModule,
    AssignDevicesModalModule,
    MatTabsModule,
    MatTableModule,
    PerfectScrollbarModule,
    MatSortModule
  ],
  exports: [EventLogComponent],
})
export class EventLogModule {}
