import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BusinessAccountLocation } from 'app/services';
import { Subject } from 'rxjs';
import { emailValidator, zipValidator } from 'validators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { states } from 'assets/states';
@Component({
  selector: 'app-add-customer-location-modal',
  templateUrl: './add-customer-location-modal.component.html',
  styleUrls: ['./add-customer-location-modal.component.scss'],
})
export class AddCustomerLocationModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  locationForm: FormGroup;
  states = states;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<AddCustomerLocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    dialogRef.disableClose = true;
    this._fuseTranslationLoaderService.loadTranslations(english);

    this.locationForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      extLocCustId: new FormControl(''),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required, zipValidator()]),
      contactFirstName: new FormControl('', [Validators.required]),
      contactLastName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [emailValidator()]),
      contactPhone: new FormControl(''),
    });

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  sendCustomer(): void {
    const newLocation: BusinessAccountLocation = {
      name: this.locationForm.get('name').value,
      ext_loc_cust_id: this.locationForm.get('extLocCustId').value,
      address_1: this.locationForm.get('address1').value,
      address_2: this.locationForm.get('address2').value,
      city: this.locationForm.get('city').value,
      state: this.locationForm.get('state').value,
      zip: this.locationForm.get('zip').value,
      contact_first_name: this.locationForm.get('contactFirstName').value,
      contact_last_name: this.locationForm.get('contactLastName').value,
      contact_email: this.locationForm.get('contactEmail').value,
      contact_phone: this.locationForm
        .get('contactPhone')
        .value.replace(/-/g, ''),
    };

    this.dialogRef.close(newLocation);
  }
}
