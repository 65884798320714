import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'app/services';
import * as _ from 'lodash';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { prettyPrintDate } from 'assets/formatting';

@Component({
  selector: 'app-eventlog',
  templateUrl: './eventlog.component.html',
  styleUrls: ['./eventlog.component.scss'],
})
export class EventLogComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  private _unsubscribeAll: Subject<any>;
  devices: any;
  deviceEvents: any;
  loading: boolean;
  users: any;
  filter: string;
  dataSource: any;
  events: any;
  displayedColumns: string[] = ['dateFormatted', 'deviceName', 'serial', 'body', 'user'];

  constructor(
    private httpService: HttpService,
  ) {
    this._unsubscribeAll = new Subject();
    this.deviceEvents = [];
    this.devices = {};
    this.users = {};
  }

  ngOnInit(): void {
    this.loading = true;
    this.buildRows();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  buildRows(): void {
    this.deviceEvents = [];
    this.loading = true;
    const $devices = this.httpService.getBusinessDevices();
    const $hubsensors = this.httpService.getBusinessHubSensors();

    forkJoin([$devices, $hubsensors])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((data) => [...data[0], ...data[1]]),
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        }),
      )
      .subscribe((res) => {
          res.forEach(el => {
            this.devices[el.id] = el;
            this.deviceEvents.push(...(el.deviceEvents || el.sensorHistories || []));
          });
          this.httpService
          .getBusinessUsers()
          .pipe(
            takeUntil(this._unsubscribeAll),
            catchError((err) => {
              this.loading = false;
              return throwError(err);
            })
          ).subscribe((users) => {
            users.forEach(el => {
              el.fullName = `${el.first_name} ${el.last_name}`;
              this.users[el.id] = el;
            });
            this.deviceEvents.forEach(el => {
              el.deviceName = this.devices[el.device_id || el.sensor_id].devicename;
              el.serial = (this.devices[el.device_id || el.sensor_id].device_serial || this.devices[el.device_id || el.sensor_id].sensor_serial);
              el.user = this.users[el.user_id]?.fullName;
              el.dateFormatted = prettyPrintDate(el.created, {time: true});
            });
            this.deviceEvents.sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0);
            this.initializeDataSource(this.deviceEvents);
            this.loading = false;
          });
      });

  }

  generateCsv(): string {
    let rows = [];
    this.deviceEvents.forEach(el => {
      if(el.filtered)
        return;
      rows.push([
        el.dateFormatted, 
        this.devices[el.device_id || el.sensor_id].devicename.replaceAll('\n','').replaceAll(',','').replaceAll('    ','').replaceAll('#',''), //turns out csv and number signs dont play well? 
        (this.devices[el.device_id || el.sensor_id].device_serial || this.devices[el.device_id || el.sensor_id].sensor_serial),
        el.body.replaceAll('\n','').replaceAll(',','').replaceAll('    ','').replaceAll('#',''), //a bunch of event bodies contain newlines, commas, and tabs now -- doesnt play nice with csv
        this.users[el.user_id]?.fullName.replaceAll('\n','').replaceAll(',','').replaceAll('    ','').replaceAll('#','')
      ]);
    });
    return encodeURI("data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n"));
  }

  downloadCsv(uri: string): void {
    let a = document.createElement("a");
    a.href = uri;
    a.download = `Skyhawk Event Log ${prettyPrintDate({fs: true})}`;
    a.hidden = true;
    document.body.appendChild(a);
    a.innerHTML = "(ꈍᴗꈍ✿)";
    a.click();
    document.body.removeChild(a);
  }

  filterElements(){
    let query = this.filter.toLowerCase();
    this.deviceEvents.forEach(el => {
      if(
        el.dateFormatted.toLowerCase().includes(query) ||
        this.devices[el.device_id || el.sensor_id].devicename.toLowerCase().includes(query) ||
        (this.devices[el.device_id || el.sensor_id].device_serial || this.devices[el.device_id || el.sensor_id].sensor_serial).toLowerCase().includes(query) ||
        el.body.toLowerCase().includes(query) ||
        this.users[el.user_id].fullName.toLowerCase().includes(query)
      )
        return el.filtered = false;
      el.filtered = true;
    });
    this.initializeDataSource(this.deviceEvents.filter(el => !el.filtered));
  }

  initializeDataSource(data: any){
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
      typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
    this.dataSource.sort = this.sort;
  }

}
