import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService } from 'app/services';
import { emailValidator } from 'validators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-add-users-modal',
  templateUrl: './add-users-modal.component.html',
  styleUrls: ['./add-users-modal.component.scss'],
})
export class AddUsersModalComponent extends BaseModal implements OnInit {
  //technically extraneous to differentiate between 'users' and 'new users' w/ changes made in 3237
  //should clean up at some point
  userEmail: FormControl;
  users: string[];
  newUsers: string[];
  newUserForm: FormGroup;

  private dataToSend: any;
  private existingUsers: any[];
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpService,
    protected dialogRef: MatDialogRef<AddUsersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);

    this._fuseTranslationLoaderService.loadTranslations(english);

    this.users = [];
    this.newUsers = [];
    this.existingUsers = [];

    this.dataToSend = {
      existingUsers: [],
      newUsers: [],
    };
  }

  ngOnInit(): void {
    this.userEmail = new FormControl('', [
      Validators.required,
      emailValidator(),
      this.checkUserValidator(),
    ]);

    this.newUserForm = new FormGroup({
      email: new FormControl('', [Validators.required, emailValidator()]),
      confirmEmail: new FormControl('', [
        Validators.required,
        emailValidator(),
        this.emailMatchValidator(),
      ]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.passwordMatchValidator(),
      ]),
    });

    this.showDialog();
  }

  addUser(): void {
    this.userEmail.markAsTouched();

    if (this.userEmail.valid) {
      this.users.push(this.userEmail.value);
      this.userEmail.reset();
    }
  }

  removeUser(user: string): void {
    this.users = this.users.filter((item) => item !== user);
    this.userEmail.updateValueAndValidity();
  }

  sendData(): void {
    this.dialogRef.close(this.dataToSend);
  }

  shiftUser(): void {
    const user = {
      first_name: this.newUserForm.get('firstName').value,
      last_name: this.newUserForm.get('lastName').value,
      email: this.newUserForm.get('email').value,
      phone: this.newUserForm.get('phone').value,
      password: this.newUserForm.get('password').value,
      logintype: 'email',
      marketing_allowed: 0,
    };

    this.dataToSend.newUsers.push(user);

    if (this.newUsers.length > 1) {
      this.newUsers.shift();

      this.newUserForm.reset();
      this.newUserForm.get('email').setValue(this.newUsers[0]);
    } else {
      this.sendData();
    }
  }

  doUsersExist(): void {
    // Add user if email field is filled out
    this.addUser();

    this.http.doUsersExist(this.users).subscribe((res) => {
      this.existingUsers = res;
      this.newUsers = this.users.filter(
        (user) => !this.existingUsers.includes(user),
      );

      if (this.existingUsers.length) {
        this.dataToSend.existingUsers = this.existingUsers.map(el => ({email: el}));
      }

      if (this.newUsers.length) {
        this.newUserForm.get('email').setValue(this.newUsers[0]);
      } else {
        this.sendData();
      }
    });
  }

  private checkUserValidator(): ValidatorFn {
    return (control: FormControl) => {
      const email = control.value;

      if (this.users.includes(email)) {
        return { userAlreadyAdded: true };
      }

      return null;
    };
  }

  private emailMatchValidator(): ValidatorFn {
    return (confirmControl: FormControl) => {
      const confirmEmail = confirmControl.value;
      const email = this.newUserForm?.get('email').value;

      if (confirmEmail && email && confirmEmail.toUppperCase() !== email.toUppperCase()) {
        return { emailMismatch: true };
      }

      return null;
    };
  }

  private passwordMatchValidator(): ValidatorFn {
    return (confirmControl: FormControl) => {
      const confirmPassword = confirmControl.value;
      const password = this.newUserForm?.get('password').value;

      if (confirmPassword !== password) {
        return { passwordMismatch: true };
      }

      return null;
    };
  }
}
