import { Component, OnDestroy } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { locale as english } from './i18n/en';
import { AuthService } from 'app/services';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy {

  query: string;
  res: any;
  streamline: any;
  loading: any = {};
  private _unsubscribeAll: Subject<any>;
  Users: any = 'Users (0)';
  Deployments: any = 'Deployments (0)';
  Customers: any = 'Customers (0)';
  Devices: any = 'Devices (0)';
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private auth: AuthService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.auth.streamline.subscribe(val => this.streamline = val);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateResults(event: any){
    this[event.component] = `${event.component} (${event.data || '0'})`;
  }
}
