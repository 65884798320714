import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule
} from 'ngx-cookieconsent';
import { AppComponent } from './app.component';
import { fuseConfig } from './fuse-config';
import { HttpErrorInterceptor } from './interceptors';
import { LayoutModule } from './layout/layout.module';
import { AccountConfirmModule } from './main/components/account/account-confirm/account-confirm.module';
import { SettingsModule } from './main/components/settings/settings-base/settings.module';
import { CbInvoicesModule } from './main/components/billing/cb-invoices/cb-invoices.module';
import { CbPaymentMethodModule } from './main/components/billing/cb-payment-method/cb-payment-method.module';
import { CbSubscriptionsModule } from './main/components/billing/cb-subscriptions/cb-subscriptions.module';
import { ChangePasswordModule } from './main/components/settings/change-password/change-password.module';
import { ResetPasswordModule } from './main/components/account/reset-password/reset-password.module';
import { CustomerLocationDetailsModule } from './main/components/customers/customer-location-details/customer-location-details.module';
import { CustomerLocationsModule } from './main/components/customers/customer-locations/customer-locations.module';
import { CustomersModule } from './main/components/customers/customers-base/customers.module';
import { DashboardModule } from './main/components/dashboard/dashboard-container/dashboard.module';
import { DevicesDashboardModule } from './main/components/dashboard/devices/devices.module';
import { DeploymentsModule } from './main/components/dashboard/deployments/deployments.module';
import { DeviceDetailsModule } from './main/components/devices/device-details/device-details.module';
import { DevicesModule } from './main/components/devices/devices/devices.module';
import { DeviceMapModule } from './main/components/devices/device-map/device-map.module';
import { DeviceListModule } from './main/components/devices/device-list/device-list.module';
import { EventLogModule } from './main/components/data-analytics/eventlog/eventlog.module';
import { ForgotPasswordModule } from './main/components/account/forgot-password/forgot-password.module';
import { LoginModule } from './main/components/account/login/login.module';
import { PasswordConfirmModule } from './main/components/account/password-confirm/password-confirm.module';
import { RegisterModule } from './main/components/account/register/register.module';
import { UsersModule } from './main/components/users/users.module';
import { ReportsModule } from './main/components/data-analytics/reports/reports.module';
import { Error403Module } from './main/errors/403/error-403.module';
import { Error404Module } from './main/errors/404/error-404.module';
import { Error500Module } from './main/errors/500/error-500.module';
import { ConfirmModalModule } from './main/modals/confirm-modal/confirm-modal.module';
import { InputModalModule } from './main/modals/input-modal/input-modal.module';
import { InfoModalModule } from './main/modals/info-modal/info-modal.module';
import { AnalyticsService } from './providers/analytics/analytics.service';
import { AuthService } from './services';
import { GalleryModule } from './main/components/general/gallery/gallery.module';
import { SearchModule } from './main/components/search/search.module';
import { DeviceDetailsModalModule } from './main/modals/device-details-modal/device-details-modal.module';
import { LocationNotificationsModalModule } from './main/modals/location-notifications-modal/location-notifications-modal.module';
import { ScheduledReportModalModule } from './main/modals/scheduled-report-modal/scheduled-report-modal.module';
import { VerificationReportModalComponent } from './main/modals/verification-report-modal/verification-report-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomizeVerificationReportModalComponent } from './main/modals/customize-verification-report-modal/customize-verification-report-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core'; // For date handling with MatDatepicker
import { ReactiveFormsModule } from '@angular/forms';





const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#1e2129',
    },
    button: {
      background: '#039be5',
    },
  },
  theme: 'classic',
  type: 'info',
};

function jwtOptionsFactory(authService: AuthService): any {
  let domain: string;
  try {
    domain = new URL(environment.apiUrl).host;
  } catch {
    domain = '';
  }

  const rtn: any = {
    tokenGetter: () => authService.getToken(),
  };

  if (domain) {
    rtn.allowedDomains = [domain];
  }

  return rtn;
}

const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'error-404' },
];

@NgModule({
  declarations: [AppComponent, VerificationReportModalComponent, CustomizeVerificationReportModalComponent],
  imports: [
    // Cookie consent module
    NgcCookieConsentModule.forRoot(cookieConfig),

    // JWT module
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      },
    }),

    // HTTP modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

    // Translation module
    TranslateModule.forRoot(),

    // Material modules
    MatButtonModule,
    MatIconModule,
    MatMomentDateModule,
    NgxMatNativeDateModule,
    MatSortModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    LoginModule,
    RegisterModule,
    ForgotPasswordModule,
    ChangePasswordModule,
    AccountConfirmModule,
    PasswordConfirmModule,
    DashboardModule,
    DevicesDashboardModule,
    DeploymentsModule,
    DevicesModule,
    DeviceMapModule,
    DeviceListModule,
    DeviceDetailsModule,
    UsersModule,
    CustomersModule,
    CustomerLocationsModule,
    CustomerLocationDetailsModule,
    SettingsModule,
    CbInvoicesModule,
    CbPaymentMethodModule,
    CbSubscriptionsModule,
    ReportsModule,
    GalleryModule,
    EventLogModule,
    SearchModule,
    ResetPasswordModule,

    // Error modules
    Error403Module,
    Error404Module,
    Error500Module,

    // Modal modules
    InfoModalModule,
    ConfirmModalModule,
    DeviceDetailsModalModule,
    InputModalModule,
    LocationNotificationsModalModule,
    ScheduledReportModalModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: Window, useValue: window },
    AnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
