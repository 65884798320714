import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthService } from 'app/services';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  changePasswordError: string;
  submitting: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }
  onSubmit(): void {
    this.submitting = true;
    this.changePasswordError = null;

    this.authService
      .changePassword(this.changePasswordForm.value)
      .pipe(
        catchError((err) => {
          this.submitting = false;

          if (err.code === 400) {
            this.changePasswordError = err.message;
          } else {
            this.changePasswordError =
              'An error has occured. Please try again.';
          }

          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.submitting = false;

        this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
      });
  }

  toggleShow(controlName: string): void {
    let el = document.querySelector(`input[formcontrolname="${controlName}"]`);
    el.getAttribute('type') == 'password' ? el.setAttribute('type', 'text') : el.setAttribute('type', 'password');
  }
  

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.changePasswordForm = this._formBuilder.group({
      curPassword: ['', Validators.required],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          passwordMatchingValidator,
        ],
      ],
      newPasswordConfirm: ['', [Validators.required, confirmPasswordValidator]],
    });

    // Update the validity of the 'newPassword' field when the 'curPassword' field changes
    this.changePasswordForm
      .get('curPassword')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.changePasswordForm.get('newPassword').updateValueAndValidity();
      });

    // Update the validity of the 'newPasswordConfirm' field when the 'newPassword' field changes
    this.changePasswordForm
      .get('newPassword')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.changePasswordForm
          .get('newPasswordConfirm')
          .updateValueAndValidity();
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const newPassword = control.parent.get('newPassword');
  const newPasswordConfirm = control.parent.get('newPasswordConfirm');

  if (!newPassword || !newPasswordConfirm) {
    return null;
  }

  if (newPasswordConfirm.value === '') {
    return null;
  }

  if (newPassword.value === newPasswordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

const passwordMatchingValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const curPassword = control.parent.get('curPassword');
  const newPassword = control.parent.get('newPassword');

  if (!curPassword || !newPassword) {
    return null;
  }

  if (newPassword.value === '') {
    return null;
  }

  if (curPassword.value !== newPassword.value) {
    return null;
  }

  return { passwordsMatching: true };
};
