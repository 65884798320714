import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard, PaymentGuard } from 'app/guards';
import { CbInvoicesComponent } from './cb-invoices.component';

const routes: Routes = [
  {
    path: 'invoices',
    component: CbInvoicesComponent,
    canActivate: [AuthGuard, PaymentGuard],
  },
];

@NgModule({
  declarations: [CbInvoicesComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
  ],
  exports: [CbInvoicesComponent],
})
export class CbInvoicesModule {}
