import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService } from 'app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-assign-devices-modal',
  templateUrl: './assign-devices-modal.component.html',
  styleUrls: ['./assign-devices-modal.component.scss'],
})
export class AssignDevicesModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  assignDevicesForm: FormGroup;
  deviceList: any[];
  userList: string[];

  private _unsubscribeAll: Subject<any>;

  constructor(
    private httpService: HttpService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<AssignDevicesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);

    this._fuseTranslationLoaderService.loadTranslations(english);

    this.assignDevicesForm = new FormGroup({
      tech: new FormControl('', [Validators.required]),
    });

    this.deviceList = data.devices;

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.httpService
      .getBusinessUsers()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(users => {
        users.forEach(el => el.label = `${el.last_name}, ${el.first_name} (${el.email})`);
        users.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
        this.userList = users;
      });

    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  sendDevicesAssign(): void {
    const assignDevices = {
      deviceSerials: this.deviceList.map((dev) => dev.serial),
      techId: this.assignDevicesForm.get('tech').value,
    };

    this.dialogRef.close(assignDevices);
  }
}
