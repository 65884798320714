<div class="page-layout blank p-24 inner-scroll container" fusePerfectScrollbar>
  <!--div *ngIf="loading">
    <i class="fas fa-spinner-third loading"></i>
  </div-->
  <div class="content">
    <h2 *ngIf="query">Showing results for <span class="query">"{{query}}"</span></h2>
    <h2 *ngIf="!query">Enter a search query to filter results</h2>
    <mat-tab-group>
      <ng-container *ngIf="!streamline">
        <mat-tab [label]="Deployments">
          <app-deployments (queryReturn)="updateResults($event)"></app-deployments>
        </mat-tab>
      </ng-container>
      <mat-tab [label]="Devices">
        <app-device-list (queryReturn)="updateResults($event)"></app-device-list>
      </mat-tab>
      <mat-tab [label]="Users">
        <app-users (queryReturn)="updateResults($event)"></app-users>
      </mat-tab>
      <mat-tab [label]="Customers">
        <app-customers (queryReturn)="updateResults($event)"></app-customers>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
