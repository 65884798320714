import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { navigation } from 'app/navigation/navigation';
import { AuthService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const routeTitles = {
  "/devices": "Devices",
  "/dashboard": "Dashboard",
  "/": "Dashboard",
  "/users": "Users",
  "/customers": "Customers",
  "/settings": "Settings",
  "/business": "Business Profile",
  "/payment-method": "Update Payment Method",
  "/invoices": "Invoices",
  "/subscriptions": "Subscriptions",
  "/reports": "Reports",
  "/eventlog": "Event Log"
}

const darkTheme = navigator.userAgent.includes('Firefox') ? 'theme-pink-dark' : 'theme-blue-gray-dark';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  userName: string;
  pageTitle: string = routeTitles[window.location.pathname];
  query: string;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private modalService: ModalService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      // {
      //   id: 'tr',
      //   title: 'Turkish',
      //   flag: 'tr',
      // },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });

    this.authService.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.userName = user?.name;
      });

    this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((e: any) => {
        this.pageTitle = routeTitles[window.location.pathname];
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    //this works a little wonky
    typeof value !== 'string' || this.router.navigateByUrl(`/search?query=${value}`, {replaceUrl: window.location.href.includes('/search')});
    // Do your search here...
  }

  logout(): void {
    this.authService.logout();
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  settings(): void {
    this.router.navigate(['/settings']);
  }

  toggleTheme(): void {
    let curTheme, nextTheme;
    if(this.isDarkMode()){
      nextTheme = 'theme-default';
      curTheme = darkTheme;
    } else {
      curTheme = 'theme-default';
      nextTheme = darkTheme;
    }
  
    document.body.classList.replace(curTheme, nextTheme);
    localStorage.setItem('skyhawk_ee__theme', nextTheme);
  }
  isDarkMode(): boolean {
    return document.body.classList.contains('theme-pink-dark') || document.body.classList.contains('theme-blue-gray-dark');
  }
}
