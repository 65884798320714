/*~~~~~~~~~~~~~~~~~~~~~
this components name is like, completely unacceptable tbh
'CustomizeDeploymentDetailsReportModalComponent' -> 46 char
the next one will be longer than picasso's full name at this rate

TODO: better naming convention for these report filtering modals 
~~~~~~~~~~~~~~~~~~~~~~*/
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { CustomizeDeploymentDetailsReportModalComponent } from './customize-deployment-details-report-modal.component';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [CustomizeDeploymentDetailsReportModalComponent],
  imports: [
    NgxMaskModule.forRoot(),
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    MatDatepickerModule
  ],
  exports: [CustomizeDeploymentDetailsReportModalComponent],
})
export class CustomizeDeploymentDetailsReportModalModule {}