<div>
  <h2 mat-dialog-title>
    {{ 'MODAL.TITLES.PICKUP_DEVICE' | translate }} ({{ deviceInfo.device.devicename }})
  </h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
    <form [formGroup]="unassignSensorForm" fxLayout="column">
      <div><h3>Pickup Time: {{ pickupTimeString }}</h3></div>
      <mat-checkbox (change)="isCapture = !isCapture" [checked]="isCapture">
        Capture
      </mat-checkbox>
      <mat-form-field appearance="standard">
        <mat-label>Result</mat-label>
        <input matInput formControlName="results">
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes"></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-12" align="end">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button mat-button (click)="sendSensorUnassign()">
      {{ 'MODAL.ACTIONS.PICKUP' | translate }}
    </button>
  </mat-dialog-actions>
</div>
