import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService } from 'app/services';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { catchError, map } from 'rxjs/operators';
import { ModalService  } from 'app/services';
import { ActivatedRoute } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-deployments',
  templateUrl: './deployments.component.html',
  styleUrls: ['./deployments.component.scss'],
})
export class DeploymentsComponent implements OnDestroy {
  private _unsubscribeAll: Subject<any>;
  @Output() queryReturn = new EventEmitter<any>();
  _loading: boolean = true;
  _tiles: any = [];
  _order = [-1, 1];
  _sort: any;  
  _sorts = {
    customer: (a, b) => a.customer_name > b.customer_name ? this._order[0] : a.customer_name < b.customer_name ? this._order[1] : 0,
    deployment: (a, b) => a.name > b.name ? this._order[0] : a.name < b.name ? this._order[1] : 0,
    notification: (a, b) => a.total_alerts > b.total_alerts ? this._order[0] : a.total_alerts < b.total_alerts ? this._order[1] : 0,
    unit: (a, b) => a.units.length > b.units.length ? this._order[0] : a.units.length < b.units.length ? this._order[1] : 0,
    heartbeat: (a, b) => a.missedHeartbeats > b.missedHeartbeats ? this._order[0] : a.missedHeartbeats < b.missedHeartbeats ? this._order[1] : 0, 
    technician: (a, b) => {
      if(a.technicians.length > 1 || !a.technicians.length)
        return b.technicians.length ? this._order[0] : this._order[1];
      if(b.technicians.length > 1 || !b.technicians.length)
        return this._order[1];
      return a.techniciansLabel > b.techniciansLabel ? this._order[0] : a.techniciansLabel < b.techniciansLabel ? this._order[1] : 0;
    }
  }
  _refreshInt: any;
  embedded = !window.location.href.includes("/dashboard");
  showMap: boolean;
  query: string = "";
  users: any[] = [];
  locations: any[] = [];

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.route.queryParams.subscribe(params => {
      this.query = params.query || "";
      this.updateSearch();
    });
    this.buildTiles();
    this._refreshInt = setInterval(this.buildTiles.bind(this), 60000);
  }

  get tiles(){ return this._tiles || []; }

  set tiles(val){
    this._tiles = val.sort(this._sort || this._sorts.notification);
  }

  get descending(){ return this._order[0] == -1; }

  set descending(val){
    this._order = val ? [-1, 1] : [1, -1]    
    this.sort();
  }

  toggleDescending(){
    this._order = this._order.map(el => el * -1);
    this.sort();
  }

  get sort(){
    return () => this._tiles = this._tiles.sort(this._sort || this._sorts.notification);
  }

  set sort(val: any){
    this._sort = this._sorts[val] || this._sorts.notification;
    this.sort();
  }

  get loading(){ return this._loading; }

  set loading(val){
    this._loading = val;
    if(this.embedded && !val)
      this.updateSearch();
  }

  popoverOpened(): void {
    this.showMap = false;
    setTimeout(() => (this.showMap = true), 0);
  }

  getTechniciansLabel(technicians): string {
    if(technicians.length == 1)
      return technicians[0] ? `${technicians[0].first_name} ${technicians[0].last_name}` : "(Removed user)";
    if(technicians.length > 1)
      return '[Multiple]';
    return 'N/A';
  }

  buildTiles(): void {
    let startTime = Date.now();
    let tiles = [];
    Promise.all([
      this.httpService.getBusinessUsers().toPromise().then(users => this.users = users),
      this.httpService.getDeployments().toPromise().then(locations => this.locations = locations),
    ]).then(() => {
      for(let location of this.locations){
        let technicians = {};
        for(let id of location.technicians)
          if(!technicians[id])
            technicians[id] = this.users.find(user => user.id == id);
        location.technicians = Object.values(technicians);
        location.techniciansLabel = this.getTechniciansLabel(location.technicians);
        location.addressLabel = `${location.address_1}, ${location.state} ${location.zip}`;
        location.containsQuery = true;
        location.missedHeartbeats = 0;
        location.units?.forEach(el => {
          let hb, connection, power;
          if(el.device_serial){
            hb = el['unit_autocheckin'];
            connection = el['lastConnection'];
            power = el['isDevicePowerOn'];
          } else {
            hb = el['sensr_heartbeat'];
            connection = el['sensr_lastConnection'];
            power = el['sensr_power'];
          }

          if (!hb || hb === '000' || !power)
            return false;
          let hbTime = (Number(hb) * 3600000);
          
          if (el.sensor_serial){
            hbTime = ((32400000 + (hbTime * 1.1)) + connection);   
          } else {
            if (el.device_serial.substring(0, 3) === 'SHH') {
              hbTime = ((3600000 + (hbTime * 1.1)) + connection);
            } else {
              hbTime = ((hbTime + (hbTime * 1.05)) + connection);
            }
          }
      
          if(hbTime < startTime){
            el.missedHeartbeat = true;
            location.missedHeartbeats++;
          }
        });
        location.coords = this.httpService.geocode(location.address_1, location.city, location.state, location.country).pipe(
          takeUntil(this._unsubscribeAll),
          catchError(err => {
            throw err;
          })
        ).subscribe(geo => {
          if(geo.status == 'OK')
            location.coords = geo.results[0].geometry.location;
        });
        tiles.push(location);
      }
      this.tiles = tiles;
      this.loading = false;
    }).catch(rej => {
      this.modalService.errorDialog(rej);
      this.loading = false;
    });
  }

  updateSearch(val?): void {
    if(typeof val === 'string' || typeof val === 'undefined'){
      typeof val === 'undefined' || (this.query = val?.toLowerCase() || '');
      this.tiles.forEach(tile => {
        if(tile.techniciansLabel.toLowerCase().includes(this.query))
          return tile.containsQuery = true;
        if(tile.customer_name.toLowerCase().includes(this.query))
          return tile.containsQuery = true;
        if(`${tile.total_alerts}`.includes(this.query))
          return tile.containsQuery = true;
        if(tile.name.toLowerCase().includes(this.query))
          return tile.containsQuery = true;
        if(`${tile.units.length}`.includes(this.query))
          return tile.containsQuery = true;
        tile.containsQuery = false;
      });
    } else 
      for(let tile of this.tiles)
        tile.containsQuery = true;
    
    this.returnQuery(this.tiles.filter(el => el.containsQuery).length);
  }

  ngOnDestroy(): void {
    clearInterval(this._refreshInt);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showTechnicianTransferModal(devices: any[], assignedTechnicians: any[]): void {
    this.modalService.showTechnicianTransferModal(devices, assignedTechnicians, this.users, () => this.buildTiles());
  }

  showDeploymentNotificationsModal(devices: any[]): void {
    this.modalService.showDeploymentNotificationsModal(devices.filter(el => el.app_tc && el.app_tc > 0));
  }

  showMissedHeartbeatModal(devices: any[]): void {
    this.modalService.showMissedHeartbeatsModal(devices.filter(el => el.missedHeartbeat));
  }
  
  audit(id: string): void {
    this.router.navigateByUrl(`/reports?audit=${id}`);
  }

  returnQuery(data): void {
    this.queryReturn.next({component: 'Deployments', data: data || 0});
  }
}
