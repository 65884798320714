import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { states } from 'assets/states';

export function emailValidator(): ValidatorFn {
  // Taken from https://emailregex.com
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  return (control: FormControl) => {
    const email = control.value;

    if (!email) {
      return null;
    }

    if (!emailRegex.test(email)) {
      return { emailInvalid: true };
    }

    return null;
  };
}

export function passwordsMatch(group: AbstractControl): ValidationErrors | null { 
  let err;
  if(group.get('newPassword').value != group.get('confirmNewPassword').value)
    err = {noMatch: true};
  if(group.get('newPassword').value == group.get('currentPassword').value)
    err = {...(err || {}), sameAsCurrent: true};
  return err;
}


export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.dirty);

    return invalidCtrl || invalidParent;
  }
}

export function zipValidator(): ValidatorFn {
  return (control: FormControl) => {
    if(!control.value || !/^\d{5}(-\d{4})?$/.test(control.value))
      return { zipInvalid: true };
  }
}

export function statesValidator(): ValidatorFn {
  return (control: FormControl) => {
    if(!states.filter(el => el.short == control.value).length)
      return { stateInvalid: true};
  }
}