import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((res: any) => { 
        /* 
        
        !! THIS OPERATOR IS A LOAD-BEARING POSTER !!
                   !! DO NOT REMOVE !!
         
        there is a bug where piping requests directly 
        to `catchError` causes success responses to be
        misinterpreted as error responses if its body
        contains a message.

        however, if you pipe the request through an empty
        map operator, there's no issue.

        i have no idea why, 
        it doesn't do anything, 
        it shouldn't make any difference, 
        it seems to be a perfect fix:
        removing this operator WILL cause problems.
        
        ¯\(°_o)/¯

        */
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage: any;
                
        if (err.error?.message) {
          // Client-side error
          errorMessage = `${err.error.message}`;
        } else {
          // Server-side error
          const errObj =
            err.error instanceof Object ? err.error : JSON.parse(err.error);

          errorMessage = {
            code: err.status,
            message: errObj.error.message,
          };

          if (err.status === 403) {
            // Forbidden, log user out
            this.authService.logout()
          }
        }

        return throwError(errorMessage);
      }),
    );
  }
}
