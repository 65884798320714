import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AwsService {

  apiUrl: any;
  constructor(public http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getSignedUploadRequest(name, type): Observable<any> {
    return this.http.get(`${this.apiUrl}/aws/sign?file-name=${name}&file-type=${type}`);
  }

  getFileList(): Observable<Array<any>> {
    return this.http.get(`${this.apiUrl}/aws/files`)
      .pipe(map(res => {
        return res['Contents'].map(val => val.Key);
      }));
  }

  getSignedFileRequest(name): Observable<any> {
    return this.http.get(`${this.apiUrl}/aws/files/${name}`,{ responseType: 'text' });//.map(res => res.json());
  }

  deleteFile(name) {
    return this.http.delete(`${this.apiUrl}/aws/files/${name}`);//.map(res => res.json());
  }

  // https://www.thepolyglotdeveloper.com/2015/03/create-a-random-nonce-string-using-javascript/
  randomString = function (length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  uploadFile(url, file):Observable<any> {
    return this.http.put(url, file);
  }

}