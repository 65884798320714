import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { Validators } from '@angular/forms';
import { HttpService, ModalService } from 'app/services';
import { takeUntil, catchError,  } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-scheduled-report-modal',
  templateUrl: './scheduled-report-modal.component.html',
  styleUrls: ['./scheduled-report-modal.component.scss'],
})
export class ScheduledReportModalComponent extends BaseModal implements OnInit, OnDestroy {
  scheduledReportForm: any = new FormGroup({
    enableScheduledReports: new FormControl(''),
    device_alert_report: new FormControl(''),
    deployment_summary_report: new FormControl(''),
    interval: new FormControl('', [Validators.required]),
    begin_date: new FormControl('', [Validators.required]),
    users: new FormControl('', [Validators.required]),
  });
  scheduledReports: any = {};
  minDate: Date;
  users: any = [];
  updateMinDate: any = () => { 
    this.minDate = new Date(Date.now() + 900000); 
    this.minDate.setMinutes(Math.floor(this.minDate.getMinutes() / 15) * 15,0,0); 
    setTimeout(this.updateMinDate, (this.minDate.getTime() - Date.now() - 3000));
  };
  private _unsubscribeAll: Subject<any>;

  constructor(
    private http: HttpService,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    protected dialogRef: MatDialogRef<ScheduledReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._unsubscribeAll = new Subject();
    this.updateMinDate();
  }

  initializeForm(){
    this.scheduledReportForm.reset(this.scheduledReports);
    this.scheduledReportForm.get('enableScheduledReports').setValue(this.scheduledReports.device_alert_report || this.scheduledReports.deployment_summary_report);
    this.scheduledReportForm.get('device_alert_report').setValue(!!this.scheduledReports.device_alert_report);
    this.scheduledReportForm.get('deployment_summary_report').setValue(!!this.scheduledReports.deployment_summary_report);
    this.scheduledReportForm.get('interval').setValue(`${this.scheduledReports.interval}`);
    this.scheduledReportForm.get('begin_date').setValue(new Date(this.minDate));
    this.scheduledReportForm.get('users').setValue(this.scheduledReports.users)
  }

  getScheduledReports(): void {
    this.http.getBusinessScheduledReports().pipe(
      takeUntil(this._unsubscribeAll),
      catchError(this.modalService.errorDialog)
    ).subscribe(res => {
      this.scheduledReports = res?.length ? res[0] : {
        interval: '7',
        begin_date: this.minDate,
        enableScheduledReports: false,
      };
      this.initializeForm();
    });
  }

  getUsers(): void {
    this.http.getBusinessUsers().pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
    ).subscribe(res => {
      !res || (this.users = res);
      this.users.forEach(el => el.label = `${el.last_name}, ${el.first_name} (${el.email})`);
      this.users.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
    });
  }

  openDatetimePicker(): void {
    setTimeout(() => document.querySelector('ngx-mat-timepicker').querySelectorAll('input').forEach(el => el.setAttribute('readonly', 'true'), 100));
  }

  saveDisabled(): boolean {
    return this.scheduledReportForm.dirty && !this.scheduledReportForm.value.enableScheduledReports ? 
        (this.scheduledReportForm.value.enableScheduledReports == this.scheduledReports.enableScheduledReports) :
          !this.scheduledReportForm.dirty || 
          !this.scheduledReportForm.valid || 
          !(
            this.scheduledReportForm.value.device_alert_report || 
            this.scheduledReportForm.value.deployment_summary_report
          );
  }

  ngOnInit(): void {
    this.getScheduledReports();
    this.getUsers();
    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  
  updateScheduledReports(): void {
    let body = {
      ...this.scheduledReportForm.value, 
      ...(
        !this.scheduledReportForm.value.enableScheduledReports ? 
        {device_alert_report: false, deployment_summary_report: false} :
        {}
      ),
      interval: parseInt(this.scheduledReportForm.value.interval), 
      begin_date: this.scheduledReportForm.value.begin_date.getTime()
    };
    delete body.enableScheduledReports;
    body.next_report = 0;
    this.http[`${!this.scheduledReports.id ? 'create' : 'update'}ScheduledReports`](body, this.scheduledReports.id)
    .pipe(
      takeUntil(this._unsubscribeAll),
      catchError(this.modalService.errorDialog)
    ).subscribe(res => {
      this.snackbar.open('Scheduled report configuration saved', 'OK', {duration: 2000});
      this.dialogRef.close();
    });   
  }
}
