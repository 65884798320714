<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.CONFIRM' | translate }}</h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
    <div [innerText]="data.text"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.NO' | translate }}
    </button>
    <button mat-button (click)="sendConfirm()">
      {{ 'MODAL.ACTIONS.YES' | translate }}
    </button>
  </mat-dialog-actions>
</div>
