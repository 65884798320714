<div>
  <div>
    <h2 mat-dialog-title>Edit user</h2>
    <mat-dialog-content
      fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
    >
      <form [formGroup]="userForm">
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required />
          <mat-error
            *ngIf="
              userForm.get('email').errors?.required ||
              userForm.get('email').errors?.emailInvalid
            "
          >
            Enter a valid email address
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="12px">
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="first_name" required />
            <mat-error *ngIf="userForm.get('first_name').errors?.required">
              First name required
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="w-100-p">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="last_name" required />
            <mat-error *ngIf="userForm.get('last_name').errors?.required">
              Last name required
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Phone Number</mat-label>
          <input
            matInput
            type="tel "
            formControlName="phone"
            mask="(000) 000-0000"
            required
          />
          <mat-error *ngIf="userForm.get('phone').errors">
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-100-p">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role" required>
            <mat-option *ngFor="let role of rolesList" [value]="role.name" [disabled]="role.name == 'user' && user.id == currentUserId">
              {{ role.display_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.get('role').invalid">
            Role is required
          </mat-error>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mt-12">
      <button mat-button mat-dialog-close>
        Cancel
      </button>
      <button mat-button [disabled]="userForm.invalid || !userForm.dirty" (click)="save()">
        Save
      </button>
    </mat-dialog-actions>
  </div>
</div>
