import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, HttpService, ModalService } from 'app/services';
import { Subject, throwError } from 'rxjs';
import { catchError, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { emailValidator } from 'validators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { states } from 'assets/states';
import { zipValidator, statesValidator } from 'validators';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  displayedColumns: string[] = ['phone', 'email', 'description', 'text_alerts', 'email_alerts', 'assigned_units', 'unassigned_units', 'controls'];
  expandedElement: any;
  business: any = {};
  user: any = {};
  notiPts: any[] = [];
  users: any = [];
  streamlineMode: boolean = false;
  viewGlobalDevices: boolean = false;
  allowTechSubs: boolean = false;

  urlParams: any = new URLSearchParams(window.location.search);
  setPath: any = (path) => window.history.replaceState({}, window.document.title, (window.location.origin + window.location.pathname + `?tab=${path}`));
  testPath: any = (path, init?) => this.urlParams.get('tab') == path || (init ? !this.urlParams.get('tab') : false);

  loadStatus: any = {};
  submitting: boolean = false;
  complete: boolean = false;

  minDate: Date;
  updateMinDate: any = () => {
    this.minDate = new Date(Date.now() + 900000);
    this.minDate.setMinutes(Math.floor(this.minDate.getMinutes() / 15) * 15, 0, 0);
    setTimeout(this.updateMinDate, (this.minDate.getTime() - Date.now() - 3000));
  };

  forms: any = [];
  businessForm: any = new FormGroup({
    name: new FormControl('', [Validators.required]),
    address_1: new FormControl('', [Validators.required]),
    address_2: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required, statesValidator()]),
    zip: new FormControl('', [Validators.required, zipValidator()]),
    phone: new FormControl(''),
    email: new FormControl('', [emailValidator()]),
  });

  userForm: any = new FormGroup({
    email: new FormControl('', [Validators.required, emailValidator()]),
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    sms2FA: new FormControl(''),
  });

  notiValidity = {
    phone: <any>true,
    email: <any>true,
    valid: <any>true,
    reassess: <any>false,
  };
  activeRow: boolean;
  previousData: any;
  states = states;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private authService: AuthService,
    private modalService: ModalService,
    private snackbar: MatSnackBar
  ) {
    this.updateMinDate();
    this._fuseTranslationLoaderService.loadTranslations(english);
    this.businessForm.initialize = (() => {
      this.businessForm.reset(this.business);
      this.businessForm.get('name').setValue(this.business.name ?? '');
      this.businessForm.get('address_1').setValue(this.business.address_1 ?? '');
      this.businessForm.get('zip').setValue(this.business.zip ?? '');
      this.businessForm.get('city').setValue(this.business.city ?? '');
      this.businessForm.get('address_1').setValue(this.business.address_1 ?? '');
      this.businessForm.get('state').setValue(states.find(el => this.business.state.toLowerCase() == el.long.toLowerCase() || this.business.state.toLowerCase() == el.short.toLowerCase())?.short ?? ''), this.businessForm.get('state').valid || this.businessForm.get('state').setValue('invalid');
      this.businessForm.get('address_2').setValue(this.business.address_2 ?? '');
      this.businessForm.get('phone').setValue(this.business.phone ?? '');
      this.businessForm.get('email').setValue(this.business.email ?? '');
    }).bind(this);

    this.userForm.initialize = (() => {
      this.userForm.reset(this.user);
      this.userForm.get('email', this.user.email);
      this.userForm.get('first_name', this.user.first_name);
      this.userForm.get('last_name', this.user.last_name);
      this.userForm.get('phone', this.user.phone);
      this.userForm.get('sms2FA', this.user.sms2FA);
      if (this.user.sms2FA) this.userForm.get('phone').disable();
    }).bind(this);

    this.forms = [this.businessForm, this.userForm], this.forms.initialize = () => this.forms.forEach(el => el.initialize());
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getBusiness();
    this.getNotificationPoints();
    this.getUsers();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateLoadingState(): void {
    let keys = Object.keys(this.loadStatus);
    for (let i in keys)
      if (this.loadStatus[keys[i]] > 0)
        return;
    this.forms.initialize();
    this.complete = true;
  }

  error(err: any): any {
    this.submitting = false
    this.snackbar.open('An error has occured. Please try again.', 'OK', { duration: 2000 })
    return throwError(err);
  }

  getBusiness(): void {
    this.loadStatus.business = 1;
    this.authService.currentBusiness
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile(bus => bus == null),
      ).subscribe(res => {
        !res || (this.business = res, this.streamlineMode = !!res.streamline_mode, this.viewGlobalDevices = !!res.view_global_devices, this.allowTechSubs = !!res.allow_tech_subs);
        this.loadStatus.business--;
        this.updateLoadingState();
      });
  }

  getNotificationPoints(): void {
    this.loadStatus.notiPts = 1;
    this.httpService.getBusinessNotificationPoints({ where: { bus_sub_acc_id: null, bus_sub_acc_loc_id: null } }).pipe(
      takeUntil(this._unsubscribeAll),
      catchError(err => this.error(err))
    ).subscribe(res => {
      !res || (this.initNotiPts(res));
      this.loadStatus.notiPts--;
      this.updateLoadingState();
    });
  }

  getUsers(): void {
    this.loadStatus.users = 1;
    this.httpService.getBusinessUsers().pipe(
      takeUntil(this._unsubscribeAll),
      catchError(err => this.error(err))
    ).subscribe(res => {
      !res || (this.users = res);
      this.users.forEach(el => el.label = `${el.last_name}, ${el.first_name} (${el.email})`);
      this.users.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
      this.user = this.users.find(el => el.id == this.authService.getCurrentUser().id);
      this.loadStatus.users--;
      this.updateLoadingState();
    });
  }

  updateBusiness(data: any, msg: string): void {
    this.submitting = true;
    this.httpService.updateBusiness({ ...(data || this.businessForm.value) })
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(err => this.error(err))
      ).subscribe(() => {
        this.snackbar.open(msg, 'OK', { duration: 2000 })
        this.authService.refreshToken()
        .pipe(
          takeUntil(this._unsubscribeAll),
          catchError(err => this.error(err))
        ).subscribe(() => {
          this.business = { ...this.business, ...this.businessForm.value };
          this.businessForm.initialize();
          this.submitting = false;
        });
      });
  }


  updateUser(changeCurrentEmailConfirmed?: boolean): void {
    if (!changeCurrentEmailConfirmed && this.userForm.value.email !== this.authService.currentUser['_value'].email) {
      this.modalService
        .showConfirmModal(`You are attempting to change the email address that you are logged in under. Doing so will cause you to be forcibly logged out. Do you wish to continue?`)
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(res => !res || this.updateUser(true));
    } else {
      let body = {
        ...{
          ...this.userForm.value,
          sms2FA: undefined,
        },
        ...(this.user.email != this.userForm.value.email ?
          {} : { email: undefined }
        ),
        ...(this.user.phone != this.userForm.get('phone').value && this.userForm.value.sms2FA == this.user.sms2FA ?
          {} : { phone: undefined }
        )
      };

      let error = err => {
        this.modalService.showModal('Error', `${err}: Please try again.`);
        return throwError(err);
      };

      const updateUser = (id, body) => {
        this.httpService.updateUser(id, body).pipe(
          takeUntil(this._unsubscribeAll),
          catchError(err => { return error(err); })
        ).subscribe(() => {
          this.user = { ...this.user, ...this.userForm.value, phone: this.userForm.get('phone').value };
          this.userForm.reset(this.user);
          this.snackbar.open('Profile details saved', 'OK', { duration: 2000 });
        });
      }
      let phone = this.userForm.get('phone').value !== this.user.phone ? this.userForm.get('phone').value : undefined;

      if (this.userForm.value.sms2FA !== this.user.sms2FA)
        this.httpService.updateUser2FA(this.user, this.userForm.value.sms2FA, true, undefined, phone).pipe(
          takeUntil(this._unsubscribeAll),
          catchError(error)
        ).subscribe(() => {
          if (this.userForm.value.sms2FA)
            this.modalService.showInputModal(
              "Verification required",
              "We've sent a verification code to the number provided. To enable two-factor authentication, enter the code below:",
              true
            ).afterClosed()
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(res => {
                if (res === false)
                  this.snackbar.open('Verification cancelled', 'OK', { duration: 2000 });
                else
                  this.httpService.updateUser2FA(this.user, this.userForm.value.sms2FA, false, res, phone).pipe(
                    takeUntil(this._unsubscribeAll),
                    catchError(error)
                  ).subscribe(() => {
                    if (phone)
                      this.user.phone = phone;
                    this.snackbar.open('Verification successful. Two-factor authentication enabled', 'OK', { duration: 2000 });
                    updateUser(this.user.id, body);
                  })
              })
          else
            updateUser(this.user.id, body);
        });
      else
        updateUser(this.user.id, body);
    }
  }


  saveNotificationPoint(data: any, id?: string) {
    let saveData = { ...data };
    delete saveData.tempId;
    delete saveData.edit;
    this.httpService[`${!data.id ? 'create' : 'update'}NotificationPoint`](saveData, id).pipe(
      takeUntil(this._unsubscribeAll),
      catchError(err => this.error(err))
    ).subscribe(res => {
      !res.id || (data.id = res.id);
      this.initNotiPts();
      this.snackbar.open('Notification point saved', 'OK', { duration: 2000 })
    });
  }

  addNotificationPoint() {
    this.activeRow = true;
    let newRow = {
      edit: true,
      tempId: Math.floor(Math.random() * 100000),
      assigned_units: false,
      unassigned_units: false,
      phone: '',
      email: '',
      email_alerts: false,
      text_alerts: false,
      description: ''
    };
    this.initNotiPts([...this.notiPts, newRow]);
    setTimeout(() => (<HTMLInputElement>document.getElementById(`phone_${newRow.tempId}`)).focus(), 100);
  }

  deleteNotificationPoint(data: any) {
    if (data.id)
      this.httpService.deleteNotificationPoint(data.id).pipe(
        takeUntil(this._unsubscribeAll),
        catchError(err => this.error(err))
      ).subscribe(res => {
        this.snackbar.open('Notification point deleted', 'OK', { duration: 2000 })
      });
    if (this.notiPts.length === 1)
      this.notiPts = [];
    else
      this.notiPts.splice(this.notiPts.findIndex(el => data.tempId ? data.tempId == el.tempId : data.id == el.id), 1);
    this.initNotiPts(); //this.notiPts = [...this.notiPts]; //truly maddening
  }

  toggleEdit(row: any, cancel?: boolean) {
    this.previousData = { ...row };
    !row.edit || (cancel || this.saveNotificationPoint(row, row.id));
    row.edit = !row.edit;
    this.activeRow = row.edit;
    if (row.edit)
      setTimeout(() => (<HTMLInputElement>document.getElementById(`phone_${row.tempId || row.id}`)).focus(), 100);
  }

  cancelNotificationPointEdit(data: any) {
    if (data.id) {
      data.assigned_units = this.previousData.assigned_units;
      data.unassigned_units = this.previousData.unassigned_units;
      data.phone = this.previousData.phone;
      data.email = this.previousData.email;
      data.email_alerts = this.previousData.email_alerts;
      data.text_alerts = this.previousData.text_alerts;
      data.description = this.previousData.description;
    } //im not sure why using the spread operator here instead doesn't work? but it doesn't
    data.edit = false;
    this.activeRow = false;
    if (data.tempId)
      this.notiPts.splice(this.notiPts.findIndex(el => data.tempId ? data.tempId == el.tempId : data.id == el.id), 1);
    this.initNotiPts();
  }

  initNotiPts(arr?: any) {
    if (arr)
      this.notiPts = arr;
    this.dataSource = new MatTableDataSource(this.notiPts);
    this.dataSource.sort = this.sort;
  }

  saveDisabled(form: FormGroup): boolean {
    switch (form) {
      case this.businessForm:
        return !form.dirty || !form.valid || this.submitting;
      default:
        return !form.dirty || !form.valid || this.submitting;
    }
  }

  resetDisabled(form: FormGroup): boolean {
    return !form.dirty || this.submitting;
  }

  assessValidity(row?: any) {
    this.validatePhone(row.phone);
    this.validateEmail(row.email);
    if (this.notiValidity.phone == -1 && this.notiValidity.email == -1)
      return this.notiValidity.valid = false;
    if (!this.notiValidity.phone || !this.notiValidity.email)
      return this.notiValidity.valid = false;
    return this.notiValidity.valid = true;
  }

  validatePhone(val: any) {
    let valid = !val ? -1 : val.length == 10 ? true : false;
    this.notiValidity.phone = valid;
    //this.assessValidity();
    return valid;
  }

  validateEmail(val: any) {
    let valid: any = !val ? -1 : (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/).test(val);
    this.notiValidity.email = valid;
    //this.assessValidity();
    return valid;
  }

  toggleStreamline() {
    this.updateBusiness({ streamline_mode: this.streamlineMode }, `Streamline mode ${this.streamlineMode ? 'en' : 'dis'}abled`);
  }

  toggleGlobalDeviceView(e: MouseEvent) {
    let isToggle = (e.target ? (e.target as HTMLElement).className.includes('mat-slide-toggle') : true);
    if (e.target && isToggle) return;

    if (!isToggle) this.viewGlobalDevices = !this.viewGlobalDevices;
    this.updateBusiness({ view_global_devices: this.viewGlobalDevices }, `Global device view ${this.viewGlobalDevices ? 'en' : 'dis'}abled`);
  }

  toggleAllowTechSubs(e: MouseEvent) {
    let isToggle = (e.target ? (e.target as HTMLElement).className.includes('mat-slide-toggle') : true);
    if (e.target && isToggle) return;

    if (!isToggle) this.allowTechSubs = !this.allowTechSubs;
    this.updateBusiness({ allow_tech_subs: this.allowTechSubs }, `Allow technician subscription generation ${this.allowTechSubs ? 'en' : 'dis'}abled`);
  }
}




//////////////  google maps autocomplete stuff //////////////

/*############# settings.component.html ###############
<mat-form-field>
  <mat-label>Address 1</mat-label>
  <input matInput type="text" formControlName="address_1" [options]='options' (onAddressChange)="AddressChange($event, 1)" ngx-google-places-autocomplete />
  <mat-error *ngIf="businessForm.get('address_1').hasError('required')">
    Address 1 is required
  </mat-error>
</mat-form-field>

############### settings.component.ts ###############
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  options={ componentRestrictions: { country:["US"] } };
  initAddr = {formatted_address: ''};
  addr = {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    geocoded: {},
    formatted_addr: '',
  };

  //...

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private modalService: ModalService,
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) {
    //...
  }

  //...

  updateAddress(addr: any){
    let c = addr.address_components;
    this.addr.formatted_addr = addr.formatted_address.replace(', USA', '');
    this.addr.zip = c.find(el => el.types.find(el => el == 'postal_code'))?.short_name || '';
    this.addr.city = c.find(el => el.types.find(el => el == 'administrative_area_level_3')).short_name?.short_name || '';
    this.addr.state = c.find(el => el.types.find(el => el == 'administrative_area_level_1')).short_name?.short_name || '';
    this.addr.address_1 = `${c.find(el => el.types.find(el => el == 'street_number'))?.short_name || ''} ${c.find(el => el.types.find(el => el == 'route'))?.short_name || ''} ${c.find(el => el.types.find(el => el == 'subpremise'))?.short_name || ''}`
    this.addr.geocoded = addr;
  }
  
  public AddressChange(e: any, key: number) {
    key != 1 || this.updateAddress(e);
  }

  getBusiness(): void {
    this.authService.currentBusiness
      .pipe(takeUntil(this._unsubscribeAll), skipWhile((bus) => bus == null))
      .subscribe((business) => {
        this.business = business;
        this.checkLoadingState();
        this.httpService.geocode(business.address_1, business.city, business.state, 'US').pipe(
          takeUntil(this._unsubscribeAll),
          catchError((err) => {
            this.checkLoadingState();
            return throwError(err);
        })
      ).subscribe(res => {
        this.initAddr = res.results[0];
        this.updateAddress(res.results[0]);
        if(business.address_2)
          this.httpService.geocode(business.address_2, business.city, business.state, 'US').pipe(
            takeUntil(this._unsubscribeAll), catchError((err) => throwError(err))
          ).subscribe(res => {
            this.business.geocoded_address2 = res.results[0];
            this.business.formatted_address2 = res.results[0].formatted_address.replace(', USA', '');
            this.checkLoadingState();
          });
        else
          this.checkLoadingState();
      });
  }

  //...

}


############### settings.module.ts ###############
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

//...

@NgModule({
  //...
  imports: [
    GooglePlaceModule
    //...
  ]
  //...
})

//...

#################################################*/