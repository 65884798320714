import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';

import { DeviceListModule } from '../device-list/device-list.module';
import { DeviceMapModule } from '../device-map/device-map.module';
import { MatSelectModule } from '@angular/material/select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DevicesComponent } from './devices.component';
import { MatTabsModule } from '@angular/material/tabs';

const routes: Routes = [
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [DevicesComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatCardModule,
    MatCheckboxModule,
    GoogleMapsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MdePopoverModule,
    DeviceListModule,
    DeviceMapModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    PerfectScrollbarModule,
    MatSnackBarModule,
    MatTabsModule
  ],
  exports: [DevicesComponent],
})
export class DevicesModule {}
