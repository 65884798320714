<div>
    <h2 mat-dialog-title>{{device.name}} ({{device.serial}})</h2>
    <mat-dialog-content fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
        <div fxLayout="row wrap">
            <fuse-widget fxLayout="column" *ngFor="let tile of tiles">
                <div class="fuse-widget-front mat-elevation-z2">
                    <div class="pl-12 pr-5 py-12 h-25" fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="h3">
                            <icon [class]="tile.icon"></icon> 
                            <span>{{ tile.title }}:</span>
                            <span style="font-weight:bold">{{ tile.data }}</span>
                        </div>
                    </div>
                </div>
            </fuse-widget>
        </div>  

        <fuse-widget fxLayout="column" (click)="printQR()" class="qr-widget">
            <div class="fuse-widget-front mat-elevation-z2">
                <div class="pl-12 pr-5 py-12 h-25">
                    <div class="h3">
                        <a><i class="fas fa-print"></i> QR Code:</a>
                        <qrcode [qrdata]="device.serial" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                </div>
            </div>
        </fuse-widget>

        <mat-form-field appearance="fill" subscriptSizing="fixed">
            <label>Device name</label>
            <input matInput [formControl]="deviceName" [(ngModel)]="cur.name" placeholder="Name this device">
            <mat-error *ngIf="deviceName.hasError('required')">Device name required</mat-error>
            <mat-error *ngIf="deviceName.hasError('maxlength') && deviceName.touched"> Device name cannot be more than 50 characters</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="fixed">
            <label>Notes</label>
            <textarea matInput [formControl]="deviceNotes" [(ngModel)]="cur.notes" placeholder="Log additional details">
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="fixed" [color]="phoneInput.hasError('phoneInvalid') ? 'warn' : 'primary'">
            <label [class]="'mat-form-field-label mat-focused ' + (phoneInput.hasError('phoneInvalid') ? 'mat-warn' : '')">Text alerts</label>
            <mat-chip-list [formControl]="phoneChips" #phoneList aria-label="Phone selection">
                <mat-chip
                *ngFor="let phone of cur.phone"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(phone, cur.phone)">
                {{phone}}
                <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                </button>
                </mat-chip>
            </mat-chip-list>
            <input
                matInput
                mask="(000) 000-0000"
                placeholder="Add a phone number"
                [formControl]="phoneInput"
                [matChipInputFor]="phoneList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event, 'phone')">
                <mat-helptext class="mat-form-field-label mat-focused mat-warn chip-err"  *ngIf="phoneInput.hasError('phoneInvalid')">Invalid phone number</mat-helptext>
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="fixed" [color]="emailInput.hasError('emailInvalid') ? 'warn' : 'primary'">
            <label [class]="'mat-form-field-label mat-focused ' + (emailInput.hasError('emailInvalid') ? 'mat-warn' : '')">Email alerts</label>
            <mat-chip-list [formControl]="emailChips" #emailList aria-label="Email selection">
                <mat-chip
                *ngFor="let email of cur.email"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(email, cur.email)">
                {{email}}
                <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                </button>
                </mat-chip>
            </mat-chip-list>
            <input
                matInput
                placeholder="Add an email address"
                [formControl]="emailInput"
                [matChipInputFor]="emailList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event, 'email')">
                <mat-helptext class="mat-form-field-label mat-focused mat-warn chip-err"  *ngIf="emailInput.hasError('emailInvalid')">Invalid email address</mat-helptext>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mt-12">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [disabled]="saveDisabled()" (click)="save()">Save</button>
    </mat-dialog-actions>
  </div>
  