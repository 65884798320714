import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent extends BaseModal implements OnInit, OnDestroy {
  dataType: string;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    protected dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);

    this._fuseTranslationLoaderService.loadTranslations(english);

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.dataType = this.getDataType(this.data.content);
    this.translateTitle();
    this.showDialog();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getDataType(data): string {
    if (!data) {
      return 'null';
    }

    if (Array.isArray(data)) {
      return 'array';
    }

    return typeof data;
  }

  translateTitle(): void {
    if (!this.data?.title || typeof this.data.title !== 'string') {
      return;
    }

    const translateKey = `MODAL.TITLES.${this.data.title.toUpperCase()}`;

    this.translateService
      .get(translateKey)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res !== translateKey) {
          this.data.title = res;
        }
      });
  }
}
