import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from 'app/services';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.authService.currentUser.pipe(
      filter((user) => user != null),
      map((val: any) => {
        const roleIds: string[] = val.roles;

        if (roleIds.indexOf('busadm') === -1) {
          // Billing Admin
          if (state.url === '/dashboard') {
            return this.router.createUrlTree(['/subscriptions']);
          }

          return this.router.createUrlTree(['/error-403']);
        }

        return true;
      }),
    );
  }
}
