import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { HttpService } from '../../../services';

@Component({
  selector: 'app-customize-verification-report-modal',
  templateUrl: './customize-verification-report-modal.component.html',
  styleUrls: ['./customize-verification-report-modal.component.scss']
})
export class CustomizeVerificationReportModalComponent implements OnInit {
  form: FormGroup;
  optionSelect: { customers: Array<{ label: string, value: string }>, locations: Array<{ label: string, value: string }> };

  constructor(
    private httpService: HttpService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CustomizeVerificationReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.optionSelect = { customers: this.data.optionSelect?.customers || [], locations: [] };
    this.form = this.fb.group({
      customerId: [this.data.savedProps?.customerId || this.data.initialProps?.customerId || ''],
      locationId: [this.data.savedProps?.locationId || this.data.initialProps?.locationId || ''],
      beginDate: [this.data.savedProps?.beginDate || this.data.initialProps?.beginDate || null],
      endDate: [this.data.savedProps?.endDate || this.data.initialProps?.endDate || null]
    });

    const initialCustomerId = this.form.get('customerId')?.value;

    if (initialCustomerId) {
      this.loadLocationsForCustomer(initialCustomerId).subscribe(locations => {
        this.optionSelect.locations = locations;
        const initialLocationId = this.form.get('locationId')?.value;
        if (initialLocationId && locations.some(loc => loc.value === initialLocationId)) {
          this.form.get('locationId')?.setValue(initialLocationId);
        } else {
          this.form.get('locationId')?.setValue('');
        }
      });
    }
    this.form.get('customerId')?.valueChanges
      .pipe(
        switchMap(customerId => this.loadLocationsForCustomer(customerId))
      )
      .subscribe(locations => {
        this.optionSelect.locations = locations;
        this.form.get('locationId')?.setValue('');
      });
  }

  private loadLocationsForCustomer(customerId: string) {
    if (!customerId) return of([]);
    return this.httpService.getBusinessAccountLocations(customerId).pipe(
      map((locations: any[]) =>
        locations.map(location => ({
          label: location.name,
          value: location.id
        }))
      )
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onApply(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
