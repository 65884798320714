export const locale = {
  lang: 'en',
  data: {
    MODAL: {
      TITLES: {
        CONFIRM: 'Confirm',
        INFO: 'Info',
        ERROR: 'Error',
        SUCCESS: 'Success',
        CREATE_USER: 'Create User',
        ADD_USERS: 'Add Users',
        ADD_CUSTOMER: 'Add Customer',
        EDIT_CUSTOMER: 'Edit Customer',
        ADD_LOCATION: 'Add Location',
        EDIT_LOCATION: 'Edit Location',
        ASSIGN_DEVICES: 'Assign Devices',
        UNASSIGN_DEVICE: 'Unassign Device',
        PICKUP_DEVICE: 'Pickup Device',
        ADD_DEVICE: 'Add Device',
        ASSIGN_ROLES: 'Assign Roles',
        UNDEPLOYED_DEVICE_DETAILS: 'Device Deployment History',
        DEVICE_LOG: "Device Log",
        TECHNICIAN_TRANSFER: 'Technician Transfer',
        DEPLOYMENT_NOTIFICATIONS: "Notifications",
      },
      ACTIONS: {
        ADD: 'Add',
        ASSIGN: 'Assign',
        UNASSIGN: 'Unassign',
        CANCEL: 'Cancel',
        OK: 'Ok',
        YES: 'Yes',
        NO: 'No',
        VALIDATE: 'Validate',
        CREATE: 'Create',
        EDIT: 'Edit',
        PICKUP: 'Pickup',
        CLOSE: 'Close',
        CONTINUE: 'Continue',
      },
    },
    DEPLOYMENTS: {
      TABLE: {
        HEADER: {
          DATE: 'Date',
          NAME: 'Name',
          RESULT: 'Result',
        },
        FOOTER: {
          EMPTY: 'This device has never been deployed.',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
    DEPLOYMENT_NOTIFICATIONS: {
      TABLE: {
        HEADER: {
          DEVICE_NAME: 'Device name',
          MOST_RECENT_NOTI: 'Most recent',
          TOTAL_NOTI: 'Alerts',
        }
      }
    },
    DEVICE_LOG: {
      TABLE: {
        HEADER: {
          DATE: 'Date',
          BODY: 'Event',
        },
      },
    },
  },
};
