import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, ChargebeeService, HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { from, Subject, throwError } from 'rxjs';
import { catchError, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { prettyPrintDate } from 'assets/formatting';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '400px' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeviceListComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;
  @Output() queryReturn = new EventEmitter<any>();
  query: string = "";
  embedded = !window.location.href.includes("/devices");
  loading = true;;
  businessName: string;
  showMap: boolean;
  showGallery: boolean;
  gallerySrc: string;

  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'name', 'serial', 'gps_label', 'user_full_name', 'last_tech_check', 'prettyLastConnection', 'businessSubscription', 'actions',];
  queryInput = new FormControl('');
  assignedFilter = new FormControl(0);
  expandedElement: any;
  queeblo002: boolean = true;
  animationDelay: boolean = false;
  obs: any;
  disableSubBtn: any = {};
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private router: Router,
    private httpService: HttpService,
    private modalService: ModalService,
    private authService: AuthService,
    private chargebeeService: ChargebeeService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,

  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.authService.currentBusiness
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((bus) => bus == null),
      )
      .subscribe((business) => {
        this.businessName = business.name;
      });
    this.route.queryParams.subscribe(params => {
      this.query = params.query || "";
      this.updateQuery.bind(this)();
    });
    this.fetchRows();
  }

  get matTable() { return document.querySelector('mat-table'); }
  get matHeaderRow() { return document.querySelector('mat-header-row'); }
  get matRows() { return document.querySelectorAll('mat-row'); }
  get overflowContainer() { return document.getElementById('device-list-overflow-container'); }
  get contentContainer() { return document.getElementById('device-list-content-container'); }

  ngOnInit(): void {
    this.obs = new ResizeObserver(this.calculatePageSize.bind(this));
    this.obs.observe(this.contentContainer);
  }

  ngOnDestroy(): void {
    this.obs.unobserve(this.contentContainer)
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  calculatePageSize(): number {
    if (!this.paginator || !this.dataSource?.paginator)
      return;
    if (!this.matTable || !this.matHeaderRow)
      return;

    let pageSize;
    let rowHeight = (this.matRows[0]?.clientHeight || 48);
    if (this.overflowContainer.clientHeight > this.contentContainer.clientHeight) {
      pageSize = this.paginator.pageSize -
        Math.ceil((this.overflowContainer.clientHeight - this.contentContainer.clientHeight) / rowHeight);
      if (pageSize <= 1)
        return;
    } else {
      pageSize = Math.floor(
        (this.matTable?.clientHeight - this.matHeaderRow?.clientHeight) / rowHeight
      );
    }
    if (this.paginator.pageSize == pageSize)
      return;
    this.paginator._changePageSize(pageSize)
  }

  fetchRows(reload?: any): void {
    if (reload === undefined)
      this.loading = true;
    this.selection.clear();

    let sortingDataAccesor = (data: any, sortHeaderId: string): string =>
      typeof data[sortHeaderId] === 'string' ?
        data[sortHeaderId].toLocaleLowerCase() :
        data[sortHeaderId],
      filterPredicate = (el, val) => {
        if (!val.assigned ||
          val.assigned == 0 ||
          (val.assigned == 1 && el.user) ||
          (val.assigned == 2 && !el.user)
        ) {
          if (val.query) {
            for (let i of ['name', 'serial', 'user_full_name', 'last_deployed', 'last_tech_check', 'gps_label'])
              if (el[i] && `${el[i]}`.toLowerCase().indexOf(val.query?.toLowerCase()) >= 0)
                return true;
          } else
            return true;
        }
        return false;
      };

    this.httpService.getAmbiguousBusinessDevices().pipe(
        catchError(this.modalService.errorDialog)
    ).subscribe((res: any) => {  
      res.forEach((el: any) => el.prettyLastConnection = el.lastConnection ? 
        prettyPrintDate(el.lastConnection, {time: true}) : 
        'N/A'
      );
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccesor;
      this.dataSource.filterPredicate = filterPredicate;
      this.applyFilter();
      this.calculatePageSize();
      if (this.embedded)
        this.updateQuery();
      else {
        this.queryInput.valueChanges.subscribe(() => this.applyFilter());
        this.assignedFilter.valueChanges.subscribe(() => this.applyFilter());
      }
      this.loading = false;
    });
  }

  applyFilter(){
    this.dataSource.filter = {
      query: this.queryInput.value,
      assigned: this.assignedFilter.value
    }
  }

  popoverOpened(): void {
    this.showMap = false;
    setTimeout(() => (this.showMap = true), 0);
  }

  //this is just for the global search functionality
  updateQuery(): void {
    if (!this.dataSource)
      return;
    this.dataSource.filter = { query: this.query };
    this.queryReturn.next({ component: 'Devices', data: this.dataSource.filteredData?.length });
  }

  showAddDeviceModal(): void {
    this.modalService
      .showAddDeviceModal()
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        if (res.error)
          this.modalService.errorDialog(res.error);
        else if (res.error === false) {
          this.snackbar.open('Device added successfully', 'OK', { duration: 2000 })
          this.fetchRows(0);
        }
      });
  }

  showAssignDevicesModal(devices: any[]): void {
    this.modalService
      .showAssignDevicesModal(devices)
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((assignDevicesForm) => {
        if (assignDevicesForm) {
          this.assignDevices(assignDevicesForm);
        }
      });
  }

  private assignDevices(assignDevicesForm: any): void {
    this.httpService
      .assignBusinessDevices(assignDevicesForm)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
      ).subscribe((res) => {
        const errors = this.getErrors(res);
        if (errors.length)
          this.modalService.showModal(
            errors.length === assignDevicesForm.deviceSerials?.length
              ? 'Failed With Errors'
              : 'Completed With Errors',
            errors,
          );
        else
          this.snackbar.open('Devices assigned successfully', 'OK', { duration: 2000 })
        this.selection.clear();
        this.fetchRows(0);
      });
  }

  showUnassignDeviceModal(): void {
    this.modalService
      .showConfirmModal(
        `Are you sure you want to unassign the selected ${this.selection.selected.length > 1 ? 'devices' : 'device'
        }?`,
      )
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((confirm) => {
        if (confirm) {
          const deviceSerials = _.map(this.selection.selected, 'serial') as string[];
          this.unassignDevices(deviceSerials);
        }
      });
  }

  private unassignDevices(deviceIds: string[]): void {
    this.httpService
      .unassignBusinessDevices(deviceIds)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
      ).subscribe((res) => {
        const errors = this.getErrors(res);
        if (errors.length)
          this.modalService.showModal(
            errors.length === deviceIds.length
              ? 'Failed With Errors'
              : 'Completed With Errors',
            errors,
          );
        else
          this.snackbar.open('Devices unassigned successfully', 'OK', { duration: 2000 })
        this.selection.clear();
        this.fetchRows(0);
      });
  }

  goToDeviceDetails(device: any): void {
    this.router.navigate(['/devices', device.id]);
  }

  findRowsWithAssignedUser(): boolean {
    return this.selection.selected.findIndex((row) => row.user) !== -1;
  }

  findRowsWithUnassignedUser(): boolean {
    return this.selection.selected.findIndex((row) => !row.user) !== -1;
  }

  async addSubscription(device: any): Promise<void> {
    const planType = this.chargebeeService.getPlanType(device.serial);
    let isSensor = (planType === 'skyhawk-ent-sensors-per-unit');
    this.disableSubBtn[device.serial] = true;

    try {
      let subs = await (isSensor ? this.httpService.getPerUnitSubs() : this.httpService.getSub(device.serial)).toPromise();
      if (subs?.length === 0) {
        let cbWindow = this.chargebeeService.checkout(device.serial, planType);
        let rtn = await cbWindow.open();

        if (!rtn) {
          this.disableSubBtn[device.serial] = false;
          return;
        }
      }

      var rtn: any = await this.httpService.subscribeSerials([device.serial]).toPromise();
      if (!rtn) return;

      this.snackbar.open('Subscription added successfully', 'OK', { duration: 2000 })
    } catch (ex) {
      this.modalService.errorDialog(ex);
      this.disableSubBtn[device.serial] = false;
    }

    this.fetchRows(0);
    this.disableSubBtn[device.serial] = false;
  }

  private getErrors(response: any[]): any[] {
    const errors = response.filter((item) => item.error);

    // Some user operations failed
    errors.forEach((error) => {
      let name = error.deviceId ? this.dataSource.data.find(el => el.id === error.deviceId).name :
        error.deviceSerial ? this.dataSource.data.find(el => el.serial === error.deviceSerial).name : 'Error';

      error.message = `${name}: ${error.message}`;
    });

    return errors;
  }

  showDeploymentHistoryModal(device: any): void {
    this.modalService.showDeploymentHistoryModal(device);
  }

  showDeviceLogModal(device: any): void {
    this.modalService.showDeviceLogModal(device.serial, device.events);
  }

  openGallery(devicePic) {
    this.gallerySrc = devicePic;
    this.showGallery = true;
  }

  getInput(id: string): HTMLInputElement {
    return <HTMLInputElement>document.querySelector(`input[id='${id}'`)
  }

  toggleEdit(row: any) {
    let input;
    !row.edit || (
      window.sessionStorage.setItem(row.id, row.name),
      (input = this.getInput(row.id)),
      (row.name = input.value),
      this.httpService.patchAmbiguousDevice(row, { name: row.name })
        .pipe(
          takeUntil(this._unsubscribeAll),
          catchError((err) => {
            this.modalService.showModal(
              `Error`,
              `Failed to save changes to device ${row.id}`
            );
            row.name = window.sessionStorage.getItem(row.id);
            return throwError(err);
          }),
        ).subscribe(() => 1)
    );
    row.edit = !row.edit;
    !row.edit || setTimeout(() => (
      input = this.getInput(row.id),
      input.focus(),
      input.setSelectionRange(0, input.value.length)
    ), 1);
  }

  deviceDetails(device) {
    this.modalService.showDeviceDetailsModal(device).afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => (console.log(res), (typeof res !== 'object' || this.fetchRows(0))));
  }

  expandElement(event, device) {
    this.expandedElement = this.expandedElement === device ? null : device;
    event.stopPropagation();
    //this.animationDelay = true; 
    setTimeout(() => this.animationDelay = false, 100);
  }
}
