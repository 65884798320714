<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.TECHNICIAN_TRANSFER' | translate}}</h2>
  <mat-dialog-content>

    <div>
      <table>
        <tr>
          <td>
            <span>Current technician(s): </span>
          </td>
          <td>
            <span>{{assignedTechNames}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>New technician:</span>
          </td>
          <td class="select-container-cell">
            <mat-form-field  [floatLabel]="'never'">
              <mat-select [(ngModel)]="newTechnician" required placeholder="Select a technician">
                <mat-option *ngFor="let technician of technicians" [value]="technician.id">{{technician.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </div>

    <div>
      <span>Devices to reassign:</span>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="!$event || toggleAllRows()"
            [checked]="selection.hasValue() && selection.selected.length == dataSource.data.length"
            [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.data.length">
            </mat-checkbox>
          </mat-header-cell>            
          <mat-cell *matCellDef="let device">
            <mat-checkbox (click)="$event.stopPropagation()" 
            (change)="!$event || selection.toggle(device)"
            [checked]="selection.isSelected(device)"> 
            </mat-checkbox>
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="devicename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let device"> {{ device.devicename }} </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button
      mat-button
      (click)="verifyTransfer(newTechnician)"
      [disabled]="!newTechnician || !selection.selected.length"
    >
      {{ 'MODAL.ACTIONS.CONTINUE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
