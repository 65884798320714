import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService, ModalService } from 'app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormErrorStateMatcher } from 'validators';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { passwordsMatch } from 'validators';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent
  implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  matcher = new FormErrorStateMatcher();

  private _unsubscribeAll: Subject<any>;

  constructor(
    private httpService: HttpService,
    private modalService: ModalService,
    private router: Router,
  ) {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmNewPassword: new FormControl(''),
    }, {validators: passwordsMatch});

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    //zzz
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  changePassword(): void {

    this.httpService.changePassword(this.changePasswordForm.value.currentPassword, this.changePasswordForm.value.newPassword)    
    .pipe(
      takeUntil(this._unsubscribeAll),
      catchError((err) => {
        //to all who may be reading --- this is insane, just go with it...
        //the alternatives are even uglier. i promise
        if(err.code == 200){
          this.modalService.showModal(
            'Success',
            'Password changed. Please log in using your new password.',
          );
          this.router.navigate(['/login'])
          return new Observable<any>();
        }
        if(err.message == "Invalid password")
          this.modalService.showModal(
            'Invalid password',
            'Please try again.',
          );
        else
          this.modalService.showModal(
            'Error',
            'An error has occured. Please try again.',
          );

        return throwError(err);
      }),
    )
    .subscribe(() => 0);
  }

  toggleShow(controlName: string): void {
    let el = document.querySelector(`input[formcontrolname="${controlName}"]`);
    el.getAttribute('type') == 'password' ? el.setAttribute('type', 'text') : el.setAttribute('type', 'password');
  }

}
