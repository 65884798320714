<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="changeTab($event)" mat-align-tabs="start">
    <ng-container *ngIf="!streamline">
      <mat-tab label="Deployment Details">
        <loading-component *ngIf="DeploymentDetails.loading" display="absolute"></loading-component>
        <div id="DeploymentDetails" class="report-container" [class.hide]="DeploymentDetails.loading"></div>
      </mat-tab>
      <mat-tab label="Deployment Overview">
        <loading-component *ngIf="DeploymentOverview.loading" display="absolute"></loading-component>
        <div id="DeploymentOverview" class="report-container" [class.hide]="DeploymentOverview.loading"></div>
      </mat-tab>
    </ng-container>
    <mat-tab label="Device Alerts">
      <loading-component *ngIf="DeviceAlerts.loading" display="absolute"></loading-component>
      <div id="DeviceAlerts" class="report-container" [class.hide]="DeviceAlerts.loading"></div>
    </mat-tab>

    <mat-tab label="System Verification Report">
      <loading-component *ngIf="SystemVerificationReport.loading" display="absolute"></loading-component>
      <div id="SystemVerificationReport" class="report-container" [class.hide]="SystemVerificationReport.loading"></div>
    </mat-tab>
  </mat-tab-group>

  <div class="action-container">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Customer actions">
      <mat-icon fontSet="fa" fontIcon="fa-ellipsis-v"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [disabled]="activeReport?.loading || !activeReport?.modalKey" (click)="customizeReport(activeReport)">
        <mat-icon fontSet="fa" fontIcon="fa-filter"></mat-icon>
        <span>Filter</span>
      </button>
      <ng-container *ngIf="activeReport?.id !== 'SystemVerificationReport'">
      <button mat-menu-item [disabled]="activeReport?.loading" (click)="shareReport()">
        <mat-icon fontSet="fa" fontIcon="fa-share-alt"></mat-icon>        
        <span>Share</span>
      </button>
      <button mat-menu-item [disabled]="activeReport.loading || !activeReport?.generateCsv" (click)="exportCsv(activeReport)">
        <mat-icon fontSet="fa" fontIcon="fa-table"></mat-icon>        
        <span>Export CSV</span>
      </button>
      <button mat-menu-item (click)="showScheduledReportModal()">
        <mat-icon fontSet="fa" fontIcon="fa-stopwatch"></mat-icon>
        <span>Scheduled reporting</span>
      </button>
      </ng-container>
    </mat-menu>
  </div>
</div>