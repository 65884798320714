<div style="height: 100%; display: flex; flex-direction: column;">
  <div class="modal-header">
    <h2 mat-dialog-title>{{ location.name }} - Notification Points</h2>  
    <button class="close-btn" mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content
    style="flex-grow:1;"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
        <div class="description">
          All notifications from any devices actively deployed in this location will be delivered to the contacts specified below.
        </div>
        <div class="chip-container">
          <mat-form-field appearance="fill" class="wide-chips">
            <mat-label>Text notifications</mat-label>
            <mat-chip-list #smsList>
              <mat-chip
              *ngFor="let np of smsNotificationPoints"
              [removable]="removable"
              (removed)="removeNotificationPoint(np)"
              >
              {{np.label}}
              <button matChipRemove *ngIf="removable">
                  <mat-icon>cancel</mat-icon>
              </button>
              </mat-chip>
          </mat-chip-list>
        </mat-form-field>

        <mat-select matSelect placeholder="+ Add recipient" [formControl]="smsSelect" [(ngModel)]="smsSelectVal">
          <mat-option value="0" 
            (click)="getSmsInfo()">[New contact]</mat-option>
          <mat-option *ngFor="let user of smsOptions"
            [value]="user" 
            (click)="addNotificationPoint({description: user.fullName, phone: user.phone})">
            {{user.smsLabel}}
          </mat-option>
        </mat-select>
      </div>

      <div class="chip-container">
        <mat-form-field appearance="fill" class="wide-chips">
          <mat-label>Email notifications</mat-label>
          <mat-chip-list #emailList>
            <mat-chip
            *ngFor="let np of emailNotificationPoints"
            [removable]="removable"
            (removed)="removeNotificationPoint(np)"
            >
            {{np.label}}
            <button matChipRemove *ngIf="removable">
                <mat-icon>cancel</mat-icon>
            </button>
            </mat-chip>
        </mat-chip-list>
      </mat-form-field>

      <mat-select matSelect placeholder="+ Add recipient" [formControl]="emailSelect" [(ngModel)]="emailSelectVal">
        <mat-option value="0" 
          (click)="getEmailInfo()">[New contact]</mat-option>
        <mat-option *ngFor="let user of emailOptions"
          [value]="user" 
          (click)="addNotificationPoint({description: user.fullName, email: user.email})">
          {{user.emailLabel}}
        </mat-option>
      </mat-select>
    </div>
  </mat-dialog-content>
</div>
