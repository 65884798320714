<div class="page-layout blank p-24 inner-scroll">
  <div class="row header">
    <button mat-mini-fab color="primary" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="m-0">
      {{locationName}}
    </h2>
  </div>
  <div class="row main-content p28">
    <mat-tab-group (selectedTabChange)="tabChanged($event)"> 
      <mat-tab label="Overview">     
        <div [class]="'map-container'">
        <div #map id="map" style="width:100%;height:100%"></div> 
        </div>
        <div *ngIf="!mapLoaded" [class]="mapLoaded ? 'map-loading' : 'map-loading-spinner-container'"><i class="fas fa-spinner map-loading-spinner"></i></div>
      </mat-tab>
      <mat-tab label="Devices">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <mat-form-field class="search-locations-field">
              <mat-label>{{ 'DETAILS.ACTIONS.FILTER' | translate }}</mat-label>
              <input [formControl]="queryInput" matInput type="text"/>
              <button mat-button *ngIf="queryInput.value" matSuffix mat-icon-button (click)="queryInput.setValue('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-button-toggle-group [formControl]="activeFilter" class="ml-12">
              <mat-button-toggle [value]="true">Active</mat-button-toggle>
              <mat-button-toggle [value]="false">History</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <button mat-raised-button
          color="warn"
          (click)="pickup()"
          [disabled]="!selection.selected.length || loading">
            {{ 'DETAILS.ACTIONS.PICKUP' | translate }}
          </button>
        </div>
        <perfect-scrollbar>
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && selection.selected.length >= dataSource.filteredData.length"
                [indeterminate]="selection.hasValue() && selection.selected.length < dataSource.filteredData.length"
                [disabled]="activeFilter.value !== true || loading">
                </mat-checkbox>
              
              </mat-header-cell>
              <mat-cell *matCellDef="let device">
                <mat-checkbox (click)="$event.stopPropagation()" 
                (change)="$event ? selection.toggle(device) : null"
                [checked]="selection.isSelected(device)"
                [disabled]="device.pickup_time != null || loading"> 
                </mat-checkbox>
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="devicename">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *matCellDef="let device"> {{ device.devicename }} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="device_serial">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
              <mat-cell *matCellDef="let device"> {{ device.device_serial }} </mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="pretty_deploy">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Deploy time </mat-header-cell>
              <mat-cell *matCellDef="let device"> {{ device.pretty_deploy }} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="pretty_pickup">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Pickup time </mat-header-cell>
              <mat-cell *matCellDef="let device"> {{ device.pretty_pickup }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="result_type">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Result </mat-header-cell>
              <mat-cell *matCellDef="let device"> {{ device.result_type }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user_full_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Technician </mat-header-cell>
              <mat-cell *matCellDef="let device"> {{ device.user_full_name }} </mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="loc_img">
              <mat-header-cell *matHeaderCellDef> Photo </mat-header-cell>
              <mat-cell *matCellDef="let device">
                <button mat-icon-button [disabled]="!device.loc_img" (click)="modalService.showGallery(device.loc_img)">
                  <mat-icon fontSet="fa" fontIcon="fa-image"></mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="gps">
              <mat-header-cell *matHeaderCellDef> GPS </mat-header-cell>
              <mat-cell *matCellDef="let device">
                <!-- i imagine you could simplify this into one container by using [disabled]="device.gps.lat === -1000". TODO: do that sometime-->
                <ng-container *ngIf="device.gps.lat === -1000">
                  <button class="action-ico-btn" mat-icon-button disabled>
                    <mat-icon fontSet="fa" fontIcon="fa-map-marker-alt"></mat-icon>
                  </button>        
                </ng-container>
                <ng-container *ngIf="device.gps.lat !== -1000">
                  <button class="action-ico-btn"
                  mat-icon-button 
                  [mdePopoverTriggerFor]="appPopover"
                  mdePopoverTriggerOn="click"
                  (opened)="popoverOpened()">
                    <mat-icon fontSet="fa" fontIcon="fa-map-marker-alt"></mat-icon>
                  </button>
                </ng-container>
                <mde-popover #appPopover="mdePopover"
                [mdePopoverOverlapTrigger]="false"
                [mdePopoverCloseOnClick]="false">
                  <mat-card>
                    <mat-card-content>
                      <google-map *ngIf="showMap" width="100%" height="300px" [center]="device.gps">
                        <map-marker [position]="device.gps"></map-marker>
                      </google-map>
                    </mat-card-content>
                  </mat-card>
                </mde-popover>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="notes">
              <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
              <mat-cell *matCellDef="let device">
                <button mat-icon-button 
                [disabled]="!(device.notes)" 
                (click)="showNotes(device.devicename, device.device_notes, device.deploy_notes, device.pickup_notes)">
                  <mat-icon fontSet="fa" fontIcon="fa-file-alt"></mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
              <mat-cell *matCellDef="let device">
                <button mat-raised-button color="primary" (click)="goToDeviceDetails(device)">
                  View
                </button>
              </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>          
        </perfect-scrollbar>
        <mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>

      </mat-tab>
      <mat-tab label="Assigned Technicians">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          *ngIf="technicianDataSource?.data?.length === 0"
          style="height: 100%;"
        >
          <!-- Assign Technician Button -->
          <button
            mat-raised-button
            color="accent"
            (click)="openAssignTechniciansModal()"
            style="margin-bottom: 16px;"
          >
            <mat-icon style="font-size: 18px; vertical-align: middle;">person_add</mat-icon> ASSIGN
          </button>
          <!-- No Technicians Message -->
          <span>No technicians assigned to this location.</span>
        </div>

        <div
          *ngIf="technicianDataSource?.data?.length > 0"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          style="margin-bottom: 16px;"
        >
          <!-- Search Field -->
          <div fxFlex="70" fxLayoutAlign="start center">
            <mat-form-field class="search-technicians-field" fxFlex="100">
              <mat-label>Search Technicians</mat-label>
              <input [formControl]="technicianQueryInput" matInput type="text" />
              <button
                mat-button
                *ngIf="technicianQueryInput.value"
                matSuffix
                mat-icon-button
                (click)="technicianQueryInput.setValue('')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- Assign Technician Button -->
          <div fxFlex="30" fxLayoutAlign="end center">
            <button
              mat-raised-button
              color="accent"
              (click)="openAssignTechniciansModal()"
            >
              <mat-icon style="font-size: 18px; vertical-align: middle;">person_add</mat-icon> ASSIGN
            </button>
          </div>
        </div>
        <perfect-scrollbar *ngIf="technicianDataSource?.data?.length > 0">
          <mat-table
            [dataSource]="technicianDataSource"
            class="mat-elevation-z8"
            matSort
          >
            <!-- Columns for Assigned Technicians -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Technician Name
              </mat-header-cell>
              <mat-cell *matCellDef="let technician">
                {{ technician.first_name }} {{ technician.last_name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
              </mat-header-cell>
              <mat-cell *matCellDef="let technician">
                {{ technician.email }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone
              </mat-header-cell>
              <mat-cell *matCellDef="let technician">
                {{ technician.phone }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let technician">
                <mat-icon   style="cursor: pointer; font-size: 24px;" (click)="unassignTechnician(technician)"> delete</mat-icon>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="technicianDisplayedColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: technicianDisplayedColumns;"
            ></mat-row>
          </mat-table>
        </perfect-scrollbar>

      </mat-tab>
    </mat-tab-group>
    <div *ngIf="tabIndex === 0" class="action-container" style="display: flex; gap: 10px; align-items: center;">
      <button id="verification-report" mat-raised-button color="accent" (click)="showVerificationReportModal()">
        Verification Report
      </button>
      <button id="toggle-heatmap" mat-raised-button color="accent" (click)="toggleHeatmap()">
        Toggle Heatmap
      </button>
      <button [disabled]="!isHeatMapOn" mat-button mat-icon-button matSuffix (click)="showHeatMapOptionModal()">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
    </div>
</div>