import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import { HttpService } from 'app/services';
import * as _ from 'lodash';
import { ModalService } from 'app/services';
import { takeUntil } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-technician-transfer-modal',
  templateUrl: './technician-transfer-modal.component.html',
  styleUrls: ['./technician-transfer-modal.component.scss'],
})
export class TechnicianTransferModalComponent extends BaseModal implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'devicename'];  
  showMap: boolean;
  technicians: any[];
  devices: any[];
  assignedTechNames: string;
  newTechnician: string;
  callback: Function;
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<TechnicianTransferModalComponent>,
    private http: HttpService,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.dataSource = new MatTableDataSource(data.devices);
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
      typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
    this.selection.select(...this.dataSource.data);
    data.technicians.forEach(el => el.label = `${el.last_name}, ${el.first_name} (${el.email})`);
    data.technicians.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
    this.technicians = data.technicians;
    this.devices = data.devices;
    this.callback = data.callback;
    this.assignedTechNames = data.assignedTechnicians.map(el => `${el.first_name} ${el.last_name} (${el.email})`).sort().join(', ');
  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  verifyTransfer(id: string): void {
    document.querySelector('app-technician-transfer-modal').parentElement.style.display = 'none'; // this is so jank lol
    let tech = this.technicians.filter(el => el.id == id)[0];
    this.modalService
      .showConfirmModal(`All active devices in this deployment will be reassigned to ${tech.first_name} ${tech.last_name}. Continue?`)
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(confirmed => {
        if(confirmed)
          this.reassignDevices();
        else
          this.dialogRef.close();
      });
  }

  reassignDevices(): void {
    let body = {
      deviceSerials: this.selection.selected.map(el => el.device_serial || el.sensor_serial),
      techId: this.newTechnician
    };
    this.http.assignBusinessDevices(body).pipe(
      takeUntil(this._unsubscribeAll),
      catchError((err) => {
        this.modalService.showModal(
          'Error',
          'An error has occured. Please try again.',
        );
        return throwError(err);
      }),
    )
    .subscribe((res) => {
      const errors = this.getErrors(res);

      if (errors.length) {
        this.modalService.showModal(
          errors.length === body.deviceSerials?.length
            ? 'Failed With Errors'
            : 'Completed With Errors',
          errors,
        );
      } else {
        this.snackbar.open('Technician transfer complete', 'OK', {duration: 2000})
        this.callback();
        this.dialogRef.close();
      }
    });
  }



  private getErrors(response: any[]): any[] {
    const errors = response.filter((item) => item.error);
    errors.forEach((error) => {
      let name = error.deviceId ? 
          this.dataSource.data.find(el => el.id === error.deviceId)?.device?.devicename : 
        error.deviceSerial ? 
          this.devices.find(el => el.device_serial === error.deviceSerial)?.device?.devicename : 
        'Error';

      error.message = `${name}: ${error.message}`;
    });
    return errors;
  }

  toggleAllRows() {
    this.dataSource.data.length == this.selection.selected.length ?
      this.selection.clear() : this.selection.select(...this.dataSource.data);
  }


}
