import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService, ModalService, NavigationService } from 'app/services';
import { Subject, throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { AwsService } from 'app/services/aws.service';

interface DeviceTileData {
  title: string;
  editable?: boolean;
  editing?: boolean;
  key?: string;
  icon?: string;
  badge?: any;
  data: any;
}

const hasReedTile = {
  Trap: true,
  Mouse: true,
  Kiwi: true
}

const reedStatuses = {
  0: 'Disconnected',
  1: 'Connected',
  2: 'Events Only'
}

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
})
export class DeviceDetailsComponent implements OnDestroy, OnInit {
  @Input() serial: string;
  @Input() device: any;

  @Output() galleryEvent = new EventEmitter<string>();
  urlParams: any = new URLSearchParams(window.location.search);
  deviceTiles: DeviceTileData[] = [];
  curTime: number = new Date().getTime();
  showGallery: boolean;
  gallerySrc: string;
  imageUrl: string;
  embedded: boolean = !window.location.href.includes('/devices/details');
  loading = true;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private datePipe: DatePipe,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private aws: AwsService,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.serial || (this.serial = (this.urlParams.get('serial') || this.device?.serial));
    if(this.device)
      this.addDeviceTiles().then(() => this.loading = false);
    else
      this.getDevice();
  }

  getDevice(): void {
    //ambiguified (tm) devices are going to simplify so much code around the EP once fully implemented
    this.httpService.getAmbiguousBusinessDevice(this.serial).pipe(
      catchError(err => {throw err})
    ).subscribe(device => {
        this.device = device;
        this.addDeviceTiles().then(() => this.loading = false);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async addDeviceTiles(): Promise<boolean> {
    let img = this.device.devicepic && this.device.devicepic.indexOf('assets/imgs') == -1 ?
      await this.aws.getSignedFileRequest(this.device.devicepic).toPromise() : null;
    return new Promise(res => {

      // device agnostic tiles  ----------------------------------------------------//
       
      !img || this.deviceTiles.push({
        title: 'Image',
        icon: 'n/a',
        data: img
      });

      this.deviceTiles.push({
        key: 'lastActive',
        title: 'Last Active',
        icon: 'fal fa-running',
        data: (this.device.lastActive ? this.datePipe.transform(this.device.lastActive, 'EE M/d, h:mm a') : 'N/A')
      });

      this.deviceTiles.push({
        key: 'notes',
        title: 'Device Notes',
        icon: 'fal fa-comment-lines',
        editable: true,
        data: this.device.notes || "No devices notes",
      });

      this.deviceTiles.push({
        key: 'disarmTime',
        title: 'Pause Timer',
        icon: 'fal fa-pause-circle',
        data: this.getPauseTimer(),
      });

      this.deviceTiles.push({
        title: 'Last Heartbeat',
        icon: `fas heartbeat-icon ${this.getHeartbeat() == 'Good heartbeat' ? 'fa-heart' : 'fa-heart-broken'}`,
        data: this.getHeartbeat(),
      });

      this.deviceTiles.push({
        key: 'events',
        title: 'Device Log',
        icon: 'far fa-history',
        badge: this.getDeviceLog(),
        data: 'View 30 Day Device Log',
      });

      this.deviceTiles.push({
        key: 'segPestTrapData',
        title: 'Deployment History',
        icon: 'fas fa-crosshairs',
        data: 'View Deployment History',
      });

      this.deviceTiles.push({
        title: 'Assigned User',
        icon: 'fal fa-user',
        data: this.device.user_full_name
      });

      !hasReedTile[this.device.type] || this.deviceTiles.push({
        key: 'reedStatus',
        title: 'Reed Status',
        icon: `fal ${!this.device.reedStatus ? 'fa-unlink' : 'fa-link'}`,
        data: reedStatuses[this.device.reedStatus],
      });


      // devoce/sensor exclusive tiles  ----------------------------------------------------//\

      if (this.device.deviceType == 'sensor') {
        this.deviceTiles.push({
          title: 'Sensor RSSI',
          icon: 'fas fa-signal-stream',
          data: this.device.sensr_rssi
        });

        this.deviceTiles.push({
          title: 'Sensor Setting',
          icon: 'fal fa-sliders-h',
          data: this.device.sensr_setting
        });
      } else {
        this.deviceTiles.push({
          title: 'Alert Limiter',
          icon: `fal ${this.device.bsm_dc === 1 ? 'fa-bell-on' : 'fa-bell'}`,
          badge: this.device.st_dtc,
          data: this.getAlertLimiter(),
        });

        !this.device.serial.match(/SHH.*/) || this.deviceTiles.push({
          title: 'Sensitivity',
          icon: 'fal fa-sliders-h',
          data: this.device.issensitivityon ? this.device.sensor_adjust : 'Disabled'
        });
      }
      res(true);
    });
  }

  getPauseTimer(): any {
    const disarmTime = this.device.disarmTime;
    return disarmTime && disarmTime > this.curTime ? (disarmTime - this.curTime) : 0;
  }
  getHeartbeat(): string {
    if (!this.device.power)
      return 'Powered off';
    if (!this.device.lastConnection)
      return 'Waiting for first heartbeat';

    if (!this.device.heartbeat || this.device.heartbeat === '000') return 'Good heartbeat';

    let hbTime = Number(this.device.heartbeat) * 60 * 60 * 1000;

    if (this.serial.substring(0, 3) === 'SHH') {
      hbTime = ((3600000 + (hbTime * 1.1)) + this.device.lastConnection);
    } else { //Sensors
      hbTime = ((32400000 + (hbTime * 1.1)) + this.device.lastConnection); 
    }

    if (hbTime < new Date().getTime()) {
      return 'Missed heartbeat';
    }

    return 'Good heartbeat';
  }

  getDeviceLog(): any {
    if(this.device.events)
      return this.device.events.filter(e => {
        let eventDate: any = new Date(e.created);
        return ((this.curTime - eventDate) / 86400000 <= 30);
      }).length;;

    return 0;
  }

  getAlertLimiter(): string {
    let val = this.device.bsm_dc ? this.device.bsm_delay : this.device.st_delay;
    
    return `${val} Minute${val > 1 ? 's' : ''}`;
  }

  goBack(): void {
    this.navigationService.goBack();
  }
  calculateFontsize(length: number): string {
    return '12px';
    //return ((length || 1) < 30 ? 20 : 20 - Math.ceil(Math.log(length))) + 'px';
  }

  showDeviceLogModal(deviceSerial: string, deviceEvents: any): void {
    this.modalService.showDeviceLogModal(deviceSerial, deviceEvents);
  }

  showDeploymentHistoryModal(): void {
    this.modalService.showDeploymentHistoryModal(this.device);
  }

  openGallery(src){
    this.modalService.showGallery(src);
  }
  
  getInput(key: string): HTMLInputElement {
    return <HTMLInputElement>document.querySelector(`textarea[id='${key}'`)
  }

  setData(key: string, data: string): void {
    window.sessionStorage.setItem(key, this.device[key]);
    this.deviceTiles.find(el => el.key == key).data = data;
    this.device[key] = data;
  }

  toggleEditing(tile: any): void {
    !tile.editing || (
      this.setData(tile.key, this.getInput(tile.key).value),
      this.httpService.patchAmbiguousDevice(this.device, {notes: this.device.notes})
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError((err) => {
          this.modalService.showModal(
            `Error`,
            `Failed to save changes to device ${this.device.id}`
          );
          this.setData(tile.key, window.sessionStorage.getItem(tile.key));
          return throwError(err);
        }),
      ).subscribe(() => 1)
    );

    tile.editing = !tile.editing;

    !tile.editing || setTimeout(() => this.getInput(tile.key)?.focus(), 1);
  }
}
