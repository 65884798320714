import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfoModalComponent } from './info-modal.component';

@NgModule({
  declarations: [InfoModalComponent],
  imports: [
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
  ],
  exports: [InfoModalComponent],
})
export class InfoModalModule {}
