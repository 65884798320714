<div>
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content
      fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
    >
      <span  style="display: flex;width: 100%;">{{ data.message }}</span>
      <div class="inputs-container">
        <ng-container *ngFor="let input of data.inputs">
          <mat-form-field appearance="standard">
            <mat-label *ngIf="input.label">{{input.label}}</mat-label>
            <ng-container *ngIf="input.type !== 'tel'">
              <input
              matInput 
              [placeholder]="input.placeholder" 
              [type]="input.type"
              (input)="changeHandler($event, input.name)"
              /> 
            </ng-container>
            <ng-container *ngIf="input.type == 'tel'">
              <input
              matInput 
              mask="(000) 000-0000"
              [type]="input.type"
              (input)="changeHandler($event, input.name)"
              /> 
            </ng-container>

          </mat-form-field>
        </ng-container>
        <mat-form-field appearance="standard" *ngIf="!data.inputs">
          <input 
          matInput 
          placeholder="000000" 
          maxlength="6" 
          [(ngModel)]="inputVal" 
          /> 
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mt-12">
      <button mat-button mat-dialog-close (click)="cancel()">
        Cancel
      </button>
      <button mat-button [disabled]="submitDisabled" (click)="close()">
        Continue
      </button>
    </mat-dialog-actions>
  </div>
  