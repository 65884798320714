import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { ReportsComponent } from './reports.component';
import { LoadingModule } from '../../general/loading/loading.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CustomizeDevicesReportModalModule } from 'app/main/modals/customize-devices-report-modal/customize-devices-report-modal.module';
import { CustomizeDeploymentDetailsReportModalModule } from 'app/main/modals/customize-deployment-details-report-modal/customize-deployment-details-report-modal.module';

const routes: Routes = [
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [ReportsComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatTabsModule,
    LoadingModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    CustomizeDevicesReportModalModule,
    CustomizeDeploymentDetailsReportModalModule
  ],
  exports: [ReportsComponent],
})
export class ReportsModule {}