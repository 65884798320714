import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsyncWrapperGuard } from 'app/guards';
import { DashboardComponent } from './dashboard.component';
import { DeploymentsModule } from '../deployments/deployments.module';
import { BrowserModule } from '@angular/platform-browser'; //idk why this import needs to be here?
import { CommonModule } from '@angular/common'; //or this one... like they're already in app, yeah? and no other component needs them..
import { DevicesDashboardModule } from '../devices/devices.module';
const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    RouterModule.forChild(routes),
    DeploymentsModule,
    DevicesDashboardModule,
    BrowserModule,
    CommonModule
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
