import { Injectable } from '@angular/core';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  startTrackerWithId(id) {
    gtag('config', id);
  }

  trackView( name: string, binary: string) {
      gtag('event', 'screen_view', {
        screen_name: name,
        app_name: 'Skyhawk',
        app_id: binary,
      })
  }

  trackEvent(category, action, label?, value?) {}
}
