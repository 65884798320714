import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CanComponentDeactivate } from 'app/guards';
import { HttpService } from 'app/services';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { Router } from '@angular/router';
import { AuthService } from 'app/services';

@Component({
  selector: 'app-cb-payment-method',
  templateUrl: './cb-payment-method.component.html',
  styleUrls: ['./cb-payment-method.component.scss'],
})
export class CbPaymentMethodComponent
  implements OnInit, OnDestroy, CanComponentDeactivate {
  hostedPageUrl: SafeResourceUrl;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private auth: AuthService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getHostedPage();  
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(!this.auth.getToken())
      window.location.pathname = "login";
    return this.http.updatePaymentSource().pipe(
      catchError(e => {
        switch(e.code) {
          case 403: 
            this.router.navigateByUrl('error-403');
          case 404: 
            this.router.navigateByUrl('error-404');
          case 500:
            this.router.navigateByUrl('error-500');
          default:
            throw e;    
        }
      }),
      takeUntil(this._unsubscribeAll),
      switchMap(() => of(true)),
    );
  }

  getHostedPage(): void {
    this.http.chargebeePortalToken().pipe(
      catchError(e => {
        switch(e.code) {
          case 403: 
            this.router.navigateByUrl('error-403');
          case 404: 
            this.router.navigateByUrl('error-404');
          case 500:
            this.router.navigateByUrl('error-500');
          default:
            throw e;
        }
      }), takeUntil(this._unsubscribeAll))
    .subscribe((res: any) => {
      this.hostedPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        res.url,
      );
    });
  }
}
