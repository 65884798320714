<div>
  <h2 mat-dialog-title>Customize Device Alert Report</h2>
  <mat-dialog-content fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
    <form [formGroup]="filterForm" fxLayout="column">
      
      <mat-form-field appearance="fill">
        <mat-label>Date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="beginDate" placeholder="Start date">
          <input matEndDate formControlName="endDate" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (opened)="disableActions(true)" (closed)="disableActions(false)"></mat-date-range-picker>
      </mat-form-field>

      <mat-slide-toggle formControlName="hideHubs">
        Hide hub events
      </mat-slide-toggle>
      
      <mat-form-field appearance="standard">
        <mat-label>Technicians</mat-label>
        <mat-select formControlName="users" multiple (openedChange)="disableActions($event)">
          <mat-option class="select-all" (click)="toggleSelectAll('users')" value="select-all">{{ isAllSelected.users ? 'Deselect all' : 'Select all'}}</mat-option>
          <mat-option *ngFor="let el of data.optionSelect.users" [value]="el.value">
            {{ el.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Deployments</mat-label>
        <mat-select formControlName="locations" multiple  (openedChange)="disableActions($event)">
          <mat-option class="select-all" (click)="toggleSelectAll('locations')" value="select-all">{{ isAllSelected.locations ? 'Deselect all' : 'Select all'}}</mat-option>
          <mat-option *ngFor="let el of data.optionSelect.locations" [value]="el.value">
            {{ el.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Devices</mat-label>
        <mat-select formControlName="devices" multiple (openedChange)="disableActions($event)">
          <mat-option class="select-all" (click)="toggleSelectAll('devices')" value="select-all">{{ isAllSelected.devices ? 'Deselect all' : 'Select all'}}</mat-option>
          <mat-option *ngFor="let el of data.optionSelect.devices" [value]="el.value">
            {{ el.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Events</mat-label>
        <mat-select formControlName="events" multiple (openedChange)="disableActions($event)">
          <mat-option value="magnetremoved">
            Magnet removed
          </mat-option>
          <mat-option value="magnetreplaced">
            Magnet replaced
          </mat-option>
          <mat-option value="accel">
            Accel trigger
          </mat-option>
          <mat-option value="pir">
            PIR/IR trigger
          </mat-option>
          <mat-option value="missedheartbeat">
            Missed heartbeat
          </mat-option>
        </mat-select>
      </mat-form-field>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" [class]="'mt-12 ' + (actionsDisabled ? 'hidden' : '')">
    <div style="flex-grow:1">
    <button mat-button (click)="dialogRef.close(true)" [disabled]="actionsDisabled">
      Reset
    </button>
    </div>
    <div>
    <button mat-button mat-dialog-close [disabled]="actionsDisabled">
      Cancel
    </button>
    <button mat-button (click)="dialogRef.close(filterForm.value)" [disabled]="actionsDisabled">
      Continue
    </button>
    </div>  
  </mat-dialog-actions>
</div>
