import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddCustomerLocationModalComponent } from 'app/main/modals/add-customer-location-modal/add-customer-location-modal.component';
import { AddCustomerModalComponent } from 'app/main/modals/add-customer-modal/add-customer-modal.component';
import { AddNewDeviceModalComponent } from 'app/main/modals/add-new-device-modal/add-new-device-modal.component';
import { AddUsersModalComponent } from 'app/main/modals/add-users-modal/add-users-modal.component';
import { AssignDevicesModalComponent } from 'app/main/modals/assign-devices-modal/assign-devices-modal.component';
import { EditUserModalComponent } from 'app/main/modals/edit-user-modal/edit-user-modal.component';
import { ConfirmModalComponent } from 'app/main/modals/confirm-modal/confirm-modal.component';
import { EditCustomerLocationModalComponent } from 'app/main/modals/edit-customer-location-modal/edit-customer-location-modal.component';
import { EditCustomerModalComponent } from 'app/main/modals/edit-customer-modal/edit-customer-modal.component';
import { InfoModalComponent } from 'app/main/modals/info-modal/info-modal.component';
import { PickupSensorModalComponent } from 'app/main/modals/pickup-sensor-modal/pickup-sensor-modal.component';
import { DeploymentHistoryModalComponent } from 'app/main/modals/deployment-history-modal/deployment-history-modal.component';
import { DeploymentNotificationsModalComponent } from 'app/main/modals/deployment-notifications-modal/deployment-notifications-modal.component';
import { DeviceLogModalComponent } from 'app/main/modals/device-log-modal/device-log-modal.component';
import { TechnicianTransferModalComponent } from 'app/main/modals/technician-transfer-modal/technician-transfer-modal.component';
import { CustomizeDevicesReportModalComponent } from 'app/main/modals/customize-devices-report-modal/customize-devices-report-modal.component';
import { CustomizeDeploymentDetailsReportModalComponent } from 'app/main/modals/customize-deployment-details-report-modal/customize-deployment-details-report-modal.component';
import { DeviceDetailsModalComponent } from 'app/main/modals/device-details-modal/device-details-modal.component';
import { InputModalComponent } from 'app/main/modals/input-modal/input-modal.component';
import { LocationNotificationsModalComponent } from 'app/main/modals/location-notifications-modal/location-notifications-modal.component';
import { ScheduledReportModalComponent } from 'app/main/modals/scheduled-report-modal/scheduled-report-modal.component';
import { MissedHeartbeatsModalComponent } from 'app/main/modals/missed-heartbeats-modal/missed-heartbeats-modal.component';
import { MissedHeartbeatsModalModule } from 'app/main/modals/missed-heartbeats-modal/missed-heartbeats-modal.module';
import { HeatMapModalComponent } from 'app/main/modals/heat-map-modal/heat-map-modal.component';
import { throwError } from 'rxjs';
import {
  AssignTechnicianDialogComponent
} from '../main/modals/assign-technician-modal/assign-technician-dialog.component';
import {
  CustomizeVerificationReportModalComponent
} from '../main/modals/customize-verification-report-modal/customize-verification-report-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private matDialog: MatDialog) {}

  showModal(title: string, content?: any): MatDialogRef<InfoModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(InfoModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        title: title,
        content: content
      },
    });
  }

  showConfirmModal(text: string): MatDialogRef<ConfirmModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(ConfirmModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        text: text,
      },
    });
  }

  showInputModal(title: string, message?: string, cancellable?: boolean, inputs?: object[]): MatDialogRef<InputModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(InputModalComponent, {
      minWidth: '15vw',
      data: {
        relativeTo: container,
        title: title,
        message: message,
        cancellable: cancellable,
        inputs: inputs
      },
    });
  }
  showHeatMapOptionModal(): MatDialogRef<HeatMapModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(HeatMapModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
      },
    });
  }
  showTechnicianAssignmentListOfUsersModal(
    component: any,
    config: { width: string, data: any }
  ): MatDialogRef<AssignTechnicianDialogComponent> {
    return this.matDialog.open(component, {
      ...config,
    });
  }

  showAddUsersModal(): MatDialogRef<AddUsersModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(AddUsersModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
      },
    });
  }

  showAddCustomerModal(): MatDialogRef<AddCustomerModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(AddCustomerModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
      },
    });
  }

  showEditCustomerModal(
    customer: any,
  ): MatDialogRef<EditCustomerModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(EditCustomerModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        customer: customer,
      },
    });
  }

  showAddCustomerLocationModal(): MatDialogRef<AddCustomerLocationModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(AddCustomerLocationModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
      },
    });
  }

  showEditCustomerLocationModal(
    location: any,
  ): MatDialogRef<EditCustomerLocationModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(EditCustomerLocationModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        location: location,
      },
    });
  }

  showLocationNotificationsModal(
    location: any,
  ): MatDialogRef<LocationNotificationsModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(LocationNotificationsModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        location: location,
      },
    });
  }

  showAssignDevicesModal(
    devices: any[],
  ): MatDialogRef<AssignDevicesModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(AssignDevicesModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        devices: devices,
      },
    });
  }

  showAddDeviceModal(): MatDialogRef<AddNewDeviceModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(AddNewDeviceModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
      },
    });
  }

  showPickupSensorModal(
    deviceInfo: any,
  ): MatDialogRef<PickupSensorModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(PickupSensorModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        deviceInfo: deviceInfo,
      },
    });
  }

  showDeploymentHistoryModal(device: any): MatDialogRef<DeploymentHistoryModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(DeploymentHistoryModalComponent, {
      minWidth: '35vw',
      minHeight:'60vh',
      data: {
        relativeTo: container,
        device: device,
      },
    });
  }

  showDeploymentNotificationsModal(devices: any[]): MatDialogRef<DeploymentNotificationsModalComponent>{
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(DeploymentNotificationsModalComponent, {
      minWidth: '45vw',
      data: {
        relativeTo: container,
        devices: devices,
      },
    }); 
  }

  showEditUserModal(user: any): MatDialogRef<EditUserModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(EditUserModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        user: user,
      },
    });
  }

  showTechnicianTransferModal(devices: any[], assignedTechnicians: any[], technicians: any[], callback: Function): MatDialogRef<TechnicianTransferModalComponent> {
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(TechnicianTransferModalComponent, {
      minWidth: '30vw',
      data: {
        relativeTo: container,
        devices: devices,
        assignedTechnicians: assignedTechnicians,
        technicians: technicians,
        callback: callback
      },
    });
  }

  showDeviceLogModal(deviceSerial: string, deviceEvents: any): MatDialogRef<DeviceLogModalComponent> {
    // Get the main view container
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(DeviceLogModalComponent, {
      minWidth: '35vw',
      minHeight:'60vh',
      data: {
        relativeTo: container,
        deviceEvents: deviceEvents,
        deviceSerial: deviceSerial //honestly, could just get this from one of the events inside the modal but this seems more
      },
    });
  }
  
  showCustomizeDevicesReportModal(optionSelect, savedProps, initialProps): MatDialogRef<CustomizeDevicesReportModalComponent> {
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(CustomizeDevicesReportModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        optionSelect: optionSelect,
        savedProps: savedProps,
        initialProps: initialProps
      },
    });
  }

  showCustomizeDeploymentDetailsReportModal(optionSelect, savedProps, initialProps): MatDialogRef<CustomizeDeploymentDetailsReportModalComponent> {
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(CustomizeDeploymentDetailsReportModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        optionSelect: optionSelect,
        savedProps: savedProps,
        initialProps: initialProps
      },
    });
  }

  showCustomizeSystemVerificationReportModal(optionSelect, savedProps, initialProps): MatDialogRef<CustomizeVerificationReportModalComponent> {
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(CustomizeVerificationReportModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container,
        optionSelect: optionSelect,
        savedProps: savedProps,
        initialProps: initialProps
      },
    });
  }

  showScheduledReportModal(): MatDialogRef<ScheduledReportModalComponent>{
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(ScheduledReportModalComponent, {
      minWidth: '25vw',
      data: {
        relativeTo: container
      },
    });    
  }

  showDeviceDetailsModal(device: any): MatDialogRef<DeviceDetailsModalComponent>{
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(DeviceDetailsModalComponent, {
      minWidth: '50vw',
      data: {
        device: device,
        relativeTo: container
      },
    });
  }

  showMissedHeartbeatsModal(devices: any): MatDialogRef<MissedHeartbeatsModalComponent>{
    const container = document.getElementsByTagName('content')[0];
    return this.matDialog.open(MissedHeartbeatsModalComponent, {
      minWidth: '50vw',
      data: {
        devices: devices,
        relativeTo: container
      },
    });
  }

  showGallery(src: any){
    //this is so hacky
  }

  errorDialog(err: any, msg?: any): any{
    let str;
    if(!msg)
      msg = err.message || err.msg || err;
    switch(typeof msg){
      case 'string':
        str = msg;
        break;
      case 'object':
        Array.isArray(msg) ?
          msg.join('\r\n') : JSON.stringify(msg);
        break;
      default:
          str = msg ? 
            msg.toString ? msg.toString() : 
              `${msg}` : '[33] Nosferatu does a hefty dance'
    }
    console.error(err);
    this.showModal('Error', `The following error has occurred: \r\n ${str} \r\n Please try again.`);
  }
}