<div id="account-confirm" fxLayout="column">
  <div
    id="account-confirm-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      id="account-confirm-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <div class="logo">
        <mat-icon class="mat-accent s-96">done</mat-icon>
      </div>

      <div class="title">Account request sent!</div>

      <div class="subtitle">
        <p>Your account request has been successfully sent.</p>
        <p>We will be in contact with you shortly.</p>
      </div>

      <div class="message">
        <a class="link" [routerLink]="'/login'">Go back to login page</a>
      </div>
    </div>
  </div>
</div>
