import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { AccountConfirmComponent } from './account-confirm.component';

const routes: Routes = [
  {
    path: 'account-confirm',
    component: AccountConfirmComponent,
  },
];

@NgModule({
  declarations: [AccountConfirmComponent],
  imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule],
})
export class AccountConfirmModule {}
