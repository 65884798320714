import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-verification-report-modal',
  templateUrl: './verification-report-modal.component.html',
  styleUrls: ['./verification-report-modal.component.scss'],
})
export class VerificationReportModalComponent {
  reportForm: FormGroup;
  isLoading: boolean = false;  // Track the loading state

  static lastStartDate: Date | null = null;
  static lastEndDate: Date | null = new Date();

  constructor(
    public dialogRef: MatDialogRef<VerificationReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.reportForm = this.fb.group({
      dateRange: this.fb.group({
        start: [VerificationReportModalComponent.lastStartDate, Validators.required],
        end: [VerificationReportModalComponent.lastEndDate, Validators.required]
      })
    });
  }

  onDownload(): void {
    if (this.reportForm.valid) {
      this.isLoading = true;
      const { start, end } = this.reportForm.get('dateRange')?.value;
      VerificationReportModalComponent.lastStartDate = start;
      VerificationReportModalComponent.lastEndDate = end;
      this.dialogRef.close({ fromDate: start, toDate: end });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
