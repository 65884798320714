import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, HttpService, ModalService } from 'app/services';
import * as _ from 'lodash';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { prettyPrintDate } from 'assets/formatting';

@Component({
  selector: 'app-cb-subscriptions',
  templateUrl: './cb-subscriptions.component.html',
  styleUrls: ['./cb-subscriptions.component.scss'],
})
export class CbSubscriptionsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  queryInput = new FormControl('');
  displayedColumns: string[] = ['select', 'bs_device_serial', 'devicename', 'dateFormatted', 'status'];  
  loading: boolean;
  businessName: string;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private modalService: ModalService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.authService.currentBusiness
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((bus) => bus == null),
      )
      .subscribe(bus => this.businessName = bus.name);
    this.fetchRows();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchRows(reload?: any): void {
    if(reload === undefined)
      this.loading = true;
    this.selection.clear();
    this.httpService
      .getBusinessSubscriptions()
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((subscriptions) =>
          _.each(subscriptions, (sub) => {
            sub.devicename = sub.device?.devicename || sub.sensor?.devicename;
          }),
        ),
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        }),
      )
      .subscribe((subscriptions) => {
        subscriptions.forEach(el => {
          el.status = this.formatSubscriptionStatus(el);
          el.dateFormatted = prettyPrintDate(el.created, {time: true});
        });
        this.dataSource = new MatTableDataSource(subscriptions);
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
          typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
        this.dataSource.filterPredicate = (el, val) => {
          for(let i of ['bs_device_serial', 'devicename', 'status'])
            if(el[i] && `${el[i]}`.toLowerCase().indexOf(`${val}`.toLowerCase()) !== -1)
              return true;
          return false;
        };
        this.dataSource.sort = this.sort;
        this.queryInput.valueChanges.subscribe(() => this.dataSource.filter = this.queryInput.value);

        this.loading = false;
      });
  }

  showCancelSubscriptionModal(): void {
    this.modalService
      .showConfirmModal(
        `Are you sure you want to cancel the selected ${
          this.selection.selected.length > 1 ? 'subscriptions' : 'subscription'
        }?`,
      )
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          // Get device ids
          const deviceIds = this.selection.selected.map(
            (row) => row.bs_device_id || row.bs_sensor_id,
          ) as string[];

          this.cancelSubscriptions(deviceIds);
        }
      });
  }

  private cancelSubscriptions(deviceIds: string[]): void {
    const obs: Observable<any>[] = [];

    for (const deviceId of deviceIds) {
      obs.push(
        this.httpService.cancelBusinessSubscription(deviceId).pipe(
          catchError((err) =>
            of({
              ...err,
              deviceSerial: this.selection.selected.find(
                (row) => (row.bs_device_id || row.bs_sensor_id) === deviceId,
              ).bs_device_serial,
            }),
          ),
        ),
      );
    }

    forkJoin(obs).subscribe((res) => {
      const errors = res
        .filter((ob) => ob != null)
        .map((err) => {
          return { message: `${err.deviceSerial}: ${err.message}` };
        });

      if (errors.length)
        this.modalService.showModal('Error', errors);
      else
        this.snackbar.open('Subscriptions successfully cancelled', 'OK', {duration: 2000})
      this.fetchRows(0);
    });
  }

  showActivateSubscriptionModal(): void {
    this.modalService
      .showConfirmModal(
        `Are you sure you want to reactivate the selected ${
          this.selection.selected.length > 1 ? 'subscriptions' : 'subscription'
        }?`,
      )
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          // Get device ids
          const deviceIds = this.selection.selected.map(
            (row) => row.bs_device_id || row.bs_sensor_id,
          ) as string[];

          this.activateSubscriptions(deviceIds);
        }
      });
  }

  private activateSubscriptions(deviceIds: string[]): void {
    const obs: Observable<any>[] = [];

    for (const deviceId of deviceIds) {
      obs.push(
        this.httpService.activateBusinessSubscription(deviceId).pipe(
          catchError((err) =>
            of({
              ...err,
              deviceSerial: this.selection.selected.find(
                (row) => (row.bs_device_id || row.bs_sensor_id) === deviceId
              ).bs_device_serial,
            }),
          ),
        ),
      );
    }

    forkJoin(obs).subscribe((res) => {
      const errors = res
        .filter((ob) => ob != null)
        .map((err) => {
          return { message: `${err.deviceSerial}: ${err.message}` };
        });

      if (errors.length)
        this.modalService.showModal('Error', errors);
      else
        this.snackbar.open('Subscriptions successfully activated', 'OK', {duration: 2000})

      this.fetchRows(0);
    });
  }

  formatSubscriptionStatus(subInfo: any): string {
    switch (subInfo.bs_status) {
      case 'active':
        return 'Active';
      case 'eot':
        const eot = subInfo.bs_eot;
        if (Date.now() > eot) 
          return 'Cancelled';
        return `Expires ${prettyPrintDate(eot, {time: true})}`;
      case 'cancelled':
        return 'Cancelled'
      default:
        return 'Inactive';
    }
  }

  toggleAllRows() {
    this.dataSource.data.length == this.selection.selected.length ?
      this.selection.clear() : this.selection.select(...this.dataSource.data);
  }



}
