<h2 mat-dialog-title>Heat Map Filter Options</h2>
<div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" style="padding-top: 10px;;">
    <mat-radio-group [(ngModel)]="optionSelection" (ngModelChange)="onGroupChange()" fxLayout="column" fxLayoutAlign="start start"  appearance="standard" class="w-80-p" > 
      <mat-radio-button [checked]="optionSelection === 1" [value]="1" class="mb-12" value="theme-default">Show all</mat-radio-button>
      <mat-radio-button [checked]="optionSelection === 2" [value]="2" class="mb-12" value="theme-yellow-light">Date range</mat-radio-button>
    </mat-radio-group> 
    <div *ngIf="optionSelection === 2">
      <mat-form-field appearance="fill" appearance="standard" class="w-150-p">
        <mat-label>Date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input [(ngModel)]="startDate" matStartDate placeholder="Start date">
          <input [(ngModel)]="endDate" matEndDate placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field> 
    </div>
    <div class="w-100-p">
    </div>
  </div>

  <mat-dialog-actions align="end" [class]="'mt-12'">
      <div>
      <button mat-button mat-dialog-close>
        Cancel
      </button>
      <button [disabled] = "optionSelection === 2 && (!startDate || !endDate)" mat-button (click)="submit()" >
        Continue
      </button>
      </div>  
    </mat-dialog-actions>
   