export function prettyPrintPhone(phone: number): any {
    let segments = `${phone}`.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
    return segments ? `(${segments[1]}) ${segments[2]}-${segments[3]}` : null;
}

interface PrettyPrintDateOptions {
    time?: boolean,
    fs?: boolean
}

export function prettyPrintDate(date?: any, options?: PrettyPrintDateOptions): string {

    if(typeof date == 'object' && !date.getTime)
        (options = date), (date = undefined);

    let opts = typeof date == 'object' && !date.getTime ? date : options, 
        d = !date ? new Date() : typeof date == 'object' ? date : new Date(date),
        str = `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`
    if(opts?.time)
        str += ` ${d.getHours() % 12 || '12'}:${d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()} ${d.getHours() >= 12 ? 'PM' : 'AM'}`;
    if(opts?.fs)
        str = str.replaceAll('/', '-').replaceAll(':', '.');
    
    return str;

}