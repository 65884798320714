export const locale = {
  lang: 'en',
  data: {
    SUBSCRIPTIONS: {
      TITLE: 'Subscriptions',
      ACTIONS: {
        ACTIVATE: 'Reactivate',
        CANCEL: 'Cancel',
        FILTER: 'Filter subscriptions',
      },
      TABLE: {
        HEADER: {
          SERIAL: 'Device Serial',
          NAME: 'Device Name',
          CREATED: 'Created',
          STATUS: 'Subscription Status',
        },
        FOOTER: {
          EMPTY: 'No data to display',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
  },
};
