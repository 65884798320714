export const locale = {
  lang: 'en',
  data: {
    LOCATIONS: {
      TITLE: 'Locations',
      ACTIONS: {
        ADD: 'Add Location',
        EDIT: 'Edit',
        REMOVE: 'Remove',
        FILTER: 'Filter locations',
        VIEW: 'View',
      },
      TABLE: {
        HEADER: {
          LOCATION_NAME: 'Location Name',
          FULL_ADDRESS: 'Address',
          CONTACT_NAME: 'Contact Name',
          CONTACT_EMAIL: 'Contact Email',
          CONTACT_PHONE: 'Contact Phone',
          DEVICE_COUNT: 'Active Devices',
          ACTIONS: 'Actions',
        },
        FOOTER: {
          EMPTY: 'No data to display',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
  },
};
