<div>
  <h2 mat-dialog-title *ngIf="data.title">{{ data.title }}</h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
    <mat-list *ngIf="dataType === 'array'">
      <mat-list-item *ngFor="let item of data.content">{{
        item.message || item
      }}</mat-list-item>
    </mat-list>
    <div *ngIf="dataType === 'string'">{{ data.content }}</div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.OK' | translate }}
    </button>
  </mat-dialog-actions>
</div>
