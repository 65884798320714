import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService, HttpService, ModalService } from 'app/services';
import { Subject } from 'rxjs';
import { catchError, map, skipWhile, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { prettyPrintPhone } from 'assets/formatting';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '400px'})),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomersComponent implements OnDestroy {
  query: string = "";
  @Output() queryReturn = new EventEmitter<any>();
  setPath: any = (path) => window.history.replaceState({}, window.document.title, (window.location.origin + window.location.pathname + (path ? `?expanded=${path}` : '')));
  urlParams: any = new URLSearchParams(window.location.search);
  embedded = !window.location.href.includes("/customers");
  loading = true;;
  businessName: string;

  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['name', 'contact_full_name', 'contact_email', 'pretty_contact_phone', 'segment_name', 'active_devices', 'devices_with_alerts', 'expand', 'actions'];
  queryInput = new FormControl('');
  expandedElement: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpService: HttpService,
    private modalService: ModalService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    this.authService.currentBusiness
    .pipe(
      takeUntil(this._unsubscribeAll),
      skipWhile((bus) => bus == null),
    )
    .subscribe((business) => {
      this.businessName = business.name;
    });
    this.route.queryParams.subscribe(params => {
      this.query = params.query || "";
      this.updateQuery();
    });

    this.fetchRows();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  //this is just for the global search functionality
  updateQuery(): void {
    if(!this.dataSource)
      return;
    this.dataSource.filter = this.query;
    this.queryReturn.next({component: 'Customers', data: this.dataSource.filteredData?.length});
  }

  fetchRows(reload?: any): void {
    if(reload === undefined)
      this.loading = true;
    this.selection.clear();
    this.httpService
      .getBusinessAccsWithDevices()
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(accounts => {
          accounts.forEach((account) => {
            account.contact_full_name = `${account.contact_first_name} ${account.contact_last_name}`;
            account.pretty_contact_phone = prettyPrintPhone(account.contact_phone);
            account.segment_name = account.segment_id == 1 ? 'Pest' : 'Other';
            // Get active devices
          });
          return accounts.filter(el => !el.inactive);
        }),
        catchError(this.modalService.errorDialog)
      )
      .subscribe((accounts: any) => {
        this.dataSource = new MatTableDataSource(accounts);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
          typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
        this.dataSource.filterPredicate = (el, val) => {
          for(let i of ['name', 'ext_cust_id', 'contact_full_name', 'contact_email', 'pretty_contact_phone', 'segment_name', 'active_devices', 'devices_with_alerts'])
            if(el[i] && `${el[i]}`?.toLowerCase().indexOf(val?.toLowerCase()) >= 0)
              return true;
          return false;
        };
        if(this.embedded)
          this.updateQuery();
        else
          this.queryInput.valueChanges.subscribe(() => this.dataSource.filter = this.queryInput.value);
        this.loading = false;
        
        if(this.urlParams.get('expanded'))
          this.setExpandedElement(this.dataSource.data.find(el => el.id == this.urlParams.get('expanded')));

      });
  }

  showAddCustomersModal(): void {
    this.modalService
      .showAddCustomerModal()
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((customer) => {
        if (customer) {
          this.addCustomer(customer);
        }
      });
  }

  private addCustomer(customer: any): void {
    this.httpService
      .addBusinessAccount(customer)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog)
      ).subscribe(() => {
        this.snackbar.open('Customer added successfully', 'OK', {duration: 2000})
        this.fetchRows(0);
      });
  }

  showEditCustomerModal(customer: any): void {
    this.modalService
      .showEditCustomerModal(customer)
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((newCustomer) => {
        if (newCustomer) {
          this.editCustomer(newCustomer);
        }
      });
  }

  private editCustomer(customer: any): void {
    this.httpService
      .editBusinessAccount(customer)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog),
      )
      .subscribe(() => {
        this.snackbar.open('Changes made successfully', 'OK', {duration: 2000})
        this.fetchRows(0);
      });
  }

  showRemoveCustomerModal(customer: any): void {
    let confirmMsg = `Removal of a customer and their location(s) means you will no longer
    be able to view historical data of devices deployed at those locations.
    This can not be undone. Are you sure?`;
    this.modalService.showConfirmModal(confirmMsg).afterClosed().pipe(takeUntil(this._unsubscribeAll))
      .subscribe((confirm) => !confirm || this.removeCustomer(customer));
  }

  private removeCustomer(customer: any): void {
    this.httpService
      .removeBusinessAccount(customer.id)
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError(this.modalService.errorDialog),
      )
      .subscribe(() => {
        this.snackbar.open('Customers removed successfully', 'OK', {duration: 2000})
        this.fetchRows(0);
      });
  }

  setExpandedElement(customer: any){
    this.expandedElement = (this.expandedElement == customer ? null : customer);
    this.setPath(this.expandedElement?.id);
  }
}
