import { Component, Input, Output, EventEmitter} from '@angular/core';
import { AwsService } from 'app/services/aws.service';
import { ModalService } from 'app/services';

let animDur = 100;
@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent {
  @Input() show: boolean;
  @Input() src: any;
  @Output() closeEvent = new EventEmitter<boolean>();
  index: number;
  imageUrls: any; //string[];
  closing: boolean;
  opening: boolean;
  loading: boolean;
  
  constructor(private aws: AwsService, private modal: ModalService){
    this.modal.showGallery = this.showGallery.bind(this);
  }

  showGallery(src: any): void {
    this.imageUrls = [];
    this.index = 0;
    this.loading = true;
    this.opening = true;
    this.show = true;
    this.evaluateSource(src);
    setTimeout(() => this.opening = false, animDur);
  }

  evaluateSource(src: any): void {
    Promise.all(typeof src == 'object' ? 
      src.map(el => this.getUrl(el)) : [ 
        src.match(/https:\/\/.*\..*\/.*\.\.*.*/) ? src : this.getUrl(src) 
      ] 
    ).then(res => {
      this.imageUrls = res;
      this.loading = false;
    });
  }

  getUrl(src: string): Promise<any> {
    if(src.indexOf('assets/imgs') == -1)
      return this.aws.getSignedFileRequest(src).toPromise();
  }

  close(): void {    
    this.closing = true;
    this.show = false;
    setTimeout((() => {
      this.closing = false;
      this.closeEvent.emit(true);
    }),animDur);
    
  }
}
