<div id="change-password" fxLayout="column">
    <div
      id="change-password-form-wrapper"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div
        id="change-password-form"
        [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
      >
        <div class="logo">
          <img src="assets/images/logos/skyhawk-text.png" />
        </div>
  
        <div class="header">
          <div class="title">CHANGE YOUR PASSWORD</div>
  
          <div class="error" *ngIf="changePasswordError" [@animate]="fadeInOut">
            {{ changePasswordError }}
          </div>
        </div>
  
        <form
          name="changePasswordForm"
          [formGroup]="changePasswordForm"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <mat-form-field appearance="outline">
            <mat-label>Current Password</mat-label>
            <input matInput type="password" formControlName="curPassword" />
            <mat-icon matSuffix class="secondary-text" (click)="toggleShow('curPassword')">vpn_key</mat-icon>
            
            <mat-error>Current Password is required</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="newPassword" />
            <mat-icon matSuffix class="secondary-text" (click)="toggleShow('newPassword')">vpn_key</mat-icon>
            <mat-error
              *ngIf="changePasswordForm.get('newPassword').hasError('required')"
              >Password is required</mat-error
            >
            <mat-error
              *ngIf="changePasswordForm.get('newPassword').hasError('minlength')"
            >
              Password must have at least 6 characters
            </mat-error>
            <mat-error  
              *ngIf="
                changePasswordForm
                  .get('newPassword')
                  .hasError('passwordsMatching')
              "
            >
              Password must be different from current password
            </mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Password (Confirm)</mat-label>
            <input
              matInput
              type="password"
              formControlName="newPasswordConfirm"
            />
            <mat-icon matSuffix class="secondary-text" (click)="toggleShow('newPasswordConfirm')">vpn_key</mat-icon>
            <mat-error
              *ngIf="
                changePasswordForm.get('newPasswordConfirm').hasError('required')
              "
            >
              Password confirmation is required
            </mat-error>
            <mat-error
              *ngIf="
                changePasswordForm
                  .get('newPasswordConfirm')
                  .hasError('passwordsNotMatching')
              "
            >
              Passwords must match
            </mat-error>
          </mat-form-field>
  
          <button
            mat-raised-button
            class="submit-button"
            color="accent"
            [disabled]="changePasswordForm.invalid || submitting"
          >
            CHANGE MY PASSWORD
          </button>
        </form>
      </div>
    </div>
  </div>
  