import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { AddNewDeviceModalModule } from 'app/main/modals/add-new-device-modal/add-new-device-modal.module';
import { AddUsersModalModule } from 'app/main/modals/add-users-modal/add-users-modal.module';
import { AssignDevicesModalModule } from 'app/main/modals/assign-devices-modal/assign-devices-modal.module';
import { DeploymentHistoryModalModule  } from 'app/main/modals/deployment-history-modal/deployment-history-modal.module';
import { DeviceListComponent } from './device-list.component';
import { GalleryModule } from '../../general/gallery/gallery.module';
import { DeviceDetailsModule } from '../device-details/device-details.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [DeviceListComponent],
  imports: [
    TranslateModule,
    FuseSharedModule,
    MatCardModule,
    GoogleMapsModule,
    MdePopoverModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    AddUsersModalModule,
    AddNewDeviceModalModule,
    DeploymentHistoryModalModule,
    AssignDevicesModalModule,
    GalleryModule,
    DeviceDetailsModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatMenuModule,
    MatPaginatorModule,
  ],
  exports: [DeviceListComponent],
})
export class DeviceListModule {}
