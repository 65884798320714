import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';


@Component({
  selector: 'app-heat-map-modal',
  templateUrl: './heat-map-modal.component.html',
  styleUrls: ['./heat-map-modal.component.scss']
})
export class HeatMapModalComponent extends BaseModal implements OnInit, OnDestroy {

  optionSelection = 1
  startDate = null;
  endDate = null;

  private _unsubscribeAll: Subject<any>;
  savedValues: any;
  constructor(
    protected dialogRef: MatDialogRef<HeatMapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._unsubscribeAll = new Subject();

    if (window.sessionStorage.getItem('__heatmap_filter_savedValues')) {
      this.savedValues = JSON.parse(window.sessionStorage.getItem('__heatmap_filter_savedValues') || '{}');
      this.optionSelection = this.savedValues.optionSelection;
      this.startDate = this.savedValues.startDate;
      this.endDate = this.savedValues.endDate;
    }
  }

  ngOnInit(): void {
    this.showDialog();
  }
  onGroupChange() {
    if (this.optionSelection === 1) {
      this.startDate = null;
      this.endDate = null;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  reset(): void {
    this.dialogRef.close(false);
  }
  
  submit(): void {
    let data = {optionSelection: this.optionSelection, startDate: this.startDate, endDate: this.endDate};
    window.sessionStorage.setItem('__heatmap_filter_savedValues', JSON.stringify(data));
    this.dialogRef.close(data);
  }
}
