import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { prettyPrintDate } from 'assets/formatting';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { HttpService } from 'app/services';

@Component({
  selector: 'app-deployment-notifications-modal',
  templateUrl: './deployment-notifications-modal.component.html',
  styleUrls: ['./deployment-notifications-modal.component.scss'],
})
export class DeploymentNotificationsModalComponent extends BaseModal implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('perfectScrollBar') perfectScroll: PerfectScrollbarComponent;
  displayedColumns: string[] = ['devicename', 'app_tc', 'body'];
  dataSource: any;
  loading: boolean = true;


  private _unsubscribeAll: Subject<any>;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<DeploymentNotificationsModalComponent>,
    private router: Router,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    let promises = [],
      parsedData = [];
    
    //need to change this in the future but for right now its going to just absolutely hammer on the api
    for(let unit of data.devices){
      if(unit.sensor_serial){
        promises.push(this.http.getSensorHistories({where: {sensor_id: unit.id}, order: ['created DESC'], limit: 1}).toPromise()
          .then(res => parsedData.push({
            serial: unit.sensor_serial,
            devicename: unit.devicename, 
            app_tc: unit.app_tc,
            body: `${res[0]?.body}, ${prettyPrintDate(res[0]?.created, {time: true})}`
          }))
        );
      } else {
        promises.push(this.http.getDeviceEvents({where: {device_id: unit.id}, order: ['created DESC'], limit: 1}).toPromise()
          .then(res => parsedData.push({
            serial: unit.device_serial,
            devicename: unit.devicename, 
            app_tc: unit.app_tc,
            body: `${res[0]?.body}, ${prettyPrintDate(res[0]?.created, {time: true})}`
          }))
        );
      }
    }
    Promise.all(promises).then(() => {
      parsedData.sort((a, b) => a.devicename > b.devicename ? 1 : a.devicename < b.devicename ? -1 : 0);
      this.dataSource = new MatTableDataSource(parsedData);
      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
        typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngAfterViewInit(): void {
    // *** THIS IS A LOAD-BEARING POSTER. ***
    // if you get rid of it perfectscrollbar will get funky, 
    // and perfectscrollbar doesnt need to be funky
    setTimeout(() => {
      this.perfectScroll.directiveRef.update()
      this.perfectScroll.directiveRef.config.suppressScrollY = false;
    }, 300)
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 
  rowClick(serial: string): void {
    this.router.navigate(['/devices', `details`], {queryParams: {serial: serial}});
    this.dialogRef.close();
  }
}
