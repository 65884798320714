import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceLogModalComponent } from './device-log-modal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
@NgModule({
  declarations: [DeviceLogModalComponent],
  imports: [
    MatDialogModule,
    MatListModule,
    TranslateModule,
    FuseSharedModule,
    MatButtonModule,    
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
  ],
  exports: [DeviceLogModalComponent],
})
export class DeviceLogModalModule {}
