import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthService } from 'app/services';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { emailValidator } from 'validators';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  registerError: string;
  submitting: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  onSubmit(): void {
    this.submitting = true;
    this.registerError = null;

    this.authService
      .signupRequest(this.registerForm.value)
      .pipe(
        catchError((err) => {
          this.submitting = false;

          this.registerError = 'An error has occured. Please try again.';

          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.submitting = false;

        this.router.navigate(['/account-confirm']);
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      name: ['', Validators.required],
      business: ['', Validators.required],
      email: ['', [Validators.required, emailValidator()]],
      phone: ['', Validators.required],
      terms: [false, Validators.required],
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
