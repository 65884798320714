import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask'
import { HeatMapModalComponent } from './heat-map-modal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';

import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';


@NgModule({
  declarations: [HeatMapModalComponent],
  imports: [
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatGridListModule,
  ],
  exports: [HeatMapModalComponent]
})
export class HeatMapModalModule {}
