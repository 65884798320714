import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModal } from '../base-modal';

@Component({
  selector: 'app-customize-deployment-details-report-modal',
  templateUrl: './customize-deployment-details-report-modal.component.html',
  styleUrls: ['./customize-deployment-details-report-modal.component.scss'],
})
export class CustomizeDeploymentDetailsReportModalComponent extends BaseModal implements OnInit {
  filterForm: FormGroup;
  actionsDisabled: boolean = false;
  isAllSelected: any = {};
  constructor(
    public dialogRef: MatDialogRef<CustomizeDeploymentDetailsReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this.filterForm = new FormGroup({
      beginDate: new FormControl(),
      endDate: new FormControl(),
      customers: new FormControl(),
      locations: new FormControl(),
      status: new FormControl(),
    });
    this.filterForm.controls.beginDate.setValue(data.savedProps.beginDate);
    this.filterForm.controls.endDate.setValue(data.savedProps.endDate);
    this.filterForm.controls.customers.setValue(data.savedProps.customers);
    this.filterForm.controls.locations.setValue(data.savedProps.locations);
    this.filterForm.controls.status.setValue(data.savedProps.status);  
  }

  ngOnInit(): void {
    this.showDialog();
  }

  disableActions(event?: any): void {
    this.actionsDisabled = event;
  }

  toggleSelectAll(fieldName) {
    let realValues = this.filterForm.controls[fieldName].value?.filter(el => el !== 'select-all');
    this.isAllSelected[fieldName] = !(realValues?.length == this.data.optionSelect[fieldName].length);
    this.filterForm.controls[fieldName].setValue(this.isAllSelected[fieldName] ? this.data.optionSelect[fieldName].map(item => item.value) : []);
  }
}
