import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard, CanDeactivateGuard } from 'app/guards';
import { CbPaymentMethodComponent } from './cb-payment-method.component';

const routes: Routes = [
  {
    path: 'payment-method',
    component: CbPaymentMethodComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  declarations: [CbPaymentMethodComponent],
  imports: [RouterModule.forChild(routes), TranslateModule, FuseSharedModule],
  exports: [CbPaymentMethodComponent],
})
export class CbPaymentMethodModule {}
