<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTab" (selectedTabChange)="setPath($event)">
    <mat-tab label="Unpaid Invoices">
      <iframe
        class="full-page"
        *ngIf="hostedPageUrl"
        [src]="hostedPageUrl"
      ></iframe>
    </mat-tab>
    <mat-tab label="Paid Invoices">
      <div class="full-page background-image">
        <div
          *ngIf="!invoices.length"
          class="page-info"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-icon class="mr-4">info</mat-icon>
          <span class="info-text">You have no paid invoices</span>
        </div>
        <div *ngIf="invoices.length" class="invoice-wrapper" fxLayout="column">
          <ul class="invoice-list">
            <li *ngFor="let invoice of invoices" class="invoice-item">
              <!-- <div class="checkbox-wrapper">
                <mat-checkbox checked="true"></mat-checkbox>
              </div> -->
              <div class="invoice-info">
                <div>Invoice #{{ invoice.id }}</div>
                <div>
                  <strong>{{ invoice.amount_paid / 100 | currency }}</strong>
                </div>
              </div>
              <div class="invoice-details">
                Paid on <strong>{{ invoice.paid_at * 1000 | date }}</strong>
              </div>
              <div
                class="invoice-download"
                (click)="downloadInvoice(invoice.id)"
              >
                Download PDF
              </div>
            </li>
          </ul>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
