<div class="page-layout blank p-24 inner-scroll" fusePerfectScrollbar>
  <loading-component [complete]="!loading" [display]="'absolute'"></loading-component>
  <div fxLayout="row" *ngIf="!embedded">
    <div class="sort-filter">
      <mat-form-field class="search-devices-field">
        <mat-label>Filter deployments</mat-label>
        <input
          matInput
          [(ngModel)]="query"
          (keyup)="updateSearch($event.target.value)"
          type="text"
        />
      </mat-form-field>
      </div>
      <div class="sort-filter"> 
      <mat-form-field>
        <mat-label>Sort</mat-label>
        <mat-select (selectionChange)="sort = $event.value" value="notification">
          <mat-option value="notification">Notifications</mat-option>
          <mat-option value="heartbeat">Missed heartbeats</mat-option>
          <mat-option value="unit">Active units</mat-option>
          <mat-option value="deployment">Name</mat-option>
          <mat-option value="customer">Customer</mat-option>
          <mat-option value="technician"> Technician</mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-button
        matSuffix
        mat-icon-button
        (click)="toggleDescending()"
      >
        <mat-icon fontSet="fa" [fontIcon]="descending ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up'"></mat-icon>
      </button>
    </div>
    <!-- div class="sort-filter">
      <mat-button-toggle-group
      class="ml-12"
      [(ngModel)]="filter"
      (change)="filterTiles()"
    >
      <mat-button-toggle value="active">Active</mat-button-toggle>
      <mat-button-toggle value="inactive">Inactive</mat-button-toggle>
      <mat-button-toggle value="all">All</mat-button-toggle>
    </mat-button-toggle-group>
    </div -->
  </div>
  <div *ngIf="tiles.length == 0 && !loading">
    No active deployments.
  </div>
  <div *ngIf="tiles.length !== 0" fxLayout="row wrap">
    <div *ngFor="let tile of tiles">
      <div *ngIf="tile.containsQuery" class="fuse-card mat-elevation-z2 deployment-tile">
        <div class="tile-content-container">
          <div class="tile-header">
            <div class="tile-title">
              <a (click)="router.navigate(['/customers/' + tile.bus_sub_id + '/locations/' + tile.id + '/details'])" [title]="tile.name">
                {{tile.name}}
              </a>
            </div>
            <button
              class="tile-kebab-menu" 
              mat-icon-button           
              primary
              [matMenuTriggerFor]="menu"
            >
              <mat-icon fontSet="fa" fontIcon="fa-ellipsis-v"></mat-icon>
            </button> 
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="audit(tile.id)">
                <span>Audit</span>
              </button>
            </mat-menu>
          </div>
          <div class="tile-body">
            <div class="indicators-container">
              <button mat-button color="accent" class="indicator notifications" (click)="showDeploymentNotificationsModal(tile.units)" [disabled]="!tile.total_alerts">
                <mat-icon fontSet="fa" fontIcon="fa-bell"></mat-icon>
                {{ tile.total_alerts }}
              </button>
              <button mat-button color="warn" class="indicator missed-heartbeats" (click)="showMissedHeartbeatModal(tile.units)" [disabled]="!tile.missedHeartbeats">
                <mat-icon fontSet="fa" fontIcon="fa-heart-broken"></mat-icon>
                {{ tile.missedHeartbeats }}
              </button>
            </div>
            <div class="divider"></div>
            <table>
              <tr>
                <td style="min-width:100px">Active units</td>
                <td>
                    <button (click)="router.navigate(['/customers/' + tile.bus_sub_id + '/locations/' + tile.id + '/details'])"
                      [disabled]="!tile.units?.length"
                      mat-button
                      primary
                      disapleRipple
                      class="tile-link"
                    >
                      {{ tile.units?.length }}
                    </button>
                  </td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>
                  <button (click)="router.navigate(['/customers', 'locations'], {queryParams: {id: tile.bus_sub_id}})"
                    mat-button 
                    primary 
                    disableRipple 
                    class="tile-link"
                  >
                    {{ tile.customer_name }}
                  </button>
                  </td>
              </tr>
              <tr>
                <td>Address</td>
                <td class="address-cell">
                  <div *ngIf="tile.coords">
                    <div [mdePopoverTriggerFor]="appPopover"
                      mdePopoverTriggerOn="hover"
                      (opened)="popoverOpened()"
                      class="address-container"
                    >
                    <span class="address-label">
                      {{ tile.addressLabel }}
                    </span>
                    <a class="location">
                      <i class="fas fa-map-marker-alt location-marker"></i>
                    </a>
                  </div>
                    <mde-popover
                      #appPopover="mdePopover"
                      [mdePopoverOverlapTrigger]="false"
                      [mdePopoverCloseOnClick]="false"
                      [mdePopoverArrowOffsetX]="8"
                    >
                      <mat-card>
                        <mat-card-content>
                          <div class="map-container">
                            <google-map
                              *ngIf="showMap"
                              width="289px"
                              height="188px"
                              [center]="tile.coords"
                              >
                                <map-marker [position]="tile.coords"></map-marker>
                            </google-map>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mde-popover>
                  </div>
                  <div *ngIf="!tile.coords" class="address-container">
                    <span class="address-label">
                      {{ tile.addressLabel }}
                    </span>
                  </div>
                </td>
              </tr>  
              <tr>
                <td>Technician(s)</td>
                <td>
                  <!-- button (click)="router.navigate(['/users'])" 
                    mat-button 
                    primary 
                    disableRipple 
                    class="tile-link"
                    >
                    TODO: perhaps make a technicians details modal?
                  </button -->
                  <div class="mat-button readonly">{{ tile.techniciansLabel }}</div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>