export const locale = {
  lang: 'en',
  data: {
    NAV: {
      DEPLOYMENTS: {
        TITLE: 'Dashboard',
      },
      DEVICES: {
        TITLE: 'Devices',
      },
      USERS: {
        TITLE: 'Users',
      },
      CUSTOMERS: {
        TITLE: 'Customers',
      },
      BUSINESS_PROFILE: {
        TITLE: 'Business Profile',
      },
      BILLING: {
        TITLE: 'Billing',
      },
      UPDATE_BILLING: {
        TITLE: 'Update Payment',
      },
      INVOICES: {
        TITLE: 'View Invoices',
      },
      SUBSCRIPTIONS: {
        TITLE: 'Manage Subscriptions',
      },
      REPORTS: {
        TITLE: 'Reports'
      },
      DATA: {
        TITLE: 'Data and Analytics'
      },
      EVENTLOG: {
        TITLE: 'Event Log'
      }
    },
  },
};
