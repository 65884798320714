import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpService } from 'app/services';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cb-invoices',
  templateUrl: './cb-invoices.component.html',
  styleUrls: ['./cb-invoices.component.scss'],
})
export class CbInvoicesComponent implements OnInit, OnDestroy {
  setPath: any = (path) => window.history.replaceState({}, window.document.title, (window.location.origin + window.location.pathname + (path?.index ? `?selectedTab=${path.index}` : '')));
  urlParams: any = new URLSearchParams(window.location.search);
  hostedPageUrl: SafeResourceUrl;
  invoices: any[];
  selectedTab: number = 0;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpService,
    private router: Router,
    public sanitizer: DomSanitizer,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english);
    this.invoices = [];
    this._unsubscribeAll = new Subject();
    if(this.urlParams.get('selectedTab'))
      this.selectedTab = 1;
  }

  ngOnInit(): void {
    this.getHostedPage();
    this.getInvoices();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getHostedPage(): void {
    this.http
      .chargebeePastDue()
      .pipe(
        catchError(e => {
          switch(e.code) {
            case 403: 
              this.router.navigateByUrl('error-403');
            case 404: 
              this.router.navigateByUrl('error-404');
            case 500:
              this.router.navigateByUrl('error-500');
            default:
              throw e;
          }
        }),
        takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.hostedPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          res.url,
        );
      });
  }

  getInvoices(): void {
    this.http
      .getInvoices()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.invoices = res;
      });
  }

  downloadInvoice(invoiceId: string): void {
    this.http
      .getInvoicePDF(invoiceId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        // TODO: Renaming the file does not work due to CORS issues with Chargbee
        saveAs(res.download_url, `Invoice_${invoiceId}.pdf`);
      });
  }
}
