import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeviceMapComponent } from './device-map.component';


@NgModule({
  declarations: [DeviceMapComponent],
  imports: [
    TranslateModule,
    FuseSharedModule,
    MatCardModule,
    MatCheckboxModule,
    GoogleMapsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MdePopoverModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    PerfectScrollbarModule,
    MatSnackBarModule
  ],
  exports: [DeviceMapComponent],
})
export class DeviceMapModule {}
