import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { prettyPrintDate } from 'assets/formatting';

@Component({
  selector: 'app-device-log-modal',
  templateUrl: './device-log-modal.component.html',
  styleUrls: ['./device-log-modal.component.scss'],
})

export class DeviceLogModalComponent extends BaseModal implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  displayedColumns: string[] = ['dateFormatted', 'body'];  
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<DeviceLogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject(); 
    let evs = [...(data.deviceEvents || [])]; //need to initialize a new array here. dont ask
    for(let j of evs)
      j.dateFormatted = prettyPrintDate(j.created, {time: true});
    evs.reverse();
    this.dataSource = new MatTableDataSource(evs);
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
      typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() :  data[sortHeaderId];
    
    // sorting only works in this component if you wrap it in a timeout??
    //https://stackoverflow.com/a/50681271
    setTimeout(() => this.dataSource.sort = this.sort);
    // ¯\(°_o)/¯

  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
