import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { DeploymentsComponent } from './deployments.component';
import { MdePopoverModule } from '@material-extended/mde';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatOptionModule } from '@angular/material/core';
import { TechnicianTransferModalModule } from 'app/main/modals/technician-transfer-modal/technician-transfer-modal.module';
import { DeploymentNotificationsModalModule } from 'app/main/modals/deployment-notifications-modal/deployment-notifications-modal.module';
import { LoadingModule } from '../../general/loading/loading.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [DeploymentsComponent],
  imports: [
    TranslateModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MdePopoverModule,
    GoogleMapsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    TechnicianTransferModalModule,
    DeploymentNotificationsModalModule,
    LoadingModule,
    MatSnackBarModule
  ],
  exports: [DeploymentsComponent],
})
export class DeploymentsModule {}
