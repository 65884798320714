import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { prettyPrintDate } from 'assets/formatting';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-missed-heartbeats-modal',
  templateUrl: './missed-heartbeats-modal.component.html',
  styleUrls: ['./missed-heartbeats-modal.component.scss'],
})
export class MissedHeartbeatsModalComponent extends BaseModal implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('perfectScrollBar') perfectScroll: PerfectScrollbarComponent;
  displayedColumns: string[] = ['devicename', 'serial', 'lastConnection'];
  dataSource: any;
  loading: boolean = true;


  private _unsubscribeAll: Subject<any>;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<MissedHeartbeatsModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this._unsubscribeAll = new Subject();
    let parsedData = data.devices.map(el => {
      return {
        serial: el.device_serial || el.sensor_serial,
        lastConnection: prettyPrintDate(new Date(el.lastConnection || el.sensr_lastConnection), {time: true}),
        devicename: el.devicename,
      }
    }).sort((a, b) => a.devicename > b.devicename ? 1 : a.devicename < b.devicename ? -1 : 0);
    this.dataSource = new MatTableDataSource(parsedData);
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => 
      typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngAfterViewInit(): void {
    // *** THIS IS A LOAD-BEARING POSTER. ***
    // if you get rid of it perfectscrollbar will get funky, 
    // and perfectscrollbar doesnt need to be funky
    setTimeout(() => {
      this.perfectScroll.directiveRef.update()
      this.perfectScroll.directiveRef.config.suppressScrollY = false;
    }, 300)
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 
  rowClick(serial: string): void {
    this.router.navigate(['/devices', `details`], {queryParams: {serial: serial}});
    this.dialogRef.close();
  }
}
