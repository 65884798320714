export const locale = {
  lang: 'en',
  data: {
    SETTINGS: {
      TITLE: 'Settings',
    },
    BUSINESS_PROFILE: {
      TITLE: 'Business Profile',
    },
    NOTIFICATIONS: {
      TITLE: 'Device Notification Preferences',
    },
  },
};
