import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-pickup-sensor-modal',
  templateUrl: './pickup-sensor-modal.component.html',
  styleUrls: ['./pickup-sensor-modal.component.scss'],
})
export class PickupSensorModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  unassignSensorForm: FormGroup;
  deviceInfo: any;
  isCapture: boolean = false;
  pickupTimeString: string;

  private pickupTime: Moment;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    protected dialogRef: MatDialogRef<PickupSensorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
    this._fuseTranslationLoaderService.loadTranslations(english);
    this.deviceInfo = data.deviceInfo;
    this.pickupTime = moment();
    this.pickupTimeString = this.pickupTime.format('M/DD/YY, h:mm A');
    this.unassignSensorForm = new FormGroup({
      results: new FormControl(''),
      notes: new FormControl(''),
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  sendSensorUnassign(): void {
    const unassignSensor = {
      id: this.deviceInfo.id,
      device_serial: this.deviceInfo.device_serial,
      is_capture: this.isCapture,
      result_type: this.unassignSensorForm.get('results').value,
      pickup_time: moment.utc(this.pickupTime).valueOf(),
      pickup_notes: this.unassignSensorForm.get('notes').value,
    };

    this.dialogRef.close(unassignSensor);
  }
}
