<div id="register" fxLayout="column">
  <div
    id="register-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      id="register-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <div class="logo">
        <img src="assets/images/logos/skyhawk-text.png" />
      </div>

      <div class="header">
        <div class="title">REQUEST AN ACCOUNT</div>

        <div class="error" *ngIf="registerError" [@animate]="fadeInOut">
          {{ registerError }}
        </div>
      </div>

      <form
        name="registerForm"
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <mat-form-field appearance="outline">
          <mat-label>Business</mat-label>
          <input matInput formControlName="business" />
          <mat-icon matSuffix class="secondary-text">business_center</mat-icon>
          <mat-error>Business is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <mat-error>Name is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="registerForm.get('email').hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="registerForm.get('email').hasError('emailInvalid')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input
            type="tel"
            matInput
            formControlName="phone"
            mask="(000) 000-0000"
          />
          <mat-icon matSuffix class="secondary-text">phone</mat-icon>
          <mat-error *ngIf="registerForm.get('phone').hasError('required')">
            Phone is required
          </mat-error>
          <mat-error *ngIf="registerForm.get('phone').hasError('mask')">
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>

        <div class="terms" fxLayout="row" fxLayoutAlign="center center">
          <mat-checkbox name="terms" formControlName="terms" required>
            <span>I read and accept</span>
          </mat-checkbox>
          <a href="https://www.skyhawk.ai/terms-and-conditions/" target="_blank"
            >terms and conditions</a
          >
        </div>

        <button
          mat-raised-button
          color="accent"
          class="submit-button"
          [disabled]="registerForm.invalid || submitting"
        >
          SUBMIT
        </button>
      </form>

      <div class="register" fxLayout="column" fxLayoutAlign="center center">
        <span class="text">Already have an account?</span>
        <a class="link" [routerLink]="'/login'">Login</a>
      </div>
    </div>
  </div>
</div>
