import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { AddNewDeviceModalModule } from 'app/main/modals/add-new-device-modal/add-new-device-modal.module';
import { AddUsersModalModule } from 'app/main/modals/add-users-modal/add-users-modal.module';
import { AssignDevicesModalModule } from 'app/main/modals/assign-devices-modal/assign-devices-modal.module';
import { DeploymentHistoryModalModule  } from 'app/main/modals/deployment-history-modal/deployment-history-modal.module';
import { DevicesDashboardComponent } from './devices.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingModule } from '../../general/loading/loading.module';

@NgModule({
  declarations: [DevicesDashboardComponent],
  imports: [
    TranslateModule,
    FuseSharedModule,
    MatCardModule,
    GoogleMapsModule,
    MdePopoverModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    AddUsersModalModule,
    AddNewDeviceModalModule,
    DeploymentHistoryModalModule,
    AssignDevicesModalModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatSelectModule,
    LoadingModule

  ],
  exports: [DevicesDashboardComponent],
})
export class DevicesDashboardModule {}
