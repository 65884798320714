<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.ADD_CUSTOMER' | translate }}</h2>
  <mat-dialog-content
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  >
    <form [formGroup]="customerForm" fxLayout="column">
      <mat-form-field appearance="standard">
        <mat-label>Business Name</mat-label>
        <input matInput type="text" formControlName="businessName" required />
        <mat-icon matSuffix>business_center</mat-icon>
        <mat-error *ngIf="customerForm.get('businessName').invalid">
          Business Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Business Type</mat-label>
        <mat-select formControlName="businessType" required>
          <mat-option *ngFor="let type of businessTypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="customerForm.get('businessType').invalid">
          Business Type is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>External Customer Id</mat-label>
        <input matInput type="text" formControlName="extCustId" />
        <mat-icon matSuffix>link</mat-icon>
      </mat-form-field>
      <div fxLayout fxLayoutAlign="space-between">
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>Contact First Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="contactFirstName"
            required
          />
          <mat-error *ngIf="customerForm.get('contactFirstName').invalid">
            Contact First Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-45-p">
          <mat-label>Contact Last Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="contactLastName"
            required
          />
          <mat-error *ngIf="customerForm.get('contactLastName').invalid">
            Contact Last Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="standard">
        <mat-label>Contact Email</mat-label>
        <input matInput type="email" formControlName="contactEmail" />
        <mat-icon matSuffix>email</mat-icon>
        <mat-error *ngIf="customerForm.get('contactEmail').errors">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Contact Phone</mat-label>
        <input
          matInput
          type="tel"
          formControlName="contactPhone"
          mask="(000) 000-0000"
        />
        <mat-icon matSuffix>phone</mat-icon>
        <mat-error *ngIf="customerForm.get('contactPhone').errors">
          Please enter a valid phone number
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button
      mat-button
      (click)="sendCustomer()"
      [disabled]="!customerForm.valid"
    >
      {{ 'MODAL.ACTIONS.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
