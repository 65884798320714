import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeploymentHistoryModalComponent } from './deployment-history-modal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DeploymentHistoryModalComponent],
  imports: [
    MatDialogModule,
    MatListModule,
    TranslateModule,
    FuseSharedModule,
    MatButtonModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    MatIconModule

  ],
  exports: [DeploymentHistoryModalComponent],
})
export class DeploymentHistoryModalModule {}
