import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { HttpService } from '../../../services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-assign-technician-dialog',
  templateUrl: './assign-technician-dialog.component.html',
})
export class AssignTechnicianDialogComponent implements OnInit {
  technicians = [];
  filteredTechnicians = [];
  selectedTechnicians: string[] = [];
  searchControl: FormControl = new FormControl();

  constructor(
    private httpService: HttpService,
    public dialogRef: MatDialogRef<AssignTechnicianDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { locationId: string, assignedTechnicians: any[] },
  ) {
  }

  ngOnInit(): void {
    this.loadTechnicians();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterTechnicians(searchText);
    });
  }

  loadTechnicians(): void {
    this.httpService.getBusinessUsers().subscribe((response: any[]) => {
      // Filter out users who are already assigned
      const assignedTechnicianIds = this.data.assignedTechnicians.map(t => t.id);

      this.technicians = response
        .filter(user => !assignedTechnicianIds.includes(user.id))
        .map(user => ({
          id: user.id,
          name: `${user.first_name} ${user.last_name} (${user.email})`,
        }));

      // Sort the technicians alphabetically
      this.technicians.sort((a, b) => a.name.localeCompare(b.name));

      // Initialize the filtered list with all technicians
      this.filteredTechnicians = this.technicians;
    });
  }

  filterTechnicians(searchText: string): void {
    if (searchText) {
      this.filteredTechnicians = this.technicians.filter(technician =>
        technician.name.toLowerCase().includes(searchText.toLowerCase()),
      );
    } else {
      this.filteredTechnicians = this.technicians;
    }
  }

  onAssignClick(): void {
    this.httpService.assignTechniciansToLocation(this.selectedTechnicians, this.data.locationId)
      .subscribe(response => {
        if (response.success) {
          this.snackBar.open('Technicians assigned successfully!', 'Close', {
            duration: 3000,
          });
          this.dialogRef.close({ success: true });
        } else {
          this.snackBar.open('Failed to assign technicians. Please try again.', 'Close', {
            duration: 3000,
          });
        }
      }, error => {
        this.snackBar.open('Error assigning technicians: ' + error.message, 'Close', {
          duration: 3000,
        });
      });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
