import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDetailsModalComponent } from './device-details-modal.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserModule } from '@angular/platform-browser'; //this is like the goto bandaid LMFAO
import { CommonModule } from '@angular/common'; //and this is like. also that
import { NgxMaskModule } from 'ngx-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FuseWidgetModule } from '@fuse/components';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [DeviceDetailsModalComponent],
  imports: [
    NgxMaskModule.forRoot(),
    FuseSharedModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatChipsModule,
    BrowserModule,
    CommonModule,
    MatFormFieldModule,
    FuseWidgetModule,
    QRCodeModule
  ],
  exports: [DeviceDetailsModalComponent],
})
export class DeviceDetailsModalModule {}
