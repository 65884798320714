<div>
    <h2 mat-dialog-title>Scheduled reports configuration</h2>
    <mat-dialog-content fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
      <form [formGroup]="scheduledReportForm" fxLayout="column">
        <mat-slide-toggle class="scheduled-reports" formControlName="enableScheduledReports">Enable scheduled reports</mat-slide-toggle>
        <mat-checkbox formControlName="device_alert_report" [disabled]="!scheduledReportForm.value.enableScheduledReports">
          Device Alerts report
        </mat-checkbox>

        <!-- ng-container *ngIf="!streamlineMode" -->
          <mat-checkbox formControlName="deployment_summary_report" [disabled]="!scheduledReportForm.value.enableScheduledReports">
            Deployment Overview report
          </mat-checkbox>
        <!-- /ng-container -->
        <mat-form-field>
          <input matInput 
          [ngxMatDatetimePicker]="picker" 
          placeholder="Initial date/time" 
          formControlName="begin_date" 
          [disabled]="!scheduledReportForm.value.enableScheduledReports"
          [min]="minDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker 
          color="primary" 
          [disableMinute]="false" 
          [stepMinute]="15"
          [enableMeridian]="true" 
          [defaultTime]="[minDate.getHours(), minDate.getMinutes(), minDate.getSeconds()]"
          (opened)="openDatetimePicker()">
          </ngx-mat-datetime-picker>
        </mat-form-field>
        
        <mat-form-field appearance="fill">
          <mat-label>Email interval</mat-label>
          <mat-select formControlName="interval" [disabled]="!scheduledReportForm.value.enableScheduledReports" required >
            <mat-option value="1">1 day</mat-option>
            <mat-option value="7">7 days</mat-option>
            <mat-option value="14">14 days</mat-option>
            <mat-option value="30">30 days</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Recipients</mat-label>
          <mat-select formControlName="users" [disabled]="!scheduledReportForm.value.enableScheduledReports" multiple required>
            <mat-option *ngFor="let user of users" [value]="user.id">{{user.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mt-12">
      <div>
        <button mat-button mat-dialog-close>
          Cancel
        </button>
        <button mat-button (click)="updateScheduledReports()" [disabled]="saveDisabled()">
          Save
        </button>
      </div>  
    </mat-dialog-actions>
  </div>