export const locale = {
  lang: 'en',
  data: {
    DEPLOYMENTS: {
      TITLE: 'Dashboard',
      DEVICES: 'DEVICES',
      DEPLOYED_DEVICES: 'Deployed Devices',
      DEVICES_WITH_ALERTS: 'Devices with Alerts',
    },
  },
};
