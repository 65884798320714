<div [class]="'page-layout blank p-12 inner-scroll '">
  <loading-component [complete]="!loading" [style]="embedded ? 'inline-table' : undefined"></loading-component>
  <div *ngIf="!loading" style="display:flex;flex-direction:column;flex-grow:1;">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="m-0">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" [class]="embedded ? '' : 'mb'">
        <ng-container *ngIf="!embedded">
          <button mat-mini-fab color="primary" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <h2 class="m-0">Locations ({{dataSource.data.length}})</h2>
        </ng-container> 
        <ng-container *ngIf="embedded">
          <h4 class="m-0">Locations ({{dataSource.data.length}})</h4>
        </ng-container> 
      </div>
      <div>
        <button
          mat-button
          matSuffix
          mat-icon-button
          (click)="showAddLocationModal()"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-button
          matSuffix
          mat-icon-button
          (click)="showRemoveLocationModal()"
          [disabled]="!selection.selected.length"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <perfect-scrollbar> 
      <mat-table *ngIf="!loading" [dataSource]="dataSource" [class]="'mat-elevation-z8 ' + (embedded ? 'embedded' : 'max')" matSort>

        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let user">
            <mat-checkbox (click)="$event.stopPropagation()" 
            (change)="$event ? selection.toggle(user) : null"
            [checked]="selection.isSelected(user)"> 
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let location"> {{ location.name }} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="ext_loc_cust_id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Ext. ID </mat-header-cell>
          <mat-cell *matCellDef="let location"> {{ location.ext_loc_cust_id }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="full_address">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Address </mat-header-cell>
          <mat-cell *matCellDef="let location"> {{ location.full_address }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contact_full_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Contact </mat-header-cell>
          <mat-cell *matCellDef="let location"> {{ location.contact_full_name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="prettyEmail">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
          <mat-cell *matCellDef="let location"> {{ location.prettyEmail }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="prettyPhone">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
          <mat-cell *matCellDef="let location"> {{ location.prettyPhone }} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let location">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Loctation actions">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="goToLocationDetails(location)">
                <mat-icon>open_in_new</mat-icon>
                <span>View details</span>
              </button>
              <button mat-menu-item (click)="showEditLocationModal(location)">
                <mat-icon>edit</mat-icon>
                <span>Edit location</span>
              </button>
              <button mat-menu-item (click)="showLocationNotificationsModal(location)">
                <mat-icon>settings</mat-icon>
                <span>Notification points</span>
              </button>
              <button mat-menu-item (click)="showRemoveLocationModal(location)">
                <mat-icon>delete</mat-icon>
                <span>Remove location</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </perfect-scrollbar>
  </div>
</div>
