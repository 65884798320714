import { Injectable } from '@angular/core';
import { HttpService } from '.';
import { ChargebeeWindow } from './chargebee.window';

@Injectable({
  providedIn: 'root',
})
export class ChargebeeService {
  constructor(private http: HttpService) {}

  getPlanType(deviceSerial: string): string {
    const deviceType = deviceSerial.substring(0, 3);

    switch (deviceType) {
      case 'SHB':
        return 'ent-kiwi-mtm';
      case 'SHH':
        return 'ent-hub-mtn';
      case 'SHP':
        return 'ent-kiwiir-mtm';
      default:
        return 'skyhawk-ent-sensors-per-unit';
    }
  }

  checkout(deviceSerial: string, plan: string): ChargebeeWindow {
    return new ChargebeeWindow(plan, false, this.http, null, deviceSerial);
  }

  openPortalSection(section: any): ChargebeeWindow {
    return new ChargebeeWindow(null, true, this.http, section, null);
  }
}
