import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Error403Component {
  /**
   * Constructor
   */
  constructor() {}
}
