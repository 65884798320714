<div>
  <h2 mat-dialog-title>{{ 'MODAL.TITLES.DEPLOYMENT_NOTIFICATIONS' | translate }}</h2>
  <mat-dialog-content style="height: 30vh">
    <perfect-scrollbar [config]="{suppressScrollX : true, suppressScrollY : true}" #perfectScrollBar>
      <loading-component [complete]="!loading"></loading-component>
      <mat-table *ngIf="!loading" [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
        <ng-container matColumnDef="devicename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Device name </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.devicename }} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="app_tc">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Alerts </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.app_tc }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="body">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Most recent </mat-header-cell>
          <mat-cell *matCellDef="let ev"> {{ ev.body }} </mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClick(row.serial)" class="clickable"></mat-row>
      </mat-table>
    </perfect-scrollbar>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-12">
    <button mat-button mat-dialog-close>
      {{ 'MODAL.ACTIONS.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
