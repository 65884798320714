<div class="page-layout blank" id="device-list-content-container">
  <div class="p-24" id="device-list-overflow-container">
  <div *ngIf="!embedded">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <mat-form-field class="search-devices-field">
          <mat-label>{{ 'DEVICES.ACTIONS.FILTER' | translate }}</mat-label>
          <input [formControl]="queryInput" matInput type="text"/>
          <button mat-button *ngIf="queryInput.value" matSuffix mat-icon-button (click)="queryInput.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-button-toggle-group [formControl]="assignedFilter" class="ml-12">
          <mat-button-toggle [value]="0">All</mat-button-toggle>
          <mat-button-toggle [value]="1">Assigned</mat-button-toggle>
          <mat-button-toggle [value]="2">Unassigned</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxFlexOffset="8px">
        <button
          mat-raised-button
          color="accent"
          [disabled]="!selection.selected.length || findRowsWithAssignedUser()"
          (click)="showAssignDevicesModal(selection.selected)"
        >
          {{ 'DEVICES.ACTIONS.ASSIGN' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!selection.selected.length || findRowsWithUnassignedUser()"
          (click)="showUnassignDeviceModal()"
        >
          {{ 'DEVICES.ACTIONS.UNASSIGN' | translate }}
        </button>
        <button mat-raised-button color="accent" (click)="showAddDeviceModal()">
          {{ 'DEVICES.ACTIONS.ADD' | translate }}
        </button>
      </div>
    </div>
    <p class="mt-0 mb-12 text-bold">
      Note: Italicized rows have no active subscription and cannot be assigned
      until a subscription is created.
    </p>
  </div>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort multiTemplateDataRows>
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let device">
          <mat-checkbox (click)="$event.stopPropagation()" 
          (change)="$event ? selection.toggle(device) : null"
          [checked]="selection.isSelected(device)"
          [disabled]="!device.businessSubscription">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let device"> 
          <ng-container *ngIf="!device.edit">
            <div class="name" (click)="deviceDetails(device)">{{ device.name }}</div>
            <button mat-icon-button class="edit-btn" (click)="toggleEdit(device)">
              <mat-icon fontSet="fa" fontIcon="fa-pen"></mat-icon>
            </button>    
          </ng-container>
          <ng-container *ngIf="device.edit">
            <input matInput type="text" maxlength="255" [id]="device.id" class="name-input" [value]="device.name"/>          
            <button mat-icon-button class="edit-btn" (click)="toggleEdit(device)">
              <mat-icon fontSet="fa" fontIcon="fa-check"></mat-icon>
            </button>    
          </ng-container>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="serial">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
        <mat-cell *matCellDef="let device"> {{device.serial}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="gps_label">
        <mat-header-cell *matHeaderCellDef mat-sort-header> GPS thumbnail </mat-header-cell>
        <mat-cell *matCellDef="let device">
          <ng-container *ngIf="device.gps.lat > -1000">
            <a [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" (opened)="popoverOpened()">
              {{ device.gps_label }}
            </a>          
          </ng-container>
          <ng-container *ngIf="device.gps.lat < -999">
            {{ device.gps_label }}
          </ng-container>
          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
            <mat-card>
              <mat-card-content>
                <google-map *ngIf="showMap" width="100%" height="300px"[center]="value">
                  <map-marker [position]="value"></map-marker>
                </google-map>
              </mat-card-content>
            </mat-card>
          </mde-popover>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="user_full_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Assigned user </mat-header-cell>
        <mat-cell *matCellDef="let device"> {{ device.user_full_name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="last_tech_check">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Last tech check </mat-header-cell>
        <mat-cell *matCellDef="let device"> {{ device.last_tech_check }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="prettyLastConnection">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Last connection </mat-header-cell>
        <mat-cell *matCellDef="let device"> {{ device.prettyLastConnection }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="businessSubscription">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Subscription </mat-header-cell>
        <mat-cell *matCellDef="let device"> 
          <ng-container *ngIf="device.businessSubscription && (device.businessSubscription.bs_status !== 'cancelled')">
            Active
          </ng-container>
          <ng-container *ngIf="!(device.businessSubscription && (device.businessSubscription.bs_status !== 'cancelled'))">
            <button mat-raised-button color="primary" (click)="addSubscription(device)" [disabled]="disableSubBtn[device.serial]" class="sub-button">
              Subscribe
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let device">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Device actions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deviceDetails(device)">
              <mat-icon>open_in_new</mat-icon>
              <span>Device details</span>
            </button>
            <button mat-menu-item (click)="showDeviceLogModal(device)">
              <mat-icon>list</mat-icon>
              <span>Event log</span>
            </button>
            <button mat-menu-item (click)="showDeploymentHistoryModal(device)">
              <mat-icon>access_time</mat-icon>
              <span>Deployment history</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let device; columns: displayedColumns;"
      [class]="device.businessSubscription && (device.businessSubscription.bs_status !== 'cancelled') ? '' : 'no-subscription'">
      </mat-row>
    </mat-table>
    <mat-paginator
    #paginator
    showFirstLastButtons>
    </mat-paginator>
  </div>
</div>