export const locale = {
  lang: 'en',
  data: {
    CUSTOMERS: {
      TITLE: 'Customers',
      ACTIONS: {
        ADD: 'Add Customer',
        REMOVE: 'Remove',
        FILTER: 'Filter customers',
        VIEW: 'View',
        EDIT: 'Edit',
      },
      TABLE: {
        HEADER: {
          BUSINESS_NAME: 'Business Name',
          ACTIVE_DEVICES: 'Active Devices',
          CONTACT_NAME: 'Contact Name',
          CONTACT_EMAIL: 'Contact Email',
          CONTACT_PHONE: 'Contact Phone',
          DEVICES_WITH_ALERTS: 'Devices with Alerts',
          BUSINESS_TYPE: 'Business Type',
          ACTIONS: 'Actions',
        },
        FOOTER: {
          EMPTY: 'No data to display',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
  },
};
