import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard, PaymentGuard } from 'app/guards';
import { CbSubscriptionsComponent } from './cb-subscriptions.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTableModule } from '@angular/material/table'; 
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
  {
    path: 'subscriptions',
    component: CbSubscriptionsComponent,
    canActivate: [AuthGuard, PaymentGuard],
  },
];

@NgModule({
  declarations: [CbSubscriptionsComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatIconModule
  ],
  exports: [CbSubscriptionsComponent],
})
export class CbSubscriptionsModule {}
