import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ChargebeeService, HttpService, ModalService } from 'app/services';
import { from, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { BaseModal } from '../base-modal';
import { locale as english } from '../i18n/en';

@Component({
  selector: 'app-add-new-device-modal',
  templateUrl: './add-new-device-modal.component.html',
  styleUrls: ['./add-new-device-modal.component.scss'],
})
export class AddNewDeviceModalComponent
  extends BaseModal
  implements OnInit, OnDestroy {
  serialForm: FormGroup;
  deviceForm: FormGroup;
  validSerial: boolean;
  invalidSerialMsg: string;
  customInfo: boolean;
  disableAddBtn: boolean = false;

  private _unsubscribeAll: Subject<any>;
  private planType: string;
  private devicePrefixes: string[];

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpService,
    private modalService: ModalService,
    private chargebeeService: ChargebeeService,
    protected dialogRef: MatDialogRef<AddNewDeviceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);

    this._fuseTranslationLoaderService.loadTranslations(english);

    this.serialForm = new FormGroup({
      deviceSerial: new FormControl('', [Validators.required]),
    });

    this.deviceForm = new FormGroup({
      deviceName: new FormControl('', [Validators.required]),
      deviceDescription: new FormControl(''),
    });

    this.validSerial = false;

    this._unsubscribeAll = new Subject();

    this.devicePrefixes = [];
  }

  ngOnInit(): void {
    this.http
      .getDeviceSerialMappings()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => (this.devicePrefixes = res));

    this.showDialog();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  validateSerial(): void {
    const deviceSerial: string = this.serialForm
      .get('deviceSerial')
      .value.toUpperCase();

    let $obs: Observable<any>;
    if (this.devicePrefixes.includes(deviceSerial.substring(0, 3))) {
      // Device
      $obs = this.http.validateDeviceSerial(deviceSerial);
    } else {
      // Sensor
      $obs = this.http.validateSensorSerial(deviceSerial);
    }

    $obs
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError((err) => {
          this.validSerial = false;
          this.invalidSerialMsg = err.message;
          this.serialForm
            .get('deviceSerial')
            .setErrors({ invalidSerial: true });

          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.validSerial = true;
        this.serialForm.get('deviceSerial').disable();
        this.planType = this.chargebeeService.getPlanType(deviceSerial);
      });
  }

  async openChargebee(): Promise<any> {
    const serial = this.serialForm.get('deviceSerial').value.toUpperCase(),
          name = this.deviceForm.get('deviceName').value || serial,
          description = this.deviceForm.get('deviceDescription').value || serial;

    const cbWindow = this.chargebeeService.checkout(serial, this.planType);
      
    this.disableAddBtn = true;

    if(this.devicePrefixes.includes(serial.substring(0, 3))) {
      this.http.addBusinessDevice({
        deviceSerial: serial,
        deviceName: name,
        deviceDescription: description
      }).pipe(
        switchMap(() => from(cbWindow.open())),
        catchError((err) => {
          this.dialogRef.close({error: err.message || err});
          return throwError(err);
        }),
      ).subscribe((res) => {
        if(res)
          this.dialogRef.close({error: false});
      });
    } else {
      let $obs = this.http.addBusinessSensor({
        sensorSerial: serial,
        sensorName: name,
        sensorDescription: description
      });
      this.http.getPerUnitSubs()
      .pipe(
        catchError(err => this.modalService.errorDialog(err))
      ).subscribe((res) => {
        if(res.length == 0){
          $obs.pipe(
            switchMap(() => from(cbWindow.open())),
            catchError((err) => {
              this.dialogRef.close({ error: err.message || err });
              return throwError(err);
            }),
          ).subscribe((res) => {
            if(res)
              this.dialogRef.close({ error: false });
          });
        } else {
          $obs.pipe(
            catchError((err) => {
              this.dialogRef.close({error: err.message || err});
              return throwError(err);
            }),
          ).subscribe((res) => {
            if(res)
              this.http.subscribeDevices([serial]).pipe(
                catchError(this.modalService.errorDialog)
              ).subscribe((sub) => {
                  this.dialogRef.close({error: sub === true ? false : JSON.stringify(sub)});
              });
          });
        }
      });
    }
    return null;
  }
}
