import { environment } from 'environments/environment';
import { HttpService } from './http.service';

export class ChargebeeWindow {
  private _planId: string;
  private _isPortal: boolean;
  private _http: HttpService;
  private _section: string;
  private _deviceSerial: string;

  private chargebeeInstance: any;
  private handler: any;
  private error: any;
  private success: any;

  private get chargebee(): any {
    return window['Chargebee'];
  }

  constructor(
    planId: string,
    isPortal: boolean,
    http: HttpService,
    section: string,
    deviceSerial: string,
  ) {
    this._planId = planId;
    this._isPortal = isPortal;
    this._http = http;
    this._section = section;
    this._deviceSerial = deviceSerial;

    try {
      this.chargebeeInstance = this.chargebee.getInstance();
    } catch {
      this.chargebeeInstance = this.chargebee.init({
        site: environment.chargebee,
        iframeOnly: true,
      });
    }
  }

  open = () => {
    if (this._isPortal) {
      this.chargebeeInstance.setPortalSession(async () => {
        return await this._http.chargebeePortalToken().toPromise();
      });

      const cbPortal = this.chargebeeInstance.createChargebeePortal();
      const portalFunctions = {
        loaded: this.cbLoaded,
        error: this.cbError,
        success: this.cbSuccess,
        close: this.cbClose,
      };

      if (this._section) {
        cbPortal.open(portalFunctions, {
          sectionType: this.chargebee.getPortalSections()[this._section],
          params: {
            subscriptionId: 'active_direct',
          },
        });
      } else {
        cbPortal.open(portalFunctions);
      }
    } else {
      this.chargebeeInstance.openCheckout({
        hostedPage: this.getHostedPage,
        loaded: this.cbLoaded,
        error: this.cbError,
        success: this.cbSuccess,
        close: this.cbClose,
        step: this.cbStep,
      });
    }

    return new Promise((res, rej) => {
      this.handler = {
        resolve: (data: any) => {
          res(data);
        },
        reject: (data: any) => {
          rej(data);
        },
      };
    });
  };

  private getHostedPage = async () => {
    return await this._http
      .chargebeeGetHostPage(this._deviceSerial, this._planId)
      .toPromise()
      .catch((err) => this.handler.reject(err));
  };

  private cbLoaded = () => {
    const cbFrame = document.getElementById('cb-frame');
    cbFrame.style.overflow = 'scroll';
    cbFrame['scrolling'] = 'yes';
  };

  private cbError = (err: any) => {
    this.error = err;
  };

  private cbClose = async () => {
    const isError = this.error !== undefined;
    const rtn = this.error || this.success;

    if (isError) {
      this.handler.reject(rtn);
    } else {
      if (this._isPortal || rtn === undefined) {
        this.handler.resolve(undefined);
      } else {
        await this._http
          .chargebeeQueryHostId(rtn)
          .toPromise()
          .catch((err) => {
            this.handler.reject(err);
          });

        this.handler.resolve(true);
      }
    }

    this.chargebeeInstance.closeAll();
  };

  private cbStep = (step: string) => {
    if (step === 'thankyou_screen') {
      this.cbClose();
    }
  };

  private cbSuccess = (rtn: any) => {
    this.success = rtn;
  };
}
