export const locale = {
  lang: 'en',
  data: {
    DETAILS: {
      TITLE: 'Details',
      ACTIONS: {
        FILTER: 'Filter devices',
        PICKUP: 'Pickup',
        VIEW: 'View',
      },
      TABLE: {
        HEADER: {
          ACTIONS: 'Actions',
          DEVICE_NAME: 'Device Name',
          DEVICE_SERIAL: 'Device Serial',
          GPS: 'GPS',
          DEPLOY_TIME: 'Deploy Time',
          PICKUP_TIME: 'Pickup Time',
          RESULT: 'Result',
          TECH: 'Technician',
          TRAP_TYPE: 'Trap Type',
          IMAGE: 'Image',
          NOTES: 'Notes'
        },
        FOOTER: {
          EMPTY: 'No data to display',
          SELECTED: 'Selected',
          TOTAL: 'Total',
        },
      },
    },
  },
};
