import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncWrapperGuard } from 'app/guards';
import { SearchComponent } from './search.component';
import { DeviceLogModalModule } from 'app/main/modals/device-log-modal/device-log-modal.module';
import { GalleryModule } from '../general/gallery/gallery.module';
import { MatTabsModule } from '@angular/material/tabs';
import { DeploymentsModule } from '../dashboard/deployments/deployments.module';
import { DeviceListModule } from '../devices/device-list/device-list.module';
import { UsersModule } from '../users/users.module';
import { CustomersModule } from '../customers/customers-base/customers.module';

const routes: Routes = [
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AsyncWrapperGuard],
  },
];

@NgModule({
  declarations: [SearchComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    DeviceLogModalModule,
    GalleryModule,
    MatTabsModule,
    DeploymentsModule,
    DeviceListModule,
    CustomersModule,
    UsersModule
  ],
  providers: [DatePipe],
  exports: [SearchComponent],
})
export class SearchModule {}
